import axios from "axios";
import { Constants } from "../utils";
import Cookies from 'js-cookie';


class NotificationService {

    fetchMyNotifications = async (pageNumber=1, pageSize=10) => {
        const token = Cookies.get('user-token');

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'application/json'}
        };

        return axios.get(`${Constants.baseUrl}/notification?pageNumber=${pageNumber}&pageSize=${pageSize}`, config);
    }

}

export default NotificationService;