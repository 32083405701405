import React from 'react';
import { Link } from 'react-router-dom';

function DonationPolicy() {
    return (
        <div className='w-full flex flex-col font-rubik gap-2 pt-20 md:pt-36 items-center'>
            <div className='flex flex-col w-full xl:w-4/5 px-4 md:px-10 xl:px-20 2xl:px-5 gap-4 items-center md:items-start'>
                <div className='text-primary-blue text-2xl md:text-3xl font-medium mb-2'> Donation Policy </div>
                <div className="flex flex-row">
                    <Link to='/legal' className='text-primary-green text-base mr-2'> Legal </Link>
                    <div className='text-slate-400 text-base mr-2'> / </div>
                    <div className='text-slate-400 text-base'> Donation Policy </div>
                </div>

                <div className='text-primary-blue text-md text-justify'> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean rutrum tempor ultrices. 
                Nulla non risus ac diam pretium gravida. Curabitur ipsum augue, mollis vitae tincidunt ac, malesuada vel erat. Nulla erat est, sodales vel 
                rutrum quis, convallis in metus. Phasellus tempor, neque non pulvinar faucibus, lacus erat congue felis, ut hendrerit lectus purus a lacus. 
                Integer convallis pellentesque interdum. Etiam facilisis magna et lorem tincidunt tempor. Sed sed erat lobortis, aliquam purus eu, porttitor 
                risus. Duis venenatis fringilla enim a semper. Cras consequat pulvinar justo, sit amet imperdiet sapien dictum vel. </div>

                <div className='text-primary-blue text-md text-justify'> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean rutrum tempor ultrices. 
                Nulla non risus ac diam pretium gravida. Curabitur ipsum augue, mollis vitae tincidunt ac, malesuada vel erat. Nulla erat est, sodales vel 
                rutrum quis, convallis in metus. Phasellus tempor, neque non pulvinar faucibus, lacus erat congue felis, ut hendrerit lectus purus a lacus. 
                Integer convallis pellentesque interdum. Etiam facilisis magna et lorem tincidunt tempor. Sed sed erat lobortis, aliquam purus eu, porttitor 
                risus. Duis venenatis fringilla enim a semper. Cras consequat pulvinar justo, sit amet imperdiet sapien dictum vel. </div>
            </div>
        </div>
    );
}

export default DonationPolicy;