import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiPlus, FiSearch, FiHelpCircle, FiLogIn, FiLogOut, FiBookmark, FiBell, FiUser, FiLoader } from "react-icons/fi";
import Cookies from 'js-cookie';

import OutsideClickHandler from './outsideClickHandler';

function ResponsiveAppBarContent({openMenu, setOpenMenu, loggedIn, setUser, setLoggedIn}) {
    const navigate = useNavigate();

    return (
        <div className={`absolute top-16 mt-2 right-0 p-4 font-rubik bg-white rounded-md border-2 border-slate-200 duration-200 flex flex-col gap-2 ` 
            + (!openMenu ? '-mr-60 opacity-20' : 'mr-6 opacity-100')}>
                <OutsideClickHandler onOutsideClick={() => setOpenMenu(false)}>
                    <div className="flex flex-col gap-2">
                        <Link to='/explore' onClick={() => setOpenMenu(false)} className='flex flex-row gap-2 text-secondary-blue hover:text-primary-green px-2 py-2 items-center duration-200'>
                            <FiSearch className='text-lg'/>
                            <div className='text-lg'>
                                Search Campaigns
                            </div>
                        </Link>

                        <Link to='/howitworks' onClick={() => setOpenMenu(false)} className='flex flex-row gap-2 text-secondary-blue hover:text-primary-green px-2 py-2 items-center duration-200'>
                            <FiHelpCircle className='text-lg'/>
                            <div className='text-lg'>
                                How it works
                            </div>
                        </Link>

                        <Link to='/create' onClick={() => setOpenMenu(false)} className='flex flex-row gap-2 text-secondary-blue hover:text-primary-green px-2 py-2 items-center duration-200'>
                            <FiPlus className='text-lg'/>
                            <div className='text-lg'>
                                Create a Campaign
                            </div>
                        </Link>

                        {loggedIn && (
                            <Link to='/saved' onClick={() => setOpenMenu(false)} className='flex flex-row gap-2 text-secondary-blue hover:text-primary-green px-2 py-2 items-center duration-200'>
                                <FiBookmark className='text-lg'/>
                                <div className='text-lg'>
                                    Saved Campaigns
                                </div>
                            </Link>
                        )}

                        {loggedIn && (
                            <Link to='/notifications' onClick={() => setOpenMenu(false)} className='flex flex-row gap-2 text-secondary-blue hover:text-primary-green px-2 py-2 items-center duration-200'>
                                <FiBell className='text-lg'/>
                                <div className='text-lg'>
                                    Notifications
                                </div>
                            </Link>
                        )}

                        {loggedIn && (
                            <Link to='/user' onClick={() => setOpenMenu(false)} className='flex flex-row gap-2 text-secondary-blue hover:text-primary-green px-2 py-2 items-center duration-200'>
                                <FiUser className='text-lg'/>
                                <div className='text-lg'>
                                    Profile
                                </div>
                            </Link>
                        )}

                        {loggedIn && (
                            <Link to='/mycampaigns' onClick={() => setOpenMenu(false)} className='flex flex-row gap-2 text-secondary-blue hover:text-primary-green px-2 py-2 items-center duration-200'>
                                <FiLoader className='text-lg'/>
                                <div className='text-lg'>
                                    My Campaigns
                                </div>
                            </Link>
                        )}

                        {!loggedIn && (
                            <Link to='/login' onClick={() => setOpenMenu(false)} className='flex flex-row gap-2 text-secondary-blue hover:text-primary-green px-2 py-2 items-center duration-200'>
                                <FiLogIn className='text-lg'/>
                                <div className='text-lg'>
                                    Sign in
                                </div>
                            </Link>
                        )}

                        {loggedIn && (
                            <button 
                            onClick={() => {
                                Cookies.remove('user-token');
                                setUser((us) => null);
                                setLoggedIn((lg) => false);
                                navigate('/login');
                                setOpenMenu(false);
                            }}
                            className='flex flex-row gap-2 px-2 py-2 items-center duration-200 text-secondary-blue hover:text-primary-green'>
                                <FiLogOut className='text-lg'/>
                                <div className='text-lg'>
                                    Log Out
                                </div>
                            </button>
                        )}
                    </div>
                </OutsideClickHandler>

            </div>
    );
}

export default ResponsiveAppBarContent;