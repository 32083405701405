import React, {useState} from 'react';
import { FiCopy, FiX } from "react-icons/fi";
import {
    FacebookShareButton, FacebookIcon,
    TwitterShareButton, TwitterIcon,
    LinkedinShareButton, LinkedinIcon,
    WhatsappShareButton, WhatsappIcon,
    TelegramShareButton, TelegramIcon
 } from 'react-share';

function SharePopup({shareUrl, onCloseClicked}) {
    const [copied, setCopied] = useState(false);
    return (
        <div className='flex flex-col bg-white shadow-lg rounded-xl gap-2 p-4 w-full'>
            
            <div className='flex flex-row gap-4 items-center'>
                <FacebookShareButton url={shareUrl}>
                    <FacebookIcon size={35} round={true}/>
                </FacebookShareButton>

                <TwitterShareButton url={shareUrl}>
                    <TwitterIcon size={35} round={true}/>
                </TwitterShareButton>

                <LinkedinShareButton url={shareUrl}>
                    <LinkedinIcon size={35} round={true}/>
                </LinkedinShareButton>

                <WhatsappShareButton url={shareUrl}>
                    <WhatsappIcon size={35} round={true}/>
                </WhatsappShareButton>

                <TelegramShareButton url={shareUrl}>
                    <TelegramIcon size={35} round={true}/>
                </TelegramShareButton>

                <div className="flex flex-grow"></div>

                <div className='group' onClick={() => onCloseClicked()}>
                    <FiX className='text-primary-blue text-xl group-hover:text-red-500'/>
                </div>
            </div>
            {copied && <div className='text-sm text-primary-green'>Link copied to clipboard</div>}
            <div className='flex flex-row gap-4 items-center'>
                <div className='text-md text-primary-blue overflow-x-auto'>{shareUrl}</div>
                <div className='group flex-grow' 
                onClick={() => {
                    navigator.clipboard.writeText(shareUrl);
                    setCopied(true);
                }}>
                    <FiCopy className='text-primary-green text-xl group-hover:text-primary-blue duration-200'/>
                </div>
            </div>
        </div>
    );
}

export default SharePopup;