import React, {useEffect, useState} from 'react';
import { FiX } from 'react-icons/fi';

import { UserService } from '../../services';


function EditProfilePopup({user, closePopup}) {
    const userService = new UserService();

    const [fname, setFName] = useState(user.firstname);
    const [lname, setLName] = useState(user.lastname);
    const [email, setEmail] = useState(user.email);

    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
        if(fname!==user.firstname || lname!==user.lastname){
            setFormValid(true);
        }else{
            setFormValid(false);
        }
    // eslint-disable-next-line
    }, [fname, lname])
    

    const [loading, setLoading] = useState(false);

    // console.log(user);
    const updateUserData = () => {
        const data = {
            id: user.id,
            firstname: fname,
            lastname: lname
        }

        setLoading(true);
        userService.updateUserData(data).then((res) => console.log(res.data))
        .catch((err) => console.log(err))
        .finally(() => {
            setLoading(false);
            closePopup();
        })

    }

    return (
        <div className='w-full flex flex-col font-rubik gap-2 py-20 p-5 md:pt-20 items-center fixed z-20'>
            <div className='flex flex-row w-full xl:w-2/5 shadow-xl justify-center bg-white rounded-xl'>
                <div className="flex flex-col w-full gap-5 px-10 py-10 items-center">
                    <div className="flex flex-row items-center justify-between w-full mb-10">
                        <div className='text-primary-blue text-xl'> Edit Profile </div>
                        <button onClick={closePopup}>
                            <FiX className='text-red-500 text-xl'/>
                        </button>
                    </div>

                    {loading && <div className='text-primary-green text-base'> Updating Account... </div>}

                    <div className="flex flex-col w-full">
                        <div className='text-primary-blue text-base'> First Name: </div>
                        <input type="text" value={fname} onChange={(val) => setFName(val.currentTarget.value)} placeholder="First Name"
                        className="bg-slate-100 rounded-md px-4 py-3 w-full"/>
                    </div>

                    <div className="flex flex-col w-full">
                        <div className='text-primary-blue text-base'> Last Name: </div>
                        <input type="text" value={lname} onChange={(val) => setLName(val.currentTarget.value)} placeholder="Last Name"
                        className="bg-slate-100 rounded-md px-4 py-3 w-full"/>
                    </div>

                    <div className="flex flex-col w-full">
                        <div className='text-primary-blue text-base'> Email: </div>
                        <input type="text" value={email} onChange={(val) => setEmail(val.currentTarget.value)} placeholder="Email"
                        className="bg-slate-100 rounded-md px-4 py-3 w-full" disabled/>
                    </div>


                    <button disabled={!formValid} onClick={updateUserData}
                    className={`flex flex-row gap-2 px-6 py-2 rounded-md items-center duration-200 w-full justify-center mb-5 ` + (formValid ? 'bg-primary-blue' : 'bg-slate-100')}>
                        <div className='text-primary-green text-lg font-rubik'>
                            Save Changes
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditProfilePopup;