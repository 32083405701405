import React from 'react';
import donateHand from '../../../assets/gifs/donate-hand.gif';

// import { FiCheck } from "react-icons/fi";
import { Utils } from '../../../utils';


function LomiTip({currency, tip, setTip}) {
    // const [openCurrency, setOpenCurrency] = useState(false);
    const utils = new Utils();
    return (
        <div className='w-full flex flex-col gap-2 py-10'>


            <div className="flex flex-row gap-2 mb-3 items-center">
                <div className="flex flex-col gap-2">
                    <div className='text-xl md:text-2xl font-medium text-primary-blue'>
                        Would you like to "Tip" Lomi?
                    </div>
                    <div className='text-primary-blue text-sm lg:text-base line-clamp-3'>
                        Lomi is a donation platform working for free, your donation to our company would be helpfull.
                    </div>
                    <div className='text-md'>
                        <a href="/legal/termsofservice" target="_blank" className='text-primary-green'>How can this help us?</a>
                    </div>
                </div>
                <img src={donateHand} alt="donate hand" className='w-20 lg:w-28' />
            </div>

            <div className="flex flex-row gap-2 items-center font-rubik mb-2">
                <div className='text-primary-blue text-xl font-medium'>
                    Tip Amount:
                </div>
                <div className='text-xl text-primary-green font-medium'>
                    {tip ? utils.addCommaToNumber(parseInt(tip)) : '0'} {currency}
                </div>
            </div>

            <div className="flex flex-row flex-wrap gap-2">
                {[5,10,15,20,50,100].map((item,index) => {
                    return (
                        <button onClick={() => {
                            setTip(item)
                        }} key={index} className={`flex w-14 h-12 justify-center items-center rounded-lg ` + (item===tip ? 'bg-slate-200' : 'bg-slate-100')}>
                            <div className='text-primary-blue text-lg'>
                                {item}
                            </div>
                        </button>
                    );
                })}
            </div>

            <div className='text-slate-400 text-sm lg:text-base line-clamp-3'>
                You can change the currency on amount section.
            </div>
            

            <div className="flex flex-row gap-2 mb-2">
                <input type="text" value={tip} 
                onChange={(e) => {
                    const val = e.currentTarget.value;
                    let isnum = /^\d+$/.test(val);
                    if(isnum || val===''){
                        setTip(val);
                    }
                }} placeholder="0.00"
                className="bg-slate-100 rounded-md px-4 py-3 flex-grow text-2xl"/>
                
            </div>

            {/* <div className="flex flex-row gap-2 mb-2">
                {[5,10,25,50,100].map((item) => {
                    return (
                        <div className='flex-grow py-3 px-2 flex flex-row justify-center bg-slate-100 rounded-lg text-lg lg:text-2xl font-medium text-primary-blue'>
                            {item}
                        </div>
                    )
                })}
                
            </div> */}


            
            
        </div>
    );
}

export default LomiTip;