import React from 'react';
import { Link } from 'react-router-dom';

function ReadyToGetStarted() {
    return (
        <div className='flex flex-col w-full px-2 lg:px-20 py-4 lg:py-8 font-rubik gap-3'>
            {/* <div className='text-primary-blue text-lg'>Hooray!</div> */}
            <div className='flex flex-col bg-slate-100 rounded-xl justify-center items-center p-4 py-10 lg:py-16 lg:p-0'>
                <div className='text-2xl lg:text-4xl font-medium text-primary-blue text-center'>Ready to get started?</div>
                <div className='text-xl lg:text-2xl font-normal text-primary-blue text-center'>Join thousands of others today.</div>
                <div className="flex flex-row flex-wrap justify-center gap-1 lg:gap-4 mt-4">
                    <Link to='/create' className='flex flex-row gap-1 lg:gap-2 px-6 py-2 border-primary-blue border-2 bg-primary-blue hover:bg-secondary-blue rounded-full items-center duration-200'>
                        <div className='text-primary-green text-md lg:text-lg font-rubik'>
                            Create a Campaign
                        </div>
                    </Link>

                    <Link to='/howitworks' className='flex flex-row gap-1 lg:gap-2 px-6 py-2 hover:bg-slate-300 border-primary-blue border-2 rounded-full items-center duration-200'>
                        <div className='text-primary-blue text-md lg:text-lg font-rubik'>
                            How it works
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ReadyToGetStarted;