import React from 'react';
import { FiCheck } from "react-icons/fi";

function DonationSuccess() {
    return (
        <div className='min-h-screen w-full flex flex-col justify-center items-center font-rubik'>
            <FiCheck className='text-primary-green text-7xl mb-6'/>
            <div className='text-primary-green text-3xl'>
                Donation Successfull 
            </div>
            <span className='text-lg ml-2 text-primary-blue mb-2'>
                Thank you for your donation!
            </span>
            <div className="flex flex-row mt-4">
                <a href='/' className='flex flex-row gap-2 px-6 py-2 bg-primary-blue rounded-lg items-center duration-200 w-full justify-center mb-4'>
                    <div className='text-primary-green text-lg font-rubik font-medium'>
                        Go to Home
                    </div>
                </a>
            </div>
        </div>
    );
}

export default DonationSuccess;