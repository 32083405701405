import axios from "axios";
import Cookies from 'js-cookie';
import { Constants } from "../utils";

class ReportService {

    async reportCampaign(formData){
        const token = Cookies.get('user-token');

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'application/json'}
        };

        return axios.post(`${Constants.baseUrl}/report/create`, formData, config);
    }

}

export default ReportService;