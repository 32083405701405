import React, {useState, useEffect} from 'react';
import { FiX } from "react-icons/fi";
import YouTube from 'react-youtube';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

function VideoPlayerPopup({onClose, videoUrl}) {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [loading, setLoading] = useState(true);
    const [videoId, setVideoId] = useState('');

    useEffect(() => {
        const vId = getVideoId(videoUrl);
        console.log(vId);
        setVideoId(vId ?? '');
    // eslint-disable-next-line 
    }, []);

    function getVideoId(url) {
        // eslint-disable-next-line
        const videoIdRegex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^\"&?\/\s]{11})/;
        const match = url.match(videoIdRegex);
        return match && match[1];
    }

    useEffect(() => {
        function handleResize() {
        setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line 
    }, []);

    const opts = {
      height: windowDimensions.width < 768 ? (windowDimensions.width*0.8)*0.63 : (windowDimensions.width*0.8)*0.4,
      width: windowDimensions.width < 768 ? windowDimensions.width*0.90 : windowDimensions.width*0.65,
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        rel: 0,
        color: "white"
      },
    };


    return (
        <div className='inset-0 fixed top-0 flex justify-center items-center bg-black bg-opacity-50'>
            <div className='flex flex-col items-center justify-center bg-white rounded-xl px-4 pt-2 pb-4'>

                <div className='w-full flex flex-row my-2 items-center px-2 justify-between py-2'>
                    <div>Video</div>
                    <button onClick={onClose} className='px-4 py-2'>
                        <FiX size={18}/>
                    </button>
                </div>

                <div className='bg-primary-blue relative' width={opts.width} height={opts.height}>
                    {videoId!=='' && (
                        <YouTube className='player-body bg-primary-blue' videoId={videoId} opts={opts} onEnd={(e)=>{
                            console.log('Video Ended')
                        }} onReady={() => setLoading(false)}/>
                    )}

                    {loading && (
                        <div className="flex w-full h-full justify-center items-center absolute top-0 z-20 bg-black bg-opacity-25">
                            <div className='text-white'>
                                Loading Video ...
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
}

export default VideoPlayerPopup;