import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiLogOut, FiUser, FiLoader, FiTrash } from "react-icons/fi";
import Cookies from 'js-cookie';

// import OutsideClickHandler from './outsideClickHandler';

function ProfileMenu({openMenu, setOpenMenu, loggedIn, setUser, setLoggedIn}) {
    const navigate = useNavigate();

    // console.log(openMenu);

    return (
        <div className={`absolute w-[200px] top-[86px] mt-2 right-14 p-2 font-rubik bg-white rounded-md border-2 border-slate-200 duration-200 flex flex-col gap-1 ` 
            + (!openMenu ? '-mr-80 opacity-10' : 'mr-6 opacity-100')}>
                <div className="flex flex-col gap-1">

                    {loggedIn && (
                        <Link to='/user' onClick={() => setOpenMenu(false)} className='flex flex-row gap-2 text-secondary-blue hover:text-primary-green px-2 py-2 items-center duration-200'>
                            <FiUser className='text-base'/>
                            <div className='text-base'>
                                Profile
                            </div>
                        </Link>
                    )}

                    {loggedIn && (
                        <Link to='/withdraw' onClick={() => setOpenMenu(false)} className='flex flex-row gap-2 text-secondary-blue hover:text-primary-green px-2 py-2 items-center duration-200'>
                            <FiLoader className='text-base'/>
                            <div className='text-base'>
                                My Withdraws
                            </div>
                        </Link>
                    )}

                    {loggedIn && (
                        <button 
                        onClick={() => {
                            Cookies.remove('user-token');
                            setUser((us) => null);
                            setLoggedIn((lg) => false);
                            navigate('/login');
                            setOpenMenu(false);
                        }}
                        className='flex flex-row gap-2 px-2 py-2 items-center duration-200 text-secondary-blue hover:text-primary-green'>
                            <FiLogOut className='text-base'/>
                            <div className='text-base'>
                                Log Out
                            </div>
                        </button>
                    )}

                    {loggedIn && (
                        <button 
                        onClick={() => {
                            // Cookies.remove('user-token');
                            // setUser((us) => null);
                            // setLoggedIn((lg) => false);
                            navigate('/delete-account');
                            setOpenMenu(false);
                        }}
                        className='flex flex-row gap-2 px-2 py-2 items-center duration-200 text-red-400 hover:text-red-500'>
                            <FiTrash className='text-base'/>
                            <div className='text-base'>
                                Delete Account
                            </div>
                        </button>
                    )}
                    </div>

            </div>
    );
}

export default ProfileMenu;