import React, {useState, useEffect, useContext} from 'react';
import { FiChevronLeft } from "react-icons/fi";
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiCheck } from "react-icons/fi";

import { UserContext } from '../../contexts';
import { CampaignService, DonationService } from '../../services';
import { Constants } from '../../utils';
import { LoadingSpinner } from '../shared';

import { DonationAmount, DonationInfo, PaymentMethod, DonationConfirm, LomiTip, DonatorInfo } from './donationPages';

// const pay = [
//     {id: 0, name: 'Chapa', img: ''},
//     {id: 1, name: 'Telebirr', img: ''},
//     {id: 2, name: 'Credit/Debit Card', img: ''},
// ];


function DonationScreen() {
    const campaignService = new CampaignService();
    const donationService = new DonationService();

    const {user, loggedIn} = useContext(UserContext);

    const [amount, setAmount] = useState('');
    const [tip, setTip] = useState('0');
    const [currency, setCurrency] = useState('ETB');
    // const [openCurrency, setOpenCurrency] = useState(false);
    const [message, setMessage] = useState('');
    const [annonymous, setAnnonymous] = useState(false);
    const {id} = useParams();

    const [isTermChecked, setIsTermChecked] = useState(false);

    const [selectedPay, setSelectedPay] = useState(-1);

    const [campaign, setCampaign] = useState(null);
    const [loading, setLoading] = useState(false);
    const [donationLoading, setDonationLoading] = useState(false);

    const [firstname, setFirstname] = useState(loggedIn ? user?.firstname : '');
    const [lastname, setLastname] = useState(loggedIn ? user?.lastname : '');
    const [email, setEmail] = useState(loggedIn ? user?.email : '');

    const [page, setPage] = useState(0);

    useEffect(() => {
        setLoading(true);
        campaignService.fetchCampaignById(id)
        .then((res) => {
            const camp = res.data.data;
            setCampaign(camp);
        }).catch((err) => console.log(err))
        .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const donateWithChapa = () => {
        setDonationLoading(true);
        const data = {
            amount: parseInt(amount)+parseInt(tip),
            currency:currency,
            email: loggedIn ? user?.email : '',
            first_name: loggedIn ? user?.firstname : '',
            last_name: loggedIn ? user?.lastname : '',
            campaignId: id,
            donatorId: loggedIn ? user?.id : '',
            title: campaign.title,
            message: message,
            annonymous: annonymous,
            tipAmount: tip
        }
        console.log(data);
        donationService.donateWithChapa(data)
        .then((res) => {
            const url = res.data.url;
            window.location.replace(url);
            console.log(res);
        })
        .catch((err) => console.log(err))
        .finally(() => setDonationLoading(false));
    }

    const donateWithStripe = () => {
        setDonationLoading(true);
        const data = {
            name: campaign.title,
            description: campaign.description,
            metadata: {
                campaignId: id,
                donatorId: loggedIn ? user?.id : '',
                message: message,
                annonymous: annonymous,
                tipAmount: tip,
                firstname: firstname ?? 'Anonymous',
                lastname: lastname ?? 'Anonymous',
                email: email ?? '-'
            },
            price: parseInt(amount)
        }
        console.log(JSON.stringify(data));
        donationService.donateWithStripe(data)
        .then((res) => {
            const url = res.data.url;
            window.location.replace(url);
        })
        .catch((err) => console.log(err))
        .finally(() => setDonationLoading(false));
    }

    const donateToCampaign = () => {
        switch (selectedPay) {
            case 0:
                donateWithChapa();
                break;
            case 1:
                donateWithChapa();
                break;
            case 2:
                donateWithStripe();
                break;
            default:
                break;
        }
    }

    const canContinue = () => {
        if(page===0){
            if(amount!==''){
                return true;
            }else{
                return false;
            }
        }if(page===1){
            return true;
        }else if(page===2){
            return true;
        }else if(page===3){
            if(selectedPay!==-1){
                return true;
            }else{
                return false;
            }
        }else if(page===4){
            return true;
        }else if(page===5){
            if(isTermChecked){
                return true;
            }else{
                return false;
            }
        }
    }

    return (
        <div className='w-full flex flex-col justify-start items-center font-rubik gap-3 pt-20 md:pt-36'>
            <div className='text-3xl font-medium text-primary-blue mb-2 md:mb-4'>Donate</div>

            {campaign!=null
            && (
                <Link to={`/campaign/${campaign._id}`} 
                className="flex flex-row w-full md:w-2/3 lg:w-1/2 xl:w-2/5 2xl:w-1/3 px-3 md:px-10 2xl:px-5 mb-2 hover:scale-105 duration-200">
                    <div className="flex flex-row gap-3 w-full p-2 justify-start bg-slate-50 rounded-xl">
                        {campaign.photos.length>0 && (
                            <img src={`${Constants.baseUrl}/uploaded-file/${campaign.photos[0]}`} alt=''
                            className='w-26 h-24 bg-slate-300 rounded-xl object-cover'/>
                        )}
                        <div className="flex flex-col">
                            <div className='text-lg md:text-2xl font-medium text-primary-blue line-clamp-1'>{campaign.title}</div>
                            <div className='text-primary-blue text-sm md:text-md line-clamp-2'>{campaign.description}</div>
                            <div className="flex flex-row md:mt-1">
                                <div className="px-4 py-1 bg-primary-green rounded-full text-sm md:text-md">
                                    {campaign.category}
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            ) }

            {loading && (
                <div className="flex flex-row w-full md:w-2/3 lg:w-1/2 xl:w-2/5 2xl:w-1/3 px-3 md:px-10 2xl:px-5 mb-2 ">
                    Loading ... 
                </div>
            )}

            <div className='hidden md:flex flex-row w-full md:w-2/3 lg:w-1/2 xl:w-2/5 2xl:w-1/3 px-3 md:px-10 2xl:px-5'>
                <div className="flex flex-row w-full rounded-lg overflow-hidden">
                    {[{id:0, name:'Amount'}, {id:1, name:'Donator'}, {id:2, name:'Message'}, {id:3, name:'Payment'}, {id:4, name:'Lomi Tip'}, {id:5, name:'Confirm'}].map((item) => {
                        return (
                            <div key={item.id} className={`hover:cursor-pointer flex-grow py-2 flex flex-row gap-2 items-center justify-center ` + (page>=item.id ? 'bg-slate-200' : 'bg-slate-100')}>
                                {page>item.id && (
                                    <div className='w-5 h-5 flex justify-center items-center rounded-full bg-primary-green'>
                                        <FiCheck className={`text-sm text-primary-blue`}/>
                                    </div>
                                )}
                                <div className='text-[13px]'>{item.name}</div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className='flex md:hidden flex-col w-full md:w-2/3 lg:w-1/2 xl:w-2/5 2xl:w-1/3 px-3 md:px-10 2xl:px-5'>
                <div className='text-primary-blue'>
                    {page}/5
                </div>
                <div className="flex flex-row gap-1 w-full rounded-full overflow-hidden">
                    {[0,1,2,3,4,5].map((item) => {
                        return (
                            <div key={item} className={`hover:cursor-pointer flex-grow h-1.5 flex flex-row gap-2 items-center justify-center ` 
                            + (page>=item ? 'bg-primary-green' : 'bg-slate-100')}>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='flex flex-col  w-full md:w-2/3 lg:w-1/2 xl:w-2/5 2xl:w-1/3 px-3 md:px-10 2xl:px-5 gap-2 md:gap-4'>

                

                {page===0  && <DonationAmount amount={amount} setAmount={setAmount} currency={currency} setCurrency={setCurrency} campaignData={campaign}/>}
                {page===1  && <DonatorInfo firstname={firstname} setFirstname={setFirstname} lastname={lastname} setLastname={setLastname} email={email} setEmail={setEmail}/>}
                {page===2  && <DonationInfo message={message} setMessage={setMessage} annonymous={annonymous} setAnnonymous={setAnnonymous}/>}
                {page===3  && <PaymentMethod selectedPay={selectedPay} setSelectedPay={setSelectedPay} currency={currency}/>}
                {page===4  && <LomiTip amount={amount} setAmount={setAmount} currency={currency} tip={tip} setTip={setTip}/>}
                {page===5  && <DonationConfirm amount={amount} currency={currency} 
                isTermChecked={isTermChecked} setIsTermChecked={setIsTermChecked} tip={tip} setPage={setPage}/>}

                <div className="flex flex-row gap-3 justify-between">

                    {page>=1 
                    ? (
                        <div className='flex flex-row'>
                            <button 
                            onClick={() => {
                                setPage(page-1);
                            }}
                            className='px-3 py-3 bg-slate-200 rounded-full'>
                                <FiChevronLeft size={20}/>
                            </button>
                        </div>
                    ) : (
                        <div className='flex flex-row px-6 py-2 w-full'>
                        </div>
                    )}

                    {page===5 
                    ? (
                        <button 
                        onClick={() => {
                            if(canContinue()){
                                donateToCampaign();
                            }else{
                                toast.error("Please read and agree with Lomi's donation terms.");
                            }
                        }}
                        className='flex flex-row px-8 py-2 bg-primary-blue rounded-md items-center duration-200 justify-center gap-2'>
                            {donationLoading && <LoadingSpinner radius='5'/>}
                            <div className='text-primary-green text-lg font-rubik'>
                                DONATE
                            </div>
                        </button>
                    ) : (
                        <button 
                        onClick={() => {
                            if(canContinue()){
                                if(page<5){
                                    setPage((prev_page) => prev_page+1)
                                }
                            }else{
                                switch (page) {
                                    case 0:
                                        toast.error("Please fill in amount field");
                                        break;
                                    case 2:
                                        toast.error("Please add a message for the fundraiser");
                                        break;
                                    case 3:
                                        toast.error("Please choose a payment method");
                                        break;
                                
                                    default:
                                        break;
                                }
                            }
                        }}
                        className='flex flex-row px-8 py-2 bg-primary-blue rounded-md items-center duration-200 justify-center gap-2'>
                            {donationLoading && <LoadingSpinner radius='5'/>}
                            <div className='text-primary-green text-lg font-rubik'>
                                NEXT
                            </div>
                        </button>
                    )}
                    
                </div>
                
                {/* <div className="flex flex-row justify-end">
                    <button 
                    onClick={donateToCampaign}
                    className='flex flex-row px-6 py-2 bg-primary-blue rounded-md items-center duration-200 w-full justify-center gap-2'>
                        {donationLoading && <LoadingSpinner radius='5'/>}
                        <div className='text-primary-green text-lg font-rubik'>
                            Donate
                        </div>
                    </button>
                </div> */}
            </div>
        </div>
    );
}

export default DonationScreen;