import React, { useEffect, useState } from 'react';
import AccountDetails from './accountDetails';

import { WithdrawService } from '../../services';
import { Utils } from '../../utils';

function WithdrawScreen() {
    const withdrawService = new WithdrawService();
    const utils = new Utils();

    const [withdraws, setWithdraws] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        withdrawService.getMyWithdraws().then((res) => {
            console.log(res.data);
            setWithdraws(res.data.data);
        }).catch((err) => {
            console.log(err);
        }).finally(() => setLoading(false));
        // eslint-disable-next-line
    }, [])

    return (
        <div className='w-full flex flex-col items-center font-rubik gap-3 pt-20 md:pt-36 mb-20'>
            <div className='flex flex-col w-full xl:w-4/5 2xl:w-2/3 px-3 md:px-10 xl:px-20 2xl:px-5 gap-2 items-center'>
                <AccountDetails/>

                <div className="flex flex-col gap-2 w-full">
                    {/* <p>Withdraws list</p> */}
                    <h3>{loading && 'Loading...'}</h3>

                    {withdraws && (
                        <table className="table-auto w-full">
                            <caption className="caption-top">
                                My recent withdraws
                            </caption>
                             <thead>
                                <tr>
                                    <th className='text-center'>Requested Date</th>
                                    <th className='text-center'>Amount</th>
                                    <th className='text-center'>Currency</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Updated on</th>
                                </tr>
                            </thead>
                            <tbody>
                                {withdraws.map((withdraw, index) => {
                                    return (
                                        <tr key={withdraw.id} className={`h-12 hover:bg-slate-200 duration-200 ${(index+1)%2===0 ? 'bg-slate-50' : 'bg-slate-100'}`}>
                                            <td className='text-center'>{utils.getDateString(withdraw.dateCreated)}</td>
                                            <td className='text-center'>{utils.addCommaToNumber(withdraw.amount)}</td>
                                            <td className='text-center'>{`${withdraw.currency}`.toUpperCase()}</td>
                                            <td className='text-center'>
                                                <div className="flex flex-row items-center justify-center">
                                                    <div className='bg-orange-400 px-3 py-1 text-[13px] rounded-full text-white'>
                                                        {withdraw.status}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='text-center'>{utils.getDateString(withdraw.dateUpdated)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
}

export default WithdrawScreen;