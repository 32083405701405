import React, { useEffect, useState } from 'react';
// import { FiCheck } from "react-icons/fi";
import { Utils } from '../../../utils';

function DonatorInfo({firstname, setFirstname, lastname, setLastname, email, setEmail}) {
    const utils = new Utils();

    const [validEmail, setValidEmail] = useState(false);

    useEffect(() => {
        // 
        const val = utils.validateEmail(email);
        setValidEmail(val);
    // eslint-disable-next-line
    }, [email])

    return (
        <div className='w-full flex flex-col gap-2 py-10'>
            <div className='text-xl md:text-2xl font-medium text-primary-blue'>
                Donator Information
            </div>
            <div className='text-primary-blue text-md line-clamp-3 mb-3'>
                These fields are optional and can be left empty.
            </div>

            <div className="flex flex-col">
                <p>First Name:</p>
                <input
                value={firstname} 
                onChange={(val) => setFirstname(val.currentTarget.value)}  
                placeholder='First Name' 
                className="bg-slate-100 rounded-md px-4 py-3 flex-grow text-base mb-2"/>
            </div>

            <div className="flex flex-col">
                <p>Last Name:</p>
                <input
                value={lastname} 
                onChange={(val) => setLastname(val.currentTarget.value)}  
                placeholder='Last Name' 
                className="bg-slate-100 rounded-md px-4 py-3 flex-grow text-base mb-2"/>
            </div>

            <div className="flex flex-col">
                <p>Email Address: {email!=='' && !validEmail && <span className='text-sm text-red-500 italic'>Invalid Email Address</span>}</p>
                <input
                value={email} 
                onChange={(val) => setEmail(val.currentTarget.value)}  
                placeholder='Email Address' 
                className={`bg-slate-100 rounded-md px-4 py-3 flex-grow text-base mb-2 border-[1px]`}/>
            </div>

            
        </div>
    );
}

export default DonatorInfo;