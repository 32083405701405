import React, {useEffect, useState} from 'react';
import YouTube from 'react-youtube';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function HowitworksScreen() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
        setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const opts = {
      height: windowDimensions.width < 768 ? (windowDimensions.width*0.8)*0.63 : (windowDimensions.width*0.8)*0.4,
      width: windowDimensions.width < 768 ? windowDimensions.width*0.90 : windowDimensions.width*0.65,
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        rel: 0,
        color: "white"
      },
    };
    return (
        <div className='min-h-screen w-full flex flex-col justify-center items-center font-rubik gap-2 pt-20'>
            <div className="flex flex-col items-start md:w-2/3 w-11/12">
                <div className='text-primary-blue text-xl lg:text-3xl font-medium'>
                    How Lomi Works?
                </div>
                <span className='text-sm lg:text-lg font-normal text-primary-blue'>
                    Please check the video below to understand how lomi works.
                </span>
            </div>

            <YouTube className='player-body bg-black' videoId="EVkA8WWMCss" opts={opts} onEnd={(e)=>{
                console.log('Video Ended')
            }}/>

            <div className="flex flex-row mt-4 gap-2 flex-wrap">
                <a href='/' className='flex flex-row gap-2 px-4 py-2 bg-primary-blue rounded-lg items-center duration-200 justify-center mb-4'>
                    <div className='text-primary-green text-lg font-rubik'>
                        Go to Home
                    </div>
                </a>
                <a href='/explore' className='flex flex-row gap-2 px-4 py-2 bg-primary-blue rounded-lg items-center duration-200 justify-center mb-4'>
                    <div className='text-primary-green text-lg font-rubik'>
                        Browse Campaigns
                    </div>
                </a>
            </div>
        </div>
    );
}

export default HowitworksScreen;