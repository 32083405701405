import React from 'react';

import { FiCheck, FiEdit3 } from "react-icons/fi";

import { Utils } from '../../../utils';

function DonationConfirm({amount, currency, isTermChecked, setIsTermChecked, tip, setPage}) {
    const utils = new Utils();
    return (
        <div className='w-full flex flex-col gap-2 py-10'>
            <div className='text-xl md:text-2xl font-medium text-primary-blue'>
                Confirmation
            </div>
            <div className='text-primary-blue text-md line-clamp-3 mb-3'>
                Please confirm the amount you chose
            </div>

            <div className='flex flex-col mb-2'>
                <div className="font-medium text-primary-blue text-lg">Total: </div>
                <div className="font-medium text-primary-green text-3xl">
                    {amount ? utils.addCommaToNumber((parseInt(amount) + parseInt(tip ? tip : '0'))) : '0'} {currency}
                </div>
            </div>

            <div className="flex flex-col gap-1 mb-3">
                <div className="font-medium text-primary-blue text-lg">Payment Details </div>
                <div className='flex flex-row gap-3 items-center py-3 px-3 border-2 border-slate-200 rounded-md'>
                    <div className="font-normal text-primary-blue text-base">
                        Donation:
                    </div> 
                    <div className='flex-grow text-primary-green text-lg'>
                        {amount ? utils.addCommaToNumber(parseInt(amount)) : '0'} {currency}
                    </div>
                    <button onClick={()=>setPage(0)}>
                        <FiEdit3 className='text-primary-blue text-lg'/>
                    </button>
                </div>
                <div className='flex flex-row gap-3 items-center py-3 px-3 border-2 border-slate-200 rounded-md'>
                    <div className="font-normal text-primary-blue text-base">
                        Tip for Lomi:
                    </div> 
                    <div className='flex-grow text-primary-green text-lg'>
                        {tip ? utils.addCommaToNumber(parseInt(tip)) : '0'} {currency}
                    </div>
                    <button onClick={()=>setPage(4)}>
                        <FiEdit3 className='text-primary-blue text-lg'/>
                    </button>
                </div>
            </div>

            <div className="gap-2 flex flex-row items-center mt-4">
                <button 
                onClick={() => setIsTermChecked(!isTermChecked)}
                className={`w-5 h-5 rounded-md flex flex-row justify-center items-center ` + (isTermChecked ? 'bg-primary-green' : 'bg-slate-100 border-primary-blue border-2')}>
                    {isTermChecked && <FiCheck className='text-white text-md'/>}
                </button>
                <div className='font-normal text-base text-primary-blue'>
                    I have read and agreed to Lomi's <a href="/legal/donationpolicy" target="_blank" className='text-primary-green'>Donation Terms</a>.
                </div>
            </div>
            
        </div>
    );
}

export default DonationConfirm;