import React, { useEffect } from 'react';

import { FiCheck } from "react-icons/fi";

function DonationAmount({amount, currency, setAmount, setCurrency, campaignData}) {
    // const [openCurrency, setOpenCurrency] = useState(false);

    useEffect(() => {

        if(campaignData){
            const etbGoal = campaignData.goalEtbAmount||0;
            // const usdGoal = campaignData.goalUsdAmount||0;

            if(etbGoal===0){
                setCurrency('USD');
            }
        }
        // eslint-disable-next-line
    }, [campaignData])

    return (
        <div className='w-full flex flex-col gap-2 py-10'>
            <div className='text-xl md:text-2xl font-medium text-primary-blue'>
                How much do you want to donate?
            </div>
            <div className='text-primary-blue text-md line-clamp-3 mb-3'>
                This amount will be deposited to the campaign.
            </div>

            

            <div className="flex flex-row gap-2 mb-2">
                <input type="text" value={amount} 
                onChange={(e) => {
                    const val = e.currentTarget.value;
                    let isnum = /^\d+$/.test(val);
                    if(isnum || val===''){
                        setAmount(val);
                    }
                }} placeholder="0.00"
                className="bg-slate-100 rounded-md px-4 py-3 flex-grow text-2xl"/>
                
            </div>


            <div className="flex flex-col gap-2 mb-4">
                <div className='text-primary-blue text-xl font-medium'>
                    Currency:
                </div>
                {campaignData && (
                    <div className="relative flex flex-row gap-2">
                        {campaignData.goalEtbAmount && campaignData.goalEtbAmount>0 ? (
                            <button onClick={() => {
                                setCurrency('ETB');
                            }}
                            className={`px-6 py-3 rounded-md items-center flex flex-row gap-2 ` + (currency==='ETB' ? 'bg-primary-blue' : 'bg-white')}>
                                {currency==='ETB' && <FiCheck className={`text-lg text-primary-green`}/>}
                                <div className={`text-base tracking-wider font-rubik line-clamp-1 ` + (currency==='ETB' ? 'text-primary-green' : 'text-primary-blue')}>
                                    ETB
                                </div>
                            </button>
                        ) : <div></div>}

                        {campaignData.goalUsdAmount && campaignData.goalUsdAmount>0 ? (
                            <button onClick={() => {
                                setCurrency('USD');
                            }}
                            className={`px-6 py-3 rounded-md items-center flex flex-row gap-2 ` + (currency==='USD' ? 'bg-primary-blue' : 'bg-white')}>
                                {currency==='USD' && <FiCheck className={`text-lg text-primary-green`}/>}
                                <div className={`text-base tracking-wider font-rubik line-clamp-1 ` + (currency==='USD' ? 'text-primary-green' : 'text-primary-blue')}>
                                    USD
                                </div>
                            </button>
                        ) : <div></div>}

                    </div>
                )}
            </div>
            
        </div>
    );
}

export default DonationAmount;