import React, {useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import  { toast } from 'react-toastify';

import { UserContext } from '../../contexts';
import { CampaignService } from '../../services';

import SelectCategories from './selectCategories';
import StartingGoal from './startingGoal';
import CampaignDetails from './campaignDetails';
import CampaignFiles from './campaignFiles';
import ConfirmationScreen from './confirmScreen';

import { LoadingSpinner } from '../shared';

function CreateCampaignScreen() {
    // const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const {user} = useContext(UserContext);
    const campaignService = new CampaignService();
    
    const [selectedCategory, setSelectedCategory] = useState('');
    // const [amount, setAmount] = useState('');

    const [goalEtbAmount, setGoalEtbAmount] = useState('');
    const [goalUsdAmount, setGoalUsdAmount] = useState('');

    const [currency, setCurrency] = useState('ETB');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [photo, setPhoto] = useState(null);
    const [supportingDocs, setSupportingDocs] = useState([]);
    const [videoUrl, setVideoUrl] = useState('');

    // const [errorMessage, setErrorMessage] = useState({});

    const [loading, setLoading] = useState(false);

    // const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);

    const addSupportingFile = (file) => {
        setSupportingDocs((docs) => {
            return [...docs, file];
        });
    }

    const removeSupportingFile = (index) => {
        let docs = [...supportingDocs];
        let newDocs = docs.filter((item, idx) => idx!==index);
        setSupportingDocs(newDocs);
    }

    // function validateYoutubeUrl(url) {
    //     const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/;
    //     return youtubeUrlRegex.test(url);
    // }

    const canContinue = () => {
        if(page===1){
            if(selectedCategory!==''){
                return true;
            }else{
                return false;
            }
        }else if(page===2){
            if(goalEtbAmount!=='' && goalUsdAmount!=='' && currency!==''){
                return true;
            }else{
                return false;
            }
        }else if(page===3){
            if(title!=='' && description!==''){
                return true;
            }else{
                return false;
            }
        }else if(page===4){
            // && supportingDocs.length!==0
            if(photo){
                return true;
            }else{
                return false;
            }
        }
    }

    // const getFileNameAndType = (img) => {
    //     let filename = img.name;

    //     let match = /\.(\w+)$/.exec(filename);
    //     let type = match ? `image/${match[1]}` : `image`;

    //     return {name: filename, type: type};
    // }

    const createCampaign = () => {
        let formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('category', selectedCategory);
        formData.append('createdBy', user?.id);
        formData.append('goalEtbAmount', goalEtbAmount);
        formData.append('goalUsdAmount', goalUsdAmount);
        formData.append('createdByCharity', false);
        formData.append('videoUrl', videoUrl);


        // const nmt = getFileNameAndType(photo);
        // formData.append('image', JSON.stringify({ uri: URL.createObjectURL(photo), ...nmt }));
        formData.append('image', photo);

        supportingDocs.forEach((doc, idx) => {
            // const docnmt = getFileNameAndType(doc);
            // formData.append(`doc-${idx}`, JSON.stringify({ uri: URL.createObjectURL(doc), ...docnmt }));
            formData.append(`doc-${idx}`, doc);
        });


        // const vls = formData.values()
        console.log(URL.createObjectURL(photo));
        console.log(formData.get('image'));
        
        setLoading(true);
        campaignService.createCampaign(formData)
        .then((response) => {
            const res = response.data;
            // setModalVisible(true);
            console.log(res);
            toast.success("Campaign Created Successfully!");
            navigate('/user');
        })
        .catch((error) => {
            toast.error("Campaign Creation Failed!");
            console.log(error);
        })
        .finally(() => setLoading(false));
    }


    return (
        <div className='w-full flex flex-col justify-start items-center font-rubik gap-3 pt-20 md:pt-36'>

            <div className='flex flex-col w-full md:w-2/3 lg:w-1/2 xl:w-2/5 2xl:w-1/3 px-4 md:px-10 2xl:px-5 gap-2 md:gap-4 mb-4'>
                
                {page}/5

                {page===1 && <SelectCategories selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}/>}
                {page===2 && <StartingGoal goalEtbAmount={goalEtbAmount} setGoalEtbAmount={setGoalEtbAmount} goalUsdAmount={goalUsdAmount} setGoalUsdAmount={setGoalUsdAmount} currency={currency} setCurrency={setCurrency}/>}
                {page===3 && <CampaignDetails title={title} setTitle={setTitle} desc={description} setDesc={setDescription} videoUrl={videoUrl} setVideoUrl={setVideoUrl}/>}
                {page===4 && <CampaignFiles photo={photo} setPhoto={setPhoto} supporting={supportingDocs} setSupporting={addSupportingFile} removeDoc={removeSupportingFile}/>}
                {page===5 && <ConfirmationScreen campaign={{title: title, description:description, photo:photo, supportingDocs:supportingDocs, goalEtbAmount:goalEtbAmount, goalUsdAmount:goalUsdAmount, category:selectedCategory}}/>}
                

                {loading && (
                    <div className="flex flex-row items-center gap-2 mt-2">
                        <LoadingSpinner/>
                        <div className="text-primary-blue">
                            Creating your campaign
                        </div>
                    </div>
                )}

                <div className="flex flex-row gap-3 md:justify-between">
                    {page>1 
                    && (
                        <div onClick={()=> {
                            if(page>1 && !loading){
                                setPage(page-1)
                            }
                        }} 
                        className="flex flex-row justify-end mt-6">
                            <button className='flex flex-row gap-2 px-6 py-2 bg-slate-100 rounded-md items-center duration-200 w-full justify-center'>
                                <div className='text-primary-blue text-lg font-rubik'>
                                    Back
                                </div>
                            </button>
                        </div>
                    )}

                    <div 
                    className="flex flex-row justify-end mt-6 flex-grow md:flex-grow-0">
                        {page===5 
                        ? (
                            <button onClick={()=> {
                                //Create Campaign Here
                                if(!loading){
                                    createCampaign();
                                }
                            }}  className='flex flex-row gap-2 px-6 py-2 bg-primary-blue rounded-md items-center duration-200 w-full justify-center'>
                                <div className='text-primary-green text-lg font-rubik'>
                                    Create
                                </div>
                            </button>
                        ) 
                        : (
                            <button onClick={()=> {
                                if(canContinue()){
                                    if(page<5){
                                        setPage(page+1)
                                    }
                                }else{
                                    switch (page) {
                                        case 1:
                                            toast.error("Please choose a category for your campaign");
                                            break;
                                        case 2:
                                            toast.error("Please fill goal amount & choose currency");
                                            break;
                                        case 3:
                                            toast.error("Please fill out the form");
                                            break;
                                        case 4:
                                            toast.error("Please add photo and supporting documents");
                                            break;
                                    
                                        default:
                                            break;
                                    }
                                }
                            }}  className='flex flex-row gap-2 px-6 py-2 bg-primary-blue rounded-md items-center duration-200 w-full justify-center'>
                                <div className='text-primary-green text-lg font-rubik'>
                                    Continue
                                </div>
                            </button>
                        )}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CreateCampaignScreen;