import React from 'react';
import { Link } from 'react-router-dom';

function TermsScreen() {
    return (
        <div className='w-full flex flex-col font-rubik gap-2 pt-20 md:pt-36 items-center'>
            <div className='flex flex-col w-full xl:w-4/5 px-4 md:px-10 xl:px-20 2xl:px-5 gap-4 items-center md:items-start pb-10'>
                <div className='text-primary-blue text-2xl md:text-3xl font-medium mb-2'> Lomi.et Terms of Service </div>
                <div className="flex flex-row">
                    <Link to='/legal' className='text-primary-green text-base mr-2'> Legal </Link>
                    <div className='text-slate-400 text-base mr-2'> / </div>
                    <div className='text-slate-400 text-base'> Terms of Service </div>
                </div>

                <div className='text-primary-blue text-md text-justify'> Between LomiTech PLC ("Lomi" or "we" or "us") and the user of this website ("you" or "User") </div>
                <div className='text-primary-blue text-md text-justify'> Last updated: 12 March 2023 </div>

                {/* ----------------------------------- 1.0 --------------------------------- */}

                <div className='text-primary-blue text-md text-center md:text-justify font-medium '> 1. Introduction </div>

                <div className='flex flex-col gap-4 md:pl-4 '>
                    <div className='text-primary-blue text-md text-justify'> 1.1.	Welcome to Lomi (Lomi.et) (the "Website",  the “Lomi app”) and its associated 
                    services are operated by Lomitech PLC("Lomi" or "we" or "us"). These terms  ("Terms of Service") govern your use of the Website and its associated 
                    services including the mobile. </div>

                    <div className='text-primary-blue text-md text-justify'> 1.2.	The Website is an online platform where people and organizations can campaign 
                    and raise funds for projects to benefit social, community or charitable causes and where Supporters can donate money ("Donation") to support those 
                    campaigns. </div>

                    <div className='text-primary-blue text-md text-justify'> 1.3.	Please read these Terms of Service carefully. If you do not wish to be bound 
                    by these Terms of Service, you should not continue to use or access the Website or its associated services.  </div>

                    <div className='text-primary-blue text-md text-justify'> 1.4.	You acknowledge and agree that you have read and understood these Terms of Service 
                    and you agree to be bound by these Terms of Service each time you use the Website.  </div>

                    <div className='text-primary-blue text-md text-justify'> 1.5.	If you are accessing the Website in order to create a Campaign, you agree to 
                    these Terms of Service both personally and on behalf of your Organization as its authorized representative. If you are not authorized to accept 
                    these Terms of Service for your Organization, you must not submit a Campaign to the Website on its behalf.   </div>

                    <div className='text-primary-blue text-md text-justify'> 1.6.	These Terms of Service may change or be updated from time to time. Lomi will post 
                    updates to the Terms of Service on the Website from time to time. You must check for updates whenever you access the Website. The latest version of these 
                    Terms of Service will govern any usage by you of the Website and its associated services from the date we post the revised version on the Website. </div>
                </div>


                {/* ----------------------------------- 2.0 --------------------------------- */}


                <div className='text-primary-blue text-md text-center md:text-justify font-medium '> 2.	Terms for Supporters | Donating to a Campaign: General </div>

                <div className='flex flex-col gap-4 md:pl-4 '>
                    <div className='text-primary-blue text-md text-justify'> 2.1  You make your Donation to a Campaign, not to Lomi - we introduce Supporters to 
                    Organizations and their Campaigns. Donations can be made via Telebirr, Chapa, bank transfer, PayPal or credit or debit card or direct debit. All 
                    transfers done by local payments providers or gateway operators are processed by themselves under the terms of agreement with them. All PayPal 
                    Donations are processed by PayPal under the terms of your agreement with PayPal. All credit and debit card and direct debit Donations are processed 
                    by Stripe or Stripe Connect (our other "payment providers") in accordance with the terms of this Agreement. Transaction fees apply 
                    (see clauses 2.12 and 2.13 below for more information).  </div>

                    <div className='text-primary-blue text-md text-justify'> 2.2.	 Donations will be processed in the currency which is indicated on the payment 
                    page but will be deposited in ETB. You may be able to change this currency. If your payment card or PayPal or bank account is in a different currency, 
                    conversion fees may apply, and may be charged to you by your financial institution. Donations can be made at any time from the start of a campaign up 
                    to the Funding Deadline for time limited campaigns. For time limited campaigns, once the Funding Deadline expires, no new Donations can be made via the 
                    Website or the App.</div>

                    <div className='text-primary-blue text-md text-justify'> 2.3.	Lomi will not pursue Supporters for uncollected Donations. It is the responsibility 
                    of an Organization to contact any Supporters who have pledged or attempted a Donation but whose payment has failed, and to ask them to provide an 
                    alternative payment method.</div>

                    <div className='text-primary-blue text-md text-justify'> 2.4.	Donations to a campaign will be charged to your PayPal account or credit or debit 
                    card or bank account at the time you make your Donation. PayPal and Stripe Connect Donations will be paid to the a campaign designated account at or 
                    around the time of your Donation. Any amounts indicated as a Contribution to Lomi will be transferred by the Organizations to Lomi at or near the time 
                    of transaction.</div>
                </div>



                {/* ----------------------------------- 2.0 --------------------------------- */}


                <div className='text-primary-blue text-md text-center md:text-justify font-medium '> Changes to and cancellations of Donations </div>

                <div className='flex flex-col gap-4 md:pl-4 '>
                    <div className='text-primary-blue text-md text-justify'> 2.5.	You may not cancel a Donation (even if a Campaign has reached or exceeded its 
                    Fundraising Target before the Fundraising Deadline) once your Payment has been collected. If you wish to increase your support for a Campaign after 
                    your Donation has been processed, you must make a further Donation. However, no new Donations can be accepted for a Campaign once its Funding Deadline 
                    has passed. Regular Donations will continue to transact at the chosen frequency after the Funding Deadline has passed.   </div>

                    <div className='text-primary-blue text-md text-justify'> 2.2.	 Donations will be processed in the currency which is indicated on the payment 
                    page but will be deposited in ETB. You may be able to change this currency. If your payment card or PayPal or bank account is in a different currency, 
                    conversion fees may apply, and may be charged to you by your financial institution. Donations can be made at any time from the start of a campaign up 
                    to the Funding Deadline for time limited campaigns. For time limited campaigns, once the Funding Deadline expires, no new Donations can be made via the 
                    Website or the App.</div>

                    <div className='text-primary-blue text-md text-justify'> 2.3.	Lomi will not pursue Supporters for uncollected Donations. It is the responsibility 
                    of an Organization to contact any Supporters who have pledged or attempted a Donation but whose payment has failed, and to ask them to provide an 
                    alternative payment method.</div>

                    <div className='text-primary-blue text-md text-justify'> 2.4.	Donations to a campaign will be charged to your PayPal account or credit or debit 
                    card or bank account at the time you make your Donation. PayPal and Stripe Connect Donations will be paid to the a campaign designated account at or 
                    around the time of your Donation. Any amounts indicated as a Contribution to Lomi will be transferred by the Organizations to Lomi at or near the time 
                    of transaction.</div>
                </div>


            </div>
        </div>
    );
}

export default TermsScreen;