import React from 'react';
import { Routes, Route, Link } from "react-router-dom";

import PrivacyScreen from './privacy.screen';
import TermsScreen from './terms.screen';
import DonationPolicy from './donation.policy';

function LegalScreen() {
    return (
        <div>
            <Routes>
                <Route path='/' element={<LegalScreenChooser/>} />
                <Route path='/termsofservice' element={<TermsScreen/>} />
                <Route path='/privacypolicy' element={<PrivacyScreen/>} />
                <Route path='/donationpolicy' element={<DonationPolicy/>} />
            </Routes>
        </div>
    );
}


function LegalScreenChooser() {
    return (
        <div className='w-full flex flex-col font-rubik gap-2 pt-20 md:pt-36 items-center'>
            <div className='flex flex-col w-full xl:w-4/5 px-4 md:px-10 xl:px-20 2xl:px-5 gap-4'>
                <div className='text-primary-blue text-3xl font-medium mb-4'> Lomi Legal Documents </div>

                <div className='text-primary-blue text-md lg:text-lg text-justify'> This section has the documents lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean rutrum tempor ultrices. 
                Nulla non risus ac diam pretium gravida. Curabitur ipsum augue. </div>


                <div className="flex flex-col md:flex-row gap-4">
                    <Link to='/legal/termsofservice' 
                    className='text-xl md:text-2xl flex-grow py-10 px-4 bg-slate-100 hover:bg-slate-200 duration-200 rounded-lg items-center flex flex-col'> 
                        Terms of Service 
                    </Link>
                    <Link to='/legal/privacypolicy' 
                    className='text-xl md:text-2xl flex-grow py-10 px-4 bg-slate-100 hover:bg-slate-200 duration-200 rounded-lg items-center flex flex-col'> 
                        Privacy Policy 
                    </Link>
                    <Link to='/legal/donationpolicy' 
                    className='text-xl md:text-2xl flex-grow py-10 px-4 bg-slate-100 hover:bg-slate-200 duration-200 rounded-lg items-center flex flex-col'> 
                        Donation Policy
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default LegalScreen;