import React from 'react';
// import { FiCheck } from "react-icons/fi";

const pay = [
    {id: 0, name: 'Chapa', img: 'https://i.ibb.co/59zfQ7N/chapa-logo.png', cur:'ETB'},
    // {id: 1, name: 'Telebirr', img: 'https://telegebeya.ethiotelecom.et/images/TeleBirr-Logo.svg', cur:'ETB'},
    {id: 2, name: 'Card', img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png', cur:'USD'},
];

function PaymentMethod({selectedPay, setSelectedPay, currency}) {
    return (
        <div className='w-full flex flex-col gap-3 py-10'>
            <div className='text-xl md:text-2xl font-medium text-primary-blue'>
                Which Payment method would you like to use?
            </div>
            <div className='text-primary-blue text-md line-clamp-3 mb-3'>
                We are currently working with these payment options:
            </div>

            <div className="flex flex-row w-full flex-wrap">
                {pay.map((paymentMethod) => {
                    if(paymentMethod.cur==='all' || paymentMethod.cur.toLowerCase()===currency.toLowerCase()){
                        return (
                            <button key={paymentMethod.id} onClick={() => setSelectedPay(paymentMethod.id)} className='w-1/3 p-1 md:p-2'>
                                <div className={`flex flex-col px-2 py-5 gap-1 rounded-xl justify-center items-center border-2 overflow-hidden ` 
                                + (paymentMethod.id===selectedPay ? 'border-primary-green' : 'border-slate-100')}>
                                    <img src={paymentMethod.img} alt="pay logo" className='object-contain w-20 lg:w-24 h-10 lg:h-12'/>
                                    <div className="text-base md:text-lg text-primary-blue font-medium">
                                        {paymentMethod.name}
                                    </div>
                                </div>
                            </button>
                        );
                    }else{
                        return <div></div>
                    }
                })}
            </div>
            
        </div>
    );
}

export default PaymentMethod;