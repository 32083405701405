import React, {useState, useContext, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import { UserContext } from '../../contexts';
import ReportService from '../../services/report.service';

function ReportScreen() {
    const reportService = new ReportService();
    const {user} = useContext(UserContext);
    const {id} = useParams();
    const navigate = useNavigate();

    const [reportCategory, setReportCategory] = useState('');
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(false);
    const [formValid, setFormValid] = useState(false);

    const options = [
    'Fake Campaign', 'Violence', 'False fundraiser or creator', 'Other'
    ];

    const reportCampaign = () => {
        const reportData = {
            campaignId: id,
            reportType: reportCategory,
            message: message,
            reportedBy: user?.id
        }
        setLoading(true);
        reportService.reportCampaign(reportData)
        .then((res) => {
            console.log(res.data);
            toast.success("Campaign Reported, Thank you!");
            navigate(-1);
        }).catch((err) => {
            toast.error("Reporting Failed");
            console.log(err);
        })
        .finally(() => setLoading(false));
    }


    useEffect(() => {
        if(message!=='' || reportCategory!==''){
            setFormValid(true);
        }else{
            setFormValid(false);
        }
    }, [message, reportCategory]);


    
    return (
        <div className='w-full flex flex-col font-rubik gap-2 pt-20 md:pt-36 items-center'>
            <div className='flex flex-col w-full xl:w-4/5 px-4 md:px-10 xl:px-20 2xl:px-5 gap-4 items-center'>

                <div className="flex flex-col gap-4 px-0 py-10 w-full md:w-2/3 lg:w-1/2">
                    <div className="flex flex-col mb-2">
                        <div className='text-primary-blue text-2xl md:text-3xl font-medium mb-2'> Report Campaign </div>
                        <div className='text-primary-blue text-sm md:text-base text-justify'> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean rutrum tempor ultrices. 
                        Nulla non risus ac diam pretium gravida. Curabitur ipsum augue. </div>
                    </div>

                    <div className="flex flex-col">
                        <div className='text-primary-blue text-lg md:text-xl font-medium'> What happend? </div>
                        <div className='text-slate-500 text-sm md:text-base text-justify'> You can select one of the pre-defined reasons to report a campaign </div>
                    </div>
                    <Dropdown className='w-full'
                    options={options} 
                    onChange={(val) => setReportCategory(val.value)} 
                    placeholder="Choose Report Type" />
                    

                    {reportCategory==='Other' && (
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col mt-4">
                                <div className='text-primary-blue text-lg md:text-xl font-medium'> Other? </div>
                                <div className='text-slate-500 text-md text-justify'> You can describe the fraudulent activity here. </div>
                            </div>

                            <textarea cols="30" rows="5"
                            value={message} 
                            onChange={(val) => setMessage(val.currentTarget.value)}  
                            placeholder='Your reason' 
                            className="bg-slate-100 rounded-md px-4 py-3 flex-grow rounded-tr-none rounded-br-none w-full"/>

                            
                        </div>
                    )}

                    <button onClick={reportCampaign} disabled={!formValid}
                    className='flex flex-row gap-2 px-6 py-2 bg-primary-blue rounded-md items-center duration-200 w-full justify-center mt-2'>
                        <div className='text-primary-green text-lg font-rubik'>
                            {loading ? 'Reporting...' : 'Report'}
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ReportScreen;