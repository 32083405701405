import React, {useState, useEffect} from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import {UserContext} from "./contexts";
import { LoginScreen, SignupScreen, HomeScreen, CampaignScreen, CampaignDetailScreen, DonationScreen, PageNotFoundScreen,
        SavedCampaignsScreen, HowitworksScreen, NotificationsScreen, UserProfileScreen, LegalScreen, CreateCampaignScreen, 
        AboutScreen, ReportScreen, SignupReponse, VerifyScreen, ForgotPasswordScreen, WithdrawScreen, UserDeleteScreen } from "./screens";

import DonationSuccess from "./screens/donationScreen/donation.success";
import DonationCanceled from "./screens/donationScreen/donation.canceled";

import { AppBar } from "./screens/shared";
import { UserService } from "./services";
import lomi_logo from './assets/lomi-logo.png';

function App() {
  const userService = new UserService();
  // const exchangeService = new ExchangeService();

  const [offset, setOffset] = useState(false);
  
  const [user, setUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userChecked, setUserChecked] = useState(false);

  // const [todaysExchangeRate, setTodaysExchangeRate] = useState();

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset > 0);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);


  // useEffect(() => {
  //   const getExchangeRate = () => {
  //     exchangeService.getTodayExchangeRate();
  //   }

  //   getExchangeRate();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    // Fetch from 
    const token = Cookies.get('user-token');
    console.log(token);
    
    if(token!==undefined) {
      userService.fetchUserData()
      .then((result) => {
        setLoggedIn(true);
        setUser(result.data.data);
      }).catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoggedIn(true);
        setUserChecked(true);
      })
    }else{
      setUserChecked(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider value={{user, setUser, loggedIn, setLoggedIn}}>
      {userChecked ? (
        <div className="min-h-screen w-full justify-center items-center">
          <Router>
            <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"/>

            <AppBar scrolled={offset}/>
            <Routes>
              <Route path="/" element={<HomeScreen/>}/>
              <Route path="/login" element={<LoginScreen/>}/>
              <Route path="/signup" element={<SignupScreen/>}/>
              <Route path="/forgotpassword" element={<ForgotPasswordScreen/>}/>
              <Route path="/verifyaccount" element={<SignupReponse/>}/>
              <Route path="/accountverified" element={<VerifyScreen/>}/>


              <Route path="/explore" element={<CampaignScreen/>}/>
              <Route path="/campaign/:id" element={<CampaignDetailScreen/>}/>
              <Route path="/donation/:id" element={<DonationScreen/>}/>
              <Route path="/donation/success" element={<DonationSuccess/>}/>
              <Route path="/donation/canceled/:id" element={<DonationCanceled/>}/>
              <Route path="/howitworks" element={<HowitworksScreen/>}/>
              <Route path="/about" element={<AboutScreen/>}/>

              <Route path="/legal/*" element={<LegalScreen/>}/>

              <Route path="/saved" element={<ProtectedRoute user={user}><SavedCampaignsScreen /></ProtectedRoute>}/>
              <Route path="/notifications" element={<ProtectedRoute user={user}><NotificationsScreen /></ProtectedRoute>}/>
              <Route path="/user" element={<ProtectedRoute user={user}><UserProfileScreen /></ProtectedRoute>}/>
              <Route path="/create" element={<ProtectedRoute user={user}><CreateCampaignScreen /></ProtectedRoute>}/>

              <Route path="/report/:id" element={<ReportScreen />}/>

              <Route path="/withdraw" element={<ProtectedRoute user={user}><WithdrawScreen /></ProtectedRoute>}/>
              <Route path="/delete-account" element={<ProtectedRoute user={user}><UserDeleteScreen /></ProtectedRoute>}/>

              <Route path="*" element={<PageNotFoundScreen/>}/>
            </Routes>
          </Router>
        </div>
      ) : (
        <div className="min-h-screen w-full flex flex-col justify-center items-center">
          <img src={lomi_logo} alt="Lomi logo"  className='w-16 lg:w-24'/>
        </div>
      )}
    </UserContext.Provider>
  );
}

const ProtectedRoute = ({user, children}) => {
  if (user==null) {
    return <Navigate to={'/login'} replace />;
  }

  return children;
};

export default App;
