import React, {useState, useEffect, useContext} from 'react';
import { FiEdit3 } from "react-icons/fi";
import { Link } from 'react-router-dom';

import {  UserService } from '../../services';
import { UserContext } from '../../contexts';
import { Utils } from '../../utils';

import MyCampaigns from './mycampaigns';
import EditProfilePopup from './editprofile.popup';

function UserProfileScreen() {
    const utils = new Utils();
    const userService = new UserService();
    const {user, setUser, loggedIn} = useContext(UserContext);

    const [showPopup, setShowPopup] = useState(false);

    const [userStat, setUserStat] = useState(null);

    const [loading, setLoading] = useState(false);

    const updateUserData = async () => {
        if(loggedIn) {
            setLoading(true);
            userService.fetchUserData()
            .then((res) => setUser((usr) => res.data.data))
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
        }
    }

    const fetchUserStats = async () => {
        setLoading(true);
        userService.fetchUserStats()
        .then((res) => setUserStat((usr) => res.data.data))
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }

    useEffect(() => {
        updateUserData();
        fetchUserStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='w-full flex flex-col items-center font-rubik gap-3 pt-20 md:pt-36 mb-20'>

            <div className='flex flex-col w-full xl:w-4/5 2xl:w-2/3 px-3 md:px-10 xl:px-20 2xl:px-5 gap-2 items-center'>
                <div className="w-16 h-16 lg:w-24 lg:h-24 rounded-full bg-slate-100 mb-4 flex justify-center items-center text-xl lg:text-3xl font-bold text-primary-blue text-opacity-25">
                    {user?.firstname.charAt(0)}{user?.lastname.charAt(0)}
                </div>

                <div className='text-primary-blue text-2xl lg:text-4xl font-bold'>
                    <span className='text-primary-blue font-thin'>Welcome, </span>
                    {user?.firstname} {user?.lastname}
                </div>

                <div className='text-primary-blue text-xl mb-2'>
                    {user?.email}
                </div>

                <div className="flex flex-row justify-center gap-3">
                    <button className="flex flex-row gap-2 items-center text-secondary-blue bg-primary-green px-4 py-1.5 rounded-md" onClick={() => setShowPopup(true)}>
                        <FiEdit3/>
                        <div className='text-base'>
                            Edit Profile
                        </div>
                    </button>
                    <Link to={'/withdraw'} className="flex flex-row gap-2 items-center text-primary-green bg-secondary-blue px-4 py-1.5 rounded-md" onClick={() => setShowPopup(true)}>
                        <FiEdit3/>
                        <div className='text-base'>
                            My Withdrawals
                        </div>
                    </Link>
                </div>

                {userStat && (
                    <div className='flex flex-row w-full flex-wrap md:mb-2'>
                        <div className="p-2 w-fit flex-grow">
                            <div className="flex flex-col items-center px-6 py-2 md:py-4 rounded-lg bg-slate-50 md:gap-1 w-full">
                                <div className='text-2xl lg:text-3xl text-primary-green font-medium md:font-bold'>
                                    {userStat?.campaigns}
                                </div>
                                <div className='text-lg text-primary-blue'>
                                    Campaigns Created
                                </div>
                            </div>
                        </div>
                        <div className="p-2 w-fit flex-grow">
                            <div className="flex flex-col items-center px-6 py-2 md:py-4 rounded-lg bg-slate-50 md:gap-1 w-full">
                                <div className='text-2xl lg:text-3xl text-primary-green font-medium md:font-bold'>
                                    {utils.addCommaToNumber(userStat?.etbDonatedAmount)} ETB
                                </div>
                                <div className='text-lg text-primary-blue'>
                                    Donated
                                </div>
                            </div>
                        </div>
                        <div className="p-2 w-fit flex-grow">
                            <div className="flex flex-col items-center px-6 py-2 md:py-4 rounded-lg bg-slate-50 md:gap-1 w-full">
                                <div className='text-2xl lg:text-3xl text-primary-green font-medium md:font-bold'>
                                    {utils.addCommaToNumber(userStat?.usdDonatedAmount)} USD
                                </div>
                                <div className='text-lg text-primary-blue'>
                                    Donated
                                </div>
                            </div>
                        </div>
                        <div className="p-2 w-fit flex-grow">
                            <div className="flex flex-col items-center px-6 py-2 md:py-4 rounded-lg bg-slate-50 md:gap-1 w-full">
                                <div className='text-2xl lg:text-3xl text-primary-green font-medium md:font-bold'>
                                    {userStat?.donations}
                                </div>
                                <div className='text-lg text-primary-blue'>
                                    Donations
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <MyCampaigns/>

                {loading && (
                    <div>Loading...</div>
                    )}


            </div>
            {showPopup && <EditProfilePopup user={user} closePopup={() => {
                setShowPopup(false);
                updateUserData();
            }}/>}

        </div>
    );
}

export default UserProfileScreen;