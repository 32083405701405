import axios from "axios";
import { Constants } from "../utils";
import UserService from "./user.service";

const userService = new UserService();

class CharityService {

    fetchCharities = async () => {
        const token = await userService.getStoredToken();
        const config = {headers: { Authorization: `Bearer ${token}`}};
        return axios.get(`${Constants.baseUrl}/charity/`, config);
    }

    fetchCharityById = async (id) => {
        const token = await userService.getStoredToken();
        const config = {headers: { Authorization: `Bearer ${token}`}};
        return axios.get(`${Constants.baseUrl}/charity/byid/${id}`, config);
    }

    // updateCharity = async (id, data) => {
    //     const token = await userService.getStoredToken();
    //     const config = {headers: { Authorization: `Bearer ${token}`}};
    //     return axios.put(`${Constants.baseUrl}/charity/update/${id}`, data, config);
    // }

    // createCharity = async (data) => {
    //     const token = await userService.getStoredToken();
    //     const config = {headers: { Authorization: `Bearer ${token}`}};
    //     return axios.post(`${Constants.baseUrl}/charity/create`, data, config);
    // }

    // deleteCharity = async (id) => {
    //     const token = await userService.getStoredToken();
    //     const config = {headers: { Authorization: `Bearer ${token}`}};
    //     return axios.delete(`${Constants.baseUrl}/charity/delete/${id}`, config);
    // }
}

export default CharityService;