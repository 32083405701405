import React from 'react';
import { FiArrowRight } from "react-icons/fi";

import signup_img from "../../assets/illustrations/signup.svg";
import story_img from "../../assets/illustrations/story.svg";
import share_img from "../../assets/illustrations/share.svg";

function Expect() {
    return (
        <div className='flex flex-col w-full px-4 lg:px-20 py-4 lg:py-8 font-rubik gap-0'>
            <div className='text-primary-blue text-lg mb-4'>What to expect</div>
            <div className='text-2xl lg:text-4xl font-medium text-primary-blue'>Fundraising on Lomi</div>
            <div className='text-2xl lg:text-4xl font-medium text-primary-blue mb-0 lg:mb-4'>takes just a few minutes.</div>
            <div className='flex flex-row flex-wrap lg:flex-nowrap gap-5 my-8'>
                <div className='flex flex-col w-full lg:w-1/3 p-2'>
                    <div className='rounded-xl lg:p-6 flex flex-row-reverse justify-end gap-4 lg:flex-col'>
                        <div className="flex flex-col">
                            <div className="flex flex-row items-center mb-2 lg:mb-6 gap-2">
                                <div className='text-xl lg:text-2xl font-medium text-primary-blue flex flex-row justify-center items-center bg-primary-green w-10 h-10 rounded-full'>1</div>
                                <div className="hidden lg:flex flex-row gap-2 flex-grow items-center">
                                    <div className="flex flex-grow h-0.5 bg-primary-blue rounded-full ml-4"></div>
                                    <div className="w-3 h-0.5 bg-primary-blue rounded-full"></div>
                                    <div className="w-3 h-0.5 bg-primary-blue rounded-full"></div>
                                    <div className="w-3 h-0.5 bg-primary-blue rounded-full"></div>
                                    <FiArrowRight className='text-primary-blue text-xl'/>
                                </div>
                            </div>
                            <div className='text-2xl font-bold text-primary-blue'>Start with the basics</div>
                            <div className='text-lg text-primary-blue'>Kick things off with creating account on lomi.</div>
                        </div>
                        <img src={signup_img} alt="" className='w-20 mt-2 lg:w-40 lg:mt-8' />
                    </div>
                </div>
                <div className='flex flex-col w-full lg:w-1/3 p-2'>
                    <div className='rounded-xl lg:p-6 flex flex-row-reverse justify-end gap-4 lg:flex-col'>
                        <div className="flex flex-col">
                            <div className="flex flex-row items-center mb-2 lg:mb-6 gap-2">
                                <div className='text-xl lg:text-2xl font-medium text-primary-blue flex flex-row justify-center items-center bg-primary-green w-10 h-10 rounded-full'>2</div>
                                <div className="hidden lg:flex flex-row gap-2 flex-grow items-center">
                                    <div className="flex flex-grow h-0.5 bg-primary-blue rounded-full ml-4"></div>
                                    <div className="w-3 h-0.5 bg-primary-blue rounded-full"></div>
                                    <div className="w-3 h-0.5 bg-primary-blue rounded-full"></div>
                                    <div className="w-3 h-0.5 bg-primary-blue rounded-full"></div>
                                    <FiArrowRight className='text-primary-blue text-xl'/>
                                </div>
                            </div>
                            <div className='text-2xl font-bold text-primary-blue'>Tell your story</div>
                            <div className='text-lg text-primary-blue'>We'll guide you with tips along the way.</div>
                        </div>
                        <img src={story_img} alt="" className='w-20 mt-2 lg:w-40 lg:mt-8' />
                    </div>
                </div>
                <div className='flex flex-col w-full lg:w-1/3 p-2'>
                    <div className='rounded-xl lg:p-6 flex flex-row-reverse justify-end gap-4 lg:flex-col'>
                        <div className="flex flex-col">
                            <div className="flex flex-row items-center mb-2 lg:mb-6 gap-2">
                                <div className='text-xl lg:text-2xl font-medium text-primary-blue flex flex-row justify-center items-center bg-primary-green w-10 h-10 rounded-full'>3</div>
                                {/* <div className="flex flex-grow h-0.5 bg-primary-blue rounded-full ml-4"></div> */}
                            </div>
                            <div className='text-2xl font-bold text-primary-blue'>Share with friends and family</div>
                            <div className='text-lg text-primary-blue'>People out there want to help you.</div>
                        </div>
                        <img src={share_img} alt="" className='w-20 mt-2 lg:w-40 lg:mt-8' />
                    </div>
                </div>
            </div>

            {/* <div className='w-full h-0.5 bg-slate-200 my-10'></div> */}
        </div>
    );
}

export default Expect;