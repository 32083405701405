import axios from "axios";
import { Constants } from "../utils";
// import UserService from "./user.service";
import Cookies from 'js-cookie';

// const userService = new UserService();

class CampaignService {

    async createCampaign(formData){
        const token = Cookies.get('user-token');

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'multipart/form-data'}
        };

        return axios.post(`${Constants.baseUrl}/campaign/create`, formData, config);
    }

    async updateCampaignData(id, formData){
        const token = Cookies.get('user-token');

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'multipart/form-data'}
        };

        return axios.patch(`${Constants.baseUrl}/campaign/update/${id}`, formData, config);
    }

    fetchMyCampaigns = async () => {
        const token = Cookies.get('user-token');

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'application/json'}
        };

        return axios.get(`${Constants.baseUrl}/campaign/mine`, config);
    }

    fetchCampaigns = async (pageNumber=1, pageSize=10, categories=[]) => {
        const filterCategories = categories.join(',')
        return axios.get(`${Constants.baseUrl}/campaign/all?pageNumber=${pageNumber}&pageSize=${pageSize}${categories.length>0 ? `&categories=${filterCategories}` : ''}`);
    }

    fetchCampaignById = async (id) => {
        return axios.get(`${Constants.baseUrl}/campaign/byid/${id}`);
    }


    fetchFeaturedCampaigns = async () => {
        return axios.get(`${Constants.baseUrl}/campaign/featured`);
    }
    

    async saveCampaign(id){
        const token = Cookies.get('user-token');
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'application/json'}
        };

        return axios.get(`${Constants.baseUrl}/campaign/save/${id}`, config);
    }

    async removeSavedCampaign(id){
        const token = Cookies.get('user-token');
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'application/json'}
        };
        return axios.get(`${Constants.baseUrl}/campaign/removesaved/${id}`, config);
    }

    async getAllSavedCampaigns(id){
        const token = Cookies.get('user-token');
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'application/json'}
        };

        return axios.get(`${Constants.baseUrl}/campaign/allsaved`, config);
    }
    
    
    getQrCode = async (id) => {
        return axios.get(`${Constants.baseUrl}/campaign/qrcode/${id}`);
    }

}

export default CampaignService;