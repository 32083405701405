import React from 'react';
import { FiPlus, FiArrowRight, FiArrowDown } from "react-icons/fi";

import { Link } from 'react-router-dom';

import Expect from './expect.js';
import FeaturedCampaign from './featured.campaign';
import ReadyToGetStarted from './readyToGetStarted';
import Partners from './partners.js';
import { Footer } from '../shared';

import bg3 from "../../assets/web-bg2.png";
import lomiamh from "../../assets/lomiamh.png";
// import bg3 from "../../assets/web-bg-01.jpeg";

function HomeScreen() {

    return (
        <div className='w-full min-h-screen'>
            
            <div style={{  
            backgroundImage: `url(${bg3})`,
            backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} 
            className='flex flex-col w-full justify-center items-center h-[570px] lg:h-[850px] relative'>

                {/* <div className="flex flex-row gap-2">
                    <div className="text-3xl lg:text-8xl font-bold text-center font-rubik text-primary-green">
                        50
                    </div>
                    <div className="flex flex-col items-start">
                        <div className="text-3xl lg:text-4xl font-medium text-center font-rubik text-primary-green">
                            ሎሚ
                        </div>
                        <div className="text-xl  text-center font-rubik text-white">
                            ለአንድ ሰው ሸክሙ፤
                        </div>
                        <div className="text-xl  text-center font-rubik text-white">
                            ለ50 ሰው ግን ጌጡ ነው።
                        </div>
                    </div>
                </div> */}

                <img src={lomiamh} alt='' className='w-60 md:w-80 rounded-xl object-contain'/>

                <div className="text-3xl lg:text-6xl font-bold text-center font-rubik text-white">
                    Open your <span className='text-primary-green'>heart</span>
                </div>
                <div className="text-3xl lg:text-6xl font-bold text-center font-rubik text-white">
                    to those in need!
                </div>
                <div className="flex flex-row flex-wrap lg:flex-nowrap gap-2 justify-center mt-6">
                    <Link to='/create' className='flex flex-row gap-2 px-6 hover:px-8 hover:gap-4 py-3 bg-primary-green hover:bg-secondary-green rounded-full items-center shadow-lg text-primary-blue duration-200'>
                        <div className='text-lg lg:text-xl font-rubik font-medium'>
                            Create a Campaign
                        </div>
                        <FiPlus className='text-xl'/>
                    </Link>
                    <Link to='/explore' className='flex flex-row gap-2 px-6 hover:px-8 hover:gap-4 py-3 bg-white hover:bg-slate-200 rounded-full items-center shadow-lg text-primary-blue duration-200'>
                        <div className='text-lg lg:text-xl font-rubik font-medium'>
                            Browse Campaigns
                        </div>
                        <FiArrowRight className='text-xl'/>
                    </Link>
                </div>

                <div className='absolute -bottom-5 lg:-bottom-6 left-0 flex flex-row w-full justify-center'>
                    <div className='w-10 h-10 lg:w-14 lg:h-14 bg-primary-green flex flex-col justify-center items-center rounded-full'>
                        <FiArrowDown className='text-lg lg:text-xl'/>
                    </div>
                </div>
            </div>
            <Expect/>
            <FeaturedCampaign/>
            <ReadyToGetStarted/>
            <Partners/>
            <Footer/>
        </div>
    );
}

export default HomeScreen;