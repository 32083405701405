import React, { useEffect } from 'react';

// import { FiCheck } from 'react-icons/fi';

function StartingGoal({goalEtbAmount, setGoalEtbAmount, goalUsdAmount, setGoalUsdAmount, currency, setCurrency}) {

    useEffect(() => {
        switch (currency) {
            case 'USD':
                setGoalUsdAmount('');
                setGoalEtbAmount('0');
                break;
            case 'ETB':
                setGoalEtbAmount('');
                setGoalUsdAmount('0');
                break;
            default:
                setGoalEtbAmount('');
                setGoalUsdAmount('');
                break;
        }
    // eslint-disable-next-line
    }, [currency])

    return (
        <div className='w-full flex flex-col gap-3'>
            <div className='text-xl md:text-2xl font-medium text-primary-blue'>
                How much would you like to raise?
            </div>
            <div className='text-primary-blue text-md line-clamp-3 mb-5'>
                It's completely expected to need funds beyond your initial target. You can always change your target as you go.
            </div>

            <div className="flex flex-col gap-2 mb-4">
                <div className='text-primary-blue text-xl font-medium'>
                    Currency:
                </div>
                <div className="relative flex flex-row gap-2">
                    {['USD', 'ETB', 'ALL'].map((item) => {
                        return (
                            <button key={item} onClick={() => {
                                setCurrency(item);
                            }}
                            className={`px-4 py-2 rounded-md items-center flex flex-row gap-2 ` + (currency===item ? 'bg-primary-blue' : 'bg-slate-100')}>
                                {/* {currency===item && <FiCheck className={`text-base text-primary-green`}/>} */}
                                <div className={`text-base tracking-wider font-rubik line-clamp-1 ` + (currency===item ? 'text-primary-green' : 'text-primary-blue')}>
                                    {item}
                                </div>
                            </button>
                        );
                    })}
                </div>
            </div>


            <div className='text-primary-blue text-md line-clamp-3'>
                Your Stating Goal
            </div>

            {currency!=='USD' && (
                <div className="flex flex-row gap-0">
                    <input type="text" 
                    value={goalEtbAmount}
                    disabled={currency==='USD'}
                    onChange={(val) => setGoalEtbAmount(val.currentTarget.value)} 
                    placeholder="ETB Goal Amount"
                    className="bg-slate-100 rounded-md px-4 py-3 flex-grow rounded-tr-none rounded-br-none text-base"/>
                    <div className={`px-4 md:px-6 py-2  rounded-md items-center rounded-tl-none rounded-bl-none bg-primary-blue text-primary-green text-md md:text-lg font-rubik `}>
                        <p>ETB</p>
                    </div>
                </div>
            )}

            {currency!=='ETB' && (
                <div className="flex flex-row gap-0">
                    <input type="text" 
                    value={goalUsdAmount} 
                    disabled={currency==='ETB'}
                    onChange={(val) => setGoalUsdAmount(val.currentTarget.value)} 
                    placeholder="USD Goal Amount"
                    className="bg-slate-100 rounded-md px-4 py-3 flex-grow rounded-tr-none rounded-br-none text-base"/>
                    <div className='px-4 md:px-6 py-2 bg-primary-blue rounded-md items-center rounded-tl-none rounded-bl-none'>
                        <div className='text-primary-green text-md md:text-lg font-rubik'>
                            USD
                        </div>
                    </div>
                </div>
            )}

            <div className='text-primary-blue text-md line-clamp-3 mt-2'>
                Bear in mind that transaction fees, including credit and debit charges, are deducted from each donation.
            </div>

            <div className='text-primary-blue text-md md:text-md'>
                By continuing, you agree to the Lomi <a href="/legal/donationpolicy" className='text-primary-green underline underline-offset-1' target='_blank'>
                    Fundraising Terms</a>.
            </div>
            
        </div>
    );
}

export default StartingGoal;