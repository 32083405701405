class Utils{
    addCommaToNumber = (number, nodot=false) => {

        const nm = String(number).split('.');
        let afterDot = '00';
        if(nm.length===2){
            afterDot = nm[1].length===1 ? `${nm[1]}0` : nm[1];
        }

        const newNum = String(nm[0]);
        let val = '';
        for(let i=0; i<newNum.length; i++){
            if((newNum.length - (i+1))%3 === 0){
                if(i!==newNum.length-1){
                    val += (newNum[i] + ',');
                }else{
                    val+=newNum[i];
                }
            }else{
                val += newNum[i];
            }
        }
        return nodot ? `${val}` : `${val}.${afterDot}`;
        // return `${val}`;
    }

    calculatePercentageString = (current, total) => {
        const newVal = (current/total) * 100;
        return newVal.toString().split('.')[0];
    }

    calculateRemainingTime = (endDate) => {
        var now = new Date();
        var end = new Date(endDate);

        var difference = Math.abs(end.getTime() - now.getTime());
        const hours = Math.ceil(difference/(1000*60*60));
        const days = Math.floor(hours/24);
        const hrs = hours%24;
        return `${days}d:${hrs}hrs`;
    }

    validateEmail = (email) => {
        return String(email)
        .toLowerCase()
        .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    validateName = (name) => {
        return (!/[^a-zA-Z]/.test(name));
    }

    getDateString = (dateString) => {
        if(dateString){
            var dt = new Date(dateString);
            return dt.toDateString().split(' ').slice(1).join(', ');
        }
        return "";
    }

    getTimeString = (dateString) => {
        if(dateString){
            var dt = new Date(dateString);
            var hours = dt.getHours();
            var minutes = dt.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }
        return "";
    }

    getTimeDifference = (time) => {
        const currentTime = Date.now();
        const endTime = new Date(time);
        const difference =  currentTime - endTime;
        // const formattedDate = new Date(differenceInDays).toLocaleDateString();
        
        let value = '';
        
        const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));

        if(differenceInDays > 0){
            value = differenceInDays + (differenceInDays > 1 ? ' days' : ' day');
        }else{
            const differenceInHours = Math.floor(difference / (1000 * 60 * 60));
            
            if(differenceInHours > 0){
                value = differenceInHours + (differenceInHours > 1 ? ' hrs' : ' hr');
            }else{
                const differenceInMinutes = Math.floor(difference / (1000 * 60));
                value = differenceInMinutes + (differenceInMinutes > 1 ? ' minutes' : ' minute');
            }
        }

        return value;
      }
}

export default Utils;