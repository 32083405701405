import React from 'react';

import { Utils } from '../../utils';

function ConfirmationScreen({campaign}) {
    const utils = new Utils();
    return (
        <div className='w-full flex flex-col gap-3'>
            <div className='text-2xl md:text-3xl font-medium text-primary-green'>
                Almost Done!
            </div>
            <div className='text-primary-blue text-md line-clamp-3 mb-3'>
                This is what your campaign will look like when searched.
            </div>

            <div  className='w-full p-2 bg-white flex flex-col gap-2 mb-0 shadow-lg rounded-xl duration-200  hover:z-20'>
                <div className="flex flex-col gap-2">
                    <img alt="not fount" src={URL.createObjectURL(campaign.photo)} className="w-full h-56 md:h-64 rounded-md object-cover" />
                    <div className="flex flex-col md:px-2 gap-1">
                        <div className='text-xl md:text-2xl font-bold text-primary-blue line-clamp-1 md:line-clamp-2'>{campaign.title}</div>
                        <div className='text-primary-blue text-sm md:text-lg line-clamp-2'>
                            {campaign.description}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col md:px-2 gap-1">
                    <div className='text-primary-blue text-lg md:text-xl'>
                        <span className='font-bold mr-1.5 text-primary-green'>{utils.addCommaToNumber(campaign.goalEtbAmount)} ETB</span>
                        is your goal.
                    </div>
                    <div className='text-primary-blue text-lg md:text-xl'>
                        <span className='font-bold mr-1.5 text-primary-green'>{utils.addCommaToNumber(campaign.goalUsdAmount)} USD</span>
                        is your goal.
                    </div>
                </div>
                <div className="flex flex-row md:px-2 md:mb-2">
                    <div className="px-4 py-1 bg-slate-100 rounded-full">
                        {campaign.category}
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default ConfirmationScreen;