import React from 'react';
import { FiFacebook, FiYoutube, FiTwitter, FiInstagram } from "react-icons/fi";
import { Link } from "react-router-dom";

import lomi_logo from "../../assets/lomi-logo.png";
import android from "../../assets/download/download-googleplay.png";
import ios from "../../assets/download/download-appstore.png";

function Footer() {
    return (
        <div className='flex flex-col w-full px-0 lg:px-20 pt-8 font-rubik gap-3'>
            
            
            <div className='flex flex-row gap-5 mt-3 justify-evenly mb-16 px-4 lg:px-0'>
                <div className='flex flex-col gap-2'>
                    <img src={lomi_logo} alt="" className='w-16 lg:w-20 mb-1 lg:mb-4' />
                    <div className='text-lg lg:text-xl font-medium text-primary-green'>Lomi Funds</div>
                    <div className='text-primary-blue text-sm lg:text-md'>Let's help our community!</div>
                    <div className="flex flex-col lg:flex-row gap-2 lg:gap-6">
                        <a href="https://play.google.com/store/apps/details?id=com.piassa" 
                        className='hover:scale-105 duration-200 hover:cursor-pointer'>
                            <img src={android} alt="" className='h-8 lg:h-12' />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.piassa" 
                        className='hover:scale-105 duration-200 hover:cursor-pointer'>
                            <img src={ios} alt="" className='h-8 lg:h-12' />
                        </a>
                    </div>
                </div>
                <div className='flex flex-col gap-2 text-base'>
                    <div className='text-xl font-medium text-primary-blue mb-2'>Address</div>
                    <div className='text-primary-blue lg:text-md'>
                        Abera Gizwa St. Addis Ababa, Ethiopia
                    </div>
                    <div className='text-primary-blue lg:text-md'>
                        Phone:  
                        <span className='ml-2 font-medium'>+2519 42859723</span>
                    </div>
                    <div className='text-primary-blue lg:text-md'>
                        Email:  
                        <span className='ml-2 font-medium'>info@lomi.et</span>
                    </div>
                </div>
                <div className='hidden lg:flex flex-col gap-2'>
                    <div className='text-xl font-medium text-primary-blue mb-2'>Campaign Types</div>
                    <Link to={'/explore?category=Medical'} className='text-primary-blue text-md hover:text-primary-green'>Medical</Link>
                    <Link to={'/explore?category=Education'} className='text-primary-blue text-md hover:text-primary-green'>Education</Link>
                    <Link to={'/explore?category=Children'} className='text-primary-blue text-md hover:text-primary-green'>Children</Link>
                    <Link to={'/explore?category=Faith'} className='text-primary-blue text-md hover:text-primary-green'>Faith</Link>
                </div>
                <div className='hidden lg:flex flex-col gap-2'>
                    <div className='text-xl font-medium text-primary-blue mb-2'>Learn More</div>
                    <Link to={'/howitworks'} className='text-primary-blue text-md hover:text-primary-green'>How lomi works?</Link>
                    <Link to={'/help'} className='text-primary-blue text-md hover:text-primary-green'>Help center</Link>
                    <Link to={'/faq'} className='text-primary-blue text-md hover:text-primary-green'>FAQ</Link >
                    <Link to={'/about'} className='text-primary-blue text-md hover:text-primary-green'>About</Link>
                </div>
            </div>
{/* 
            <div className="flex flex-col lg:flex-row flex-wrap py-4 lg:py-8 px-4 lg:px-20 gap-4 lg:gap-6 justify-between lg:items-center">
                <div className='text-primary-blue text-base lg:text-lg'>
                    You can download our mobile apps with these links
                </div>

                
                
            </div> */}

            <div className="flex flex-row flex-wrap py-8 px-20 bg-slate-100 gap-6 justify-between">
                <div className='text-primary-blue text-md'>© 2023 Lomi Funds</div>

                <div className="flex flex-row gap-6">
                    <Link to="/legal/termsofservice" className='text-primary-blue text-md'> Terms of Service </Link>
                    <Link to="/legal/privacypolicy" className='text-primary-blue text-md'> Privacy </Link>
                    <Link to="/legal" className='text-primary-blue text-md'> Legal </Link>
                </div>


                <div className="flex flex-row gap-6">
                    <a href="/" className='text-primary-blue hover:text-primary-green text-md duration-200'>
                        <FiFacebook className='text-xl'/>
                    </a>
                    <a href="/" className='text-primary-blue hover:text-primary-green text-md duration-200'>
                        <FiYoutube className='text-xl'/>
                    </a>
                    <a href="/" className='text-primary-blue hover:text-primary-green text-md duration-200'>
                        <FiTwitter className='text-xl'/>
                    </a>
                    <a href="/" className='text-primary-blue hover:text-primary-green text-md duration-200'>
                        <FiInstagram className='text-xl'/>
                    </a>
                </div>

                
            </div>
        </div>
    );
}

export default Footer;