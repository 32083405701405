import React from 'react';
import { FiCheck } from "react-icons/fi";


function DonationInfo({message, annonymous, setMessage, setAnnonymous}) {
    return (
        <div className='w-full flex flex-col gap-2 py-10'>
            <div className='text-xl md:text-2xl font-medium text-primary-blue'>
                Do you have any message for the fundraiser?
            </div>
            <div className='text-primary-blue text-md line-clamp-3 mb-3'>
                You can attach any message that will be shown with the donation.
            </div>

            <textarea cols="30" rows="4"
            value={message} 
            onChange={(val) => setMessage(val.currentTarget.value)}  
            placeholder='Message' 
            className="bg-slate-100 rounded-md px-4 py-3 flex-grow rounded-tr-none rounded-br-none"/>

            <div className="flex flex-row gap-2 mt-5 items-center justify-between">
                <div className='text-xl md:text-2xl font-medium text-primary-blue'>
                    Privacy
                </div>
                <div className='text-md line-clamp-3'>
                    <a href="/legal/termsofservice" target="_blank" className='text-primary-green'>Learn more!</a>
                </div>
            </div>

            <div className="gap-2 flex flex-row items-center mt-4 text-primary-blue">
                <button 
                onClick={() => setAnnonymous(!annonymous)}
                className={`w-5 h-5 rounded-md flex flex-row justify-center items-center ` + (annonymous ? 'bg-primary-green' : 'bg-slate-100 border-primary-blue border-2')}>
                    {annonymous && <FiCheck className='text-white text-md'/>}
                </button>
                <div className='font-normal text-base'>
                    Do not show my personal information.
                </div>
            </div>
            
        </div>
    );
}

export default DonationInfo;