import React, {useState, useEffect} from 'react';

import { CharityService } from '../../services';
import { Utils, Constants } from '../../utils';

function CharityInfo({charityId}) {

    const charityService = new CharityService();
    const utils = new Utils();

    const [loading, setLoading] = useState(false);

    const [charityData, setCharityData] = useState(null);

    
    useEffect(() => {
        const getCharityData = () => {
            setLoading(true);
            charityService.fetchCharityById(charityId)
            .then((res) => {
                // console.log(res.data);
                setCharityData(res.data.data);
            }).catch((err) => {
                console.log(err);
            }).finally(() => setLoading(false));
        }
        getCharityData();
        // eslint-disable-next-line
    }, []);

    if(!charityData || loading){
        return (
            <div className='flex flex-row bg-white border-[1px] rounded-xl w-full px-4 py-3'>
                <div className='w-[60px] h-[60px] bg-slate-100 rounded-full mr-2'></div>
                <div className="flex flex-col flex-grow gap-1">
                    <div className='w-1/3 h-4 bg-slate-100 rounded-full my-1'></div>
                    <div className='w-[100px] h-2 bg-slate-100 rounded-full my-0.5'></div>
                    <div className='w-full h-3 bg-slate-100 rounded-full my-1'></div>
                </div>
            </div>
        );
    }

    return (
        <div className='flex flex-row bg-white border-[1px] rounded-xl w-full px-3 py-2 items-center'>
            <img src={`${Constants.baseUrl}/uploaded-file/${charityData.logo}`} alt="Carity logo" className='w-[40px] h-[40px] rounded-full mr-2 shrink-0' />
            <div className="flex flex-col flex-grow">
                <div className='text-primary-blue font-medium'>
                    {charityData.name}
                </div>
                <div className='text-primary-blue text-sm line-clamp-2'>
                    {charityData.description}
                </div>
            </div>
            <div className='flex flex-col items-end shrink-0'>
                <p className='text-primary-blue text-sm'>Created On: </p>
                <p className='text-primary-blue text-base font-medium'>{utils.getDateString(charityData.dateCreated)}</p>
            </div>
        </div>
    );
}

export default CharityInfo;