import React from 'react';

import page_notfound from "../../assets/illustrations/page_notfound.png";

function PageNotFoundScreen() {
    return (
        <div className='min-h-screen w-full flex flex-col justify-center items-center font-rubik'>

            <img src={page_notfound} alt="Check Email" className='w-[90%] md:w-[400px]  my-4' />

            <span className='text-xl md:text-2xl font-normal ml-2 text-primary-blue'>
                Page Not Found!
            </span>

            <div className="flex flex-row mt-10">
                <a href='/' className='flex flex-row gap-2 px-6 py-2 border-primary-blue border-2 rounded-lg items-center duration-200 w-full justify-center mb-4'>
                    <div className='text-primary-blue text-base md:text-lg font-rubik'>
                        Go to Home
                    </div>
                </a>
            </div>
        </div>
    );
}

export default PageNotFoundScreen;