import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import { NotificationService } from '../../services';

function NotificationsScreen() {
    const notificationService = new NotificationService();

    const [loading, setLoading] = useState(false);
    const [myNotifications, setMyNotifications] = useState([]);
    const [notificationsData, setNotificationsData] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    useEffect(() => {
        setLoading(true);
        notificationService.fetchMyNotifications(currentPage, pageSize)
        .then((result) => {
            console.log(result.data);
            setMyNotifications(result.data.data.notifications);
            setNotificationsData({...result.data.data, notifications:[]});
        })
        .catch((err) => console.log(err))
        .finally(()=>setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentPage, pageSize]);

    return (
        <div className='min-h-screen w-full flex flex-col items-center font-rubik gap-2 pt-20 md:pt-36'>
            <div className='text-primary-blue text-xl lg:text-3xl font-medium'>
                Notifications
            </div>

            {loading && <div>Loading..</div>}

            <div className='w-full md:w-4/5 lg:w-2/3 flex flex-col'>
                {notificationsData && (
                    <div className='flex flex-row justify-between w-full'>
                        <p className=''>Total Notifications - <span className='font-medium'>{notificationsData.total}</span></p>
                        <div className='flex flex-row gap-3'>
                            <button disabled={currentPage<=1} className={`${currentPage<=1 ? 'text-gray-400' : 'text-primary-blue'}`} 
                            onClick={() => {
                                if(currentPage>1){
                                    setCurrentPage((last) => last-1);
                                }
                            }}><p className='hover:text-primary-green'>Prev</p></button>

                            <p>{currentPage} of {notificationsData.lastPage}</p>

                            <button disabled={currentPage===notificationsData.lastPage} className={`${currentPage===notificationsData.lastPage ? 'text-gray-400' : 'text-primary-blue'}`} 
                            onClick={() => {
                                if(currentPage<notificationsData.lastPage){
                                    setCurrentPage((last) => last+1);
                                }
                            }}><p className='hover:text-primary-green'>Next</p></button>
                        </div>
                    </div>
                )}

                {myNotifications.length>0 && (
                    <div className="w-full flex flex-col justify-center items-center mt-2 gap-2">
                        {myNotifications.map((notification) => {
                            return (
                                <div key={notification.id} className="bg-white border-[1px] border-slate-200 w-full py-1 px-2 flex flex-row rounded-lg items-center">
                                    <div className="flex flex-col flex-grow">
                                        <p className="text-primary-blue text-base md:text-base font-medium">
                                            {notification.title}
                                        </p>
                                        <p className="text-primary-blue text-sm md:text-base">
                                            {notification.description}
                                        </p>
                                    </div>
                                    {notification.type==="campaign" && notification.data && (
                                        <Link to={`/campaign/${notification.data}`} className='px-2 py-1.5 bg-primary-blue rounded-lg duration-300 group'>
                                            <p className='text-white duration-300 group-hover:text-primary-green text-sm'>View Campaign</p>
                                        </Link>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}

            </div>
        </div>
    );
}

export default NotificationsScreen;