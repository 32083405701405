import React, {useContext, useState} from 'react';
import { FiPlus, FiSearch, FiMenu, FiX, FiLogIn, FiBookmark, FiBell, FiUser, FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Link } from 'react-router-dom';
// import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';


import lomi_logo from "../../assets/lomi-logo.png";
import { UserContext } from '../../contexts';
import ResponsiveAppBarContent from './responsive.appbar.content';
import ProfileMenu from './profile.menu';

// import OutsideClickHandler from './outsideClickHandler';

function AppBar({scrolled}) {
    const {loggedIn, user, setUser, setLoggedIn} = useContext(UserContext);
    const [openMenu, setOpenMenu] = useState(false);
    const [openProfileMenu, setOpenProfileMenu] = useState(false);

    const location = useLocation();

    // console.log(user);

    return (
        <div className={`flex flex-row left-0 z-40 w-full fixed justify-center duration-200 ` + (scrolled ? 'top-0 shadow-lg' : 'lg:top-6')}>
            <div className={`flex flex-row font-rubik bg-white duration-200 justify-center px-4 flex-grow ` 
            + (scrolled ? 'rounded-none mx-0 py-5 md:py-6' : 'lg:rounded-full lg:mx-20 py-5 md:py-4')}>

                <div className={'flex flex-row justify-between  gap-2 items-center duration-200 ' + (scrolled ? 'w-full lg:mx-20' : 'w-full mx-0')}>
                    <div className="hidden xl:flex xl:flex-grow flex-row justify-start gap-4 items-center">
                        <Link to='/' className='flex flex-row justify-center hover:scale-105 duration-200 w-16 lg:w-16 ml-3 mr-2 '>
                            <img src={lomi_logo} alt="Lomi logo"  className='w-full '/>
                        </Link>
                        
                        <Link to='/explore' 
                        className={`flex flex-row gap-2 hover:scale-105 px-6 py-2 bg-slate-100 hover:bg-slate-200 rounded-full items-center duration-200 ${location.pathname==='/explore' ? 'border-[1px] border-primary-green' : ''}`}>
                            <FiSearch className='text-slate-800 text-lg'/>
                            <div className='text-slate-800 text-base font-rubik flex 2xl:hidden'>
                                Explore
                            </div>
                            <div className='text-slate-800 text-base font-rubik hidden 2xl:flex'>
                                Explore Campaigns
                            </div>
                        </Link>
                        <Link to='/howitworks' 
                        className={`flex flex-row gap-2 px-2 py-2 items-center duration-200 group hover:text-primary-green ${location.pathname==='/howitworks' ? 'bg-primary-green bg-opacity-10 border-b-2 border-primary-green' : ''}`}>
                            
                            <p className='text-primary-blue group-hover:text-primary-green duration-200'>How it works</p>
                        </Link>

                        {loggedIn && (
                            <Link to='/saved' className={`flex flex-row gap-2 px-2 py-2 items-center duration-200 group ${location.pathname==='/saved' ? 'bg-primary-green bg-opacity-10 border-b-2 border-primary-green' : ''}`}>
                                <FiBookmark className='text-slate-800 text-base group-hover:text-primary-green duration-300 group-hover:scale-105'/>
                                <p className='text-primary-blue group-hover:text-primary-green duration-200'>Saved</p>
                                {/* <div className="flex-col flex opacity-0 group-hover:opacity-100 group-hover:top-20 duration-200 absolute top-16 gap-0">
                                    <div className='text-slate-800 text-sm font-rubik py-2 px-4 bg-white rounded-md'>
                                        Saved Campaigns
                                    </div>
                                </div> */}
                            </Link>
                        )}

                        {loggedIn && (
                            <Link to='/notifications' className={`flex flex-row gap-2 px-2 py-2 items-center duration-200 group ${location.pathname==='/notifications' ? 'bg-primary-green bg-opacity-10 border-b-2 border-primary-green' : ''}`}>
                                <FiBell className='text-slate-800 text-lg group-hover:text-primary-green duration-200 group-hover:scale-105'/>
                                <p className='text-primary-blue group-hover:text-primary-green duration-200'>Notifications</p>
                                {/* <div className="flex-col flex opacity-0 group-hover:opacity-100 group-hover:top-20 duration-200 absolute top-16 gap-0">
                                    <div className='text-slate-800 text-sm font-rubik py-2 px-4 bg-white rounded-md'>
                                        Notifications
                                    </div>
                                </div> */}
                            </Link>
                        )}
                    </div>

                    <Link to='/' className='flex flex-row xl:hidden justify-center hover:scale-105 duration-200 w-16 lg:w-16 ml-4'>
                        <img src={lomi_logo} alt="Lomi logo"  className='w-full'/>
                    </Link>

                    <div onClick={() => {
                        // console.log("hereee to!");
                        setOpenMenu(!openMenu);
                    }} className='flex xl:hidden hover:scale-105 hover:cursor-pointer duration-200'>
                        {!openMenu ? <FiMenu className='text-3xl text-primary-blue'/> : <FiX className='text-3xl text-primary-blue'/>}
                    </div>

                    <div className="hidden xl:flex flex-row justify-end gap-6 items-center">
                        <Link to='/create' className='flex flex-row gap-2 px-4 py-2 bg-primary-blue hover:scale-105 rounded-full items-center duration-200'>
                            {/* <img src={lomiIcon} alt="" className='w-8'/> */}
                            <FiPlus className='text-primary-green text-lg'/>
                            <div className='text-primary-green text-base font-rubik flex 2xl:hidden'>
                                Create
                            </div>
                            <div className='text-primary-green text-base font-rubik hidden 2xl:flex'>
                                Create a Campaign
                            </div>
                        </Link>

                        {loggedIn && (
                            <div onClick={() => {
                                setOpenProfileMenu(!openProfileMenu)
                            }} 
                            className='flex flex-row gap-2 hover:gap-6 px-2 items-center duration-200 group cursor-pointer'>
                                <div className="flex flex-row gap-2 group-hover:scale-105 duration-200 rounded-full px-3 py-1 items-center">
                                    <FiUser className='text-primary-blue text-md'/>
                                    <div className='text-primary-blue text-base font-rubik'>
                                        {user?.firstname??''} {user?.lastname??''}
                                    </div>
                                    {openProfileMenu ? <FiChevronUp className='text-primary-blue text-md'/> : <FiChevronDown className='text-primary-blue text-md'/>}
                                </div>
                            </div>
                        )}

                        {!loggedIn && (
                            <a href='/login' className='flex flex-row gap-2 px-2 py-2 items-center duration-200 group'>
                                <FiLogIn className='text-slate-800 text-lg group-hover:text-primary-green duration-200'/>
                                <div className='text-slate-800 text-md font-rubik bg-white rounded-md group-hover:text-primary-green duration-200'>
                                    Sign In
                                </div>
                            </a>
                        )}
                    </div>


                    
                    <ProfileMenu openMenu={openProfileMenu} setOpenMenu={setOpenProfileMenu} loggedIn={loggedIn} setUser={setUser} setLoggedIn={setLoggedIn}/>
                    <ResponsiveAppBarContent openMenu={openMenu} setOpenMenu={setOpenMenu} loggedIn={loggedIn} setUser={setUser} setLoggedIn={setLoggedIn}/>
                    
                </div>
            </div>
        </div>
    );
}

export default AppBar;