import React from 'react';
import { FiXCircle } from "react-icons/fi";
import { useParams } from 'react-router-dom';

function DonationCanceled() {
    const {id} = useParams();
    return (
        <div className='min-h-screen w-full flex flex-col justify-center items-center font-rubik'>
            <FiXCircle className='text-red-400 text-7xl mb-6'/>
            <div className='text-red-400 text-3xl'>
                Donation Failed 
            </div>
            <span className='text-lg ml-2 text-primary-blue mb-2'>
                Your donation failed, please try again.
            </span>
            <div className="flex flex-row mt-4">
                <a href={`/campaign/${id}`} className='flex flex-row gap-2 px-6 py-2 bg-primary-blue rounded-lg items-center duration-200 w-full justify-center mb-4'>
                    <div className='text-primary-green text-lg font-rubik font-medium'>
                        Try Again
                    </div>
                </a>
            </div>
        </div>
    );
}

export default DonationCanceled;