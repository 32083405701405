import React, {useState} from 'react';
import { FiLogIn, FiCheck, FiEyeOff, FiEye, FiAlertCircle } from "react-icons/fi";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { UserService } from '../../services';
import { Utils } from '../../utils';

function SignupScreen() {
    const userService = new UserService();
    const utils = new Utils();
    const navigate = useNavigate();

    const [fname, setFName] = useState('');
    const [lname, setLName] = useState('');
    const [email, setEmail] = useState('');

    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    
    const [confPassword, setConfPassword] = useState('');
    const [showConfPassword, setShowConfPassword] = useState(false);
    const [passwordConfirmed, setPasswordConfirmed] = useState(false);

    const [isTermChecked, setIsTermChecked] = useState(false);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [captchaVerified, setCaptchaVerified] = useState(false);

    const signup = () => {
        setErrorMessage('');
        
        if(!utils.validateName(fname)){
            setErrorMessage('Invalid First Name');
            return;
        }

        if(!utils.validateName(lname)){
            setErrorMessage('Invalid Last Name');
            return;
        }

        if(!utils.validateEmail(email)){
            setErrorMessage('Invalid Email');
            return;
        }

        if(!passwordConfirmed){
            setErrorMessage('Password Doesnot match!');
            return;
        }

        const data = {
            firstname: fname,
            lastname: lname,
            email: email.toLocaleLowerCase().replace(/ /g,''),
            password: password
        };

        setLoading(true);
        userService.signUp(data)
        .then((result) => {
            toast.success("Signed up Successfully!");
            const navigationData = {emailAddress: email.toLocaleLowerCase().replace(/ /g,'')}
            navigate("/verifyaccount", {state: navigationData});
        }).catch((err) => {
            toast.error("Sign up Failed!");
            setErrorMessage('Email already in use.');
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    const canSignUp = () => {
        

        let errorMessage = '';
        if(fname==='' || lname===''){
            errorMessage = 'Please fill out your name';
        }
        
        if(email==='' || password===''){
            errorMessage = 'Please fill out your login info.';
        }

        // if(password===confPassword){
        //     errorMessage = 'Password doesnot match.';
        // }

        if(!isTermChecked){
            errorMessage = 'Please read and agree to our terms';
        }

        if(!captchaVerified){
            errorMessage = 'please verify captcha first';
        }

        if(errorMessage!==''){
            toast.error(errorMessage);
            return false;
        }else{
            return true;
        }
    }

    function onChange(value) {
        if(value){
            setCaptchaVerified(true);
        }
        console.log("Captcha value:", value);
    }
    

    // const [loading, setLoading] = useState(false);

    return (
        <div className='min-h-screen w-full px-3 flex flex-col justify-center items-center font-rubik gap-3'>
            <div className='text-primary-blue text-lg'>Welcome to Lomi!</div>
            <div className='text-4xl font-medium text-primary-blue mb-8'>Sign Up</div>

            {loading && <div>Signing up . . .</div>}
            {errorMessage!=='' && <div className='text-sm text-red-400'>{errorMessage}</div> }

            <div className='flex flex-col w-full lg:w-96 gap-4'>
                <div className="flex flex-col">
                    {/* {errorMessage.fname!=='' && <div className='text-sm text-red-400'>{errorMessage.fname}</div> } */}
                    <input type="text" value={fname} onChange={(val) => setFName(val.currentTarget.value)} placeholder="First Name"
                    className="bg-slate-100 rounded-md px-4 py-3"/>
                </div>

                <div className="flex flex-col">
                    {/* <div className='text-sm text-red-400'>{errorMessage.lname}</div> */}
                    <input type="text" value={lname} onChange={(val) => setLName(val.currentTarget.value)} placeholder="Last Name"
                    className="bg-slate-100 rounded-md px-4 py-3"/>
                </div>

                <div className="flex flex-col">
                    {/* <div className='text-sm text-red-400'>{errorMessage.email}</div> */}
                    <input type="text" value={email} onChange={(val) => setEmail(val.currentTarget.value)} placeholder="Email"
                    className="bg-slate-100 rounded-md px-4 py-3"/>
                </div>


                <div className="flex flex-row  bg-slate-100 rounded-md overflow-hidden">
                    <input type={showPassword ? 'text' : 'password'} value={password} onChange={(val) => setPassword(val.currentTarget.value)} placeholder="Password"
                    className="flex-grow bg-slate-100 px-4 py-3 rounded-md" />
                    <button className='px-4' onClick={() => setShowPassword(!showPassword)}>
                        {showPassword 
                        ? <FiEyeOff className='text-primary-green text-xl'/> 
                        : <FiEye className='text-primary-green text-xl'/>}
                    </button>
                </div>

                <div className="flex flex-row bg-slate-100 rounded-md overflow-hidden">
                    <input type={showConfPassword ? 'text' : 'password'} value={confPassword} 
                    onChange={(val) => {
                        setConfPassword(val.currentTarget.value);
                        if(val.currentTarget.value===password){
                            setPasswordConfirmed(true);
                        }else{
                            setPasswordConfirmed(false);
                        }
                    }} placeholder="Confirm Password"
                    className="flex-grow bg-slate-100 px-4 py-3 rounded-md" />
                    <button className='px-4' onClick={() => setShowConfPassword(!showConfPassword)}>
                        {showConfPassword 
                        ? <FiEyeOff className='text-primary-green text-xl'/> 
                        : <FiEye className='text-primary-green text-xl'/>}
                    </button>
                </div>

                {confPassword!=='' && (
                    <div>
                        {passwordConfirmed ? (
                            <div className='flex flex-row items-center gap-2'>
                                <FiCheck className='text-primary-green text-md'/>
                                <div className='text-primary-green text-sm'>Password Matches</div>
                            </div>
                        ) : (
                            <div className='flex flex-row items-center gap-2 text-red-400'>
                                <FiAlertCircle className='text-md'/>
                                <div className='text-sm'>Password Doesn't Match.</div>
                            </div>
                        )}

                    </div>
                )}

                <ReCAPTCHA
                    sitekey="6Lfvr-MkAAAAAAHDAW9VTxXh5gT2hNgl6rMSfIoz"
                    onChange={onChange}
                />
                
                <div className="gap-2 flex flex-row items-center">
                    <button 
                    onClick={() => setIsTermChecked(!isTermChecked)}
                    className={`w-5 h-5 rounded-sm flex flex-row justify-center items-center ` + (isTermChecked ? 'bg-primary-green' : 'bg-slate-100 border-primary-blue border-2')}>
                        {isTermChecked && <FiCheck className='text-white text-md'/>}
                    </button>
                    <div className='font-normal text-sm flex-1'>
                        I have read and agreed to Lomi's <a href="/legal/termsofservice" className='text-primary-green' target='_blank'>Terms of Service</a> & <a href="/legal/privacypolicy" className='text-primary-green' target='_blank'>Privacy Policy</a>
                    </div>
                </div>
                <div className="flex flex-row justify-end">
                    <button 
                    onClick={() => {
                        if(canSignUp()){
                            signup();
                        }
                    }}
                    className='flex flex-row gap-2 px-6 py-2 bg-primary-blue rounded-md items-center duration-200 w-full justify-center'>
                        {/* <img src={lomiIcon} alt="" className='w-8'/> */}
                        <div className='text-primary-green text-lg font-rubik'>
                            Sign Up
                        </div>
                        <FiLogIn className='text-primary-green text-xl'/>
                    </button>
                </div>
                
            </div>

            <div className="flex flex-row gap-2">
                <div className='text-primary-blue text-lg'>Already have account?</div>
                <a href='/login' className='text-primary-green text-lg'>Sign in</a>
            </div>


        </div>
    );
}

export default SignupScreen;