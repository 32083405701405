import React from 'react';
// import { FiCheck } from "react-icons/fi";
import { useLocation } from 'react-router-dom';

import check_email from "../../assets/illustrations/check_email.png";

function SignupReponse() {
    const location = useLocation();
    const {emailAddress} = location.state;

    return (
        <div className='w-full flex flex-col font-rubik gap-2 pt-20 md:pt-36 items-center'>
            <div className='flex flex-col w-full xl:w-4/5 px-4 md:px-10 xl:px-20 2xl:px-5 gap-4 items-center'>
                {/* <div className="flex flex-row gap-4 items-center mb-2">
                    <FiCheck className='text-primary-green text-lg md:text-xl '/>
                    <div className='text-primary-green text-lg md:text-xl'> Account Created! </div>
                </div> */}

                <div className='text-primary-blue text-2xl md:text-4xl font-medium mb-2'> Verify your email </div>

                <div className='text-primary-blue text-base md:text-lg mb-4 text-center'> You will need to verify your email to complete registration</div>

                <img src={check_email} alt="Check Email" className='w-2/3 md:w-[300px] lg:w-[350px] xl:w-[400px]' />

                <div className='text-primary-blue text-base md:text-lg mt-2 text-center w-full lg:w-2/3'>
                    An email has been sent to <span className='text-primary-green'>{emailAddress||'your email address'}</span> with a link to verify your account. 
                    If you have not received the email after few minutes, please check your spam folder.
                </div>

                <div className="flex flex-row gap-3 md:gap-4 justify-center mt-2">
                    <button
                    className='flex flex-row gap-2 px-3 md:px-6 py-2 bg-primary-blue rounded-md items-center justify-center'>
                        <div className='text-primary-green text-base font-rubik'>
                            Resend Email
                        </div>
                    </button>

                    <button
                    className='flex flex-row gap-2 px-3 md:px-6 py-2 bg-white border-primary-blue border-2 rounded-md items-center justify-center'>
                        <div className='text-primary-blue text-base font-rubik'>
                            Contact Support
                        </div>
                    </button>
                </div>

            </div>
        </div>
    )
}

export default SignupReponse;