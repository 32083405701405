import axios from "axios";
// import UserService from "./user.service";
import Cookies from 'js-cookie';
import { Constants } from "../utils";

// const userService = new UserService();

class DonationService {

    async donateWithStripe(formData){
        const token = Cookies.get('user-token');

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'application/json'}
        };

        return axios.post(`${Constants.baseUrl}/pay/paywithstripe`, formData, config);
    }

    async donateWithChapa(formData){
        const token = Cookies.get('user-token');

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'application/json'}
        };

        return axios.post(`${Constants.baseUrl}/pay/paywithchapa`, formData, config);
    }

    fetchCampaignDonations = async (id, pageNumber=1, pageSize=10) => {
        return axios.get(`${Constants.baseUrl}/donation/campaign/${id}?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }

}

export default DonationService;