import React, { useEffect, useState } from 'react';
import { FiX } from "react-icons/fi";

import { CampaignService } from '../../services';

function UpdateCampaignDetails({campaignData, onClose, fetchCampaignData}) {
    const campaignService = new CampaignService();

    const [title, setTitle] = useState(campaignData.title);
    const [description, setDescription] = useState(campaignData.description);

    const [validToContinue, setValidToContinue] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleUpdateCampaign = () => {
        setLoading(true);
        const data = {
            title,
            description
        }
        campaignService.updateCampaignData(campaignData.id || campaignData._id, data).then((res) => {
            // console.log(res.data);
            fetchCampaignData();
            onClose();
        }).catch((err) => {
            console.log(err);
        }).finally(() => setLoading(false));
    }

    const validateForm = () => {
        if(title.length===0){
            setValidToContinue(false);
            return;
        }

        if(description.length===0){
            setValidToContinue(false);
            return;
        }

        setValidToContinue(true);
        return;
    }

    useEffect(() => {
        validateForm();
        // eslint-disable-next-line
    }, [title, description])

    const onCancelClicked = () => {
        setTitle(campaignData.title);
        setDescription(campaignData.description);
    }

    return (
        <div className='flex flex-col w-full h-full bg-black inset-0 absolute top-0 left-0 bg-opacity-20 backdrop-blur-sm justify-center items-center p-3'>

            <div className="flex flex-col bg-white rounded-lg p-3 w-full md:w-[600px]">
                <div className="flex flex-row w-full justify-between items-center">
                    <p className='text-lg font-medium'>
                        Update Campaign
                    </p>
                    <button onClick={onClose}>
                        <FiX className='text-xl text-red-500 hover:scale-110 cursor-pointer'/>
                    </button>
                </div>

                {loading && <p>Loading...</p>}

                <div className="flex flex-col gap-3 mt-3">
                    <div className="flex flex-col">
                        <p className='text-gray-600'>Title:</p>
                        <input type="text" value={title} onChange={(val) => setTitle(val.currentTarget.value)} placeholder="Campaign Title"
                        className="bg-slate-100 rounded-md px-4 py-3 w-full"/>
                    </div>

                    <div className="flex flex-col">
                        <p className='text-gray-600'>Description:</p>
                        {/* <input type="text" value={description} onChange={(val) => setDescription(val.currentTarget.value)} placeholder="Campaign Description"
                        className="bg-slate-100 rounded-md px-4 py-3 w-full"/> */}
                        <textarea cols="30" rows="8"
                        value={description} 
                        onChange={(val) => setDescription(val.currentTarget.value)}  
                        placeholder='Campaign Description' 
                        className="bg-slate-100 rounded-md px-4 py-3 flex-grow rounded-tr-none rounded-br-none"/>
                    </div>
                </div>

                <div className="flex flex-row gap-2 w-full mt-3">
                    <button onClick={onCancelClicked} className='px-2 py-2 rounded-lg bg-gray-200 text-primary-blue flex-grow'>
                        <p>Cancel</p>
                    </button>
                    <button disabled={!validToContinue} 
                    onClick={handleUpdateCampaign}
                    className='px-2 py-2 rounded-lg bg-primary-blue text-primary-green flex-grow'>
                        <p>Update</p>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default UpdateCampaignDetails;