import React from 'react';

// const pay = [
//     {id: 0, name: 'Chapa', img: 'https://banksethiopia.com/wp-content/uploads/2022/10/download.png'},
//     {id: 1, name: 'Telebirr', img: 'https://telegebeya.ethiotelecom.et/images/TeleBirr-Logo.svg'},
//     {id: 2, name: 'Card', img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png'},
// ];

function Partners() {
    return (
        <div className='flex flex-col items-center w-full px-2 lg:px-20 py-4 lg:py-8 font-rubik gap-3'>
            <div className='flex flex-col items-center rounded-xl justify-center lg:items-center px-4 lg:p-0'>
                <div className='text-2xl lg:text-4xl font-medium text-primary-blue text-center'>Our Partners!</div>
                <div className='text-base lg:text-xl text-primary-blue text-center'>Join us as, and let's help our community together!</div>
                <div className="flex flex-row flex-wrap gap-1 lg:gap-6 mt-2 lg:mt-4 items-center">
                    <a href="https://chapa.co/" target="_blank" rel="noreferrer">
                        <img src="https://i.ibb.co/59zfQ7N/chapa-logo.png" alt="pr1" 
                        className="object-contain filter md:grayscale hover:scale-110 hover:filter-none duration-200 w-24 lg:w-40" />
                    </a>

                    <a href="https://ethiotelebirr.com/" target="_blank" rel="noreferrer">
                        <img src="https://telegebeya.ethiotelecom.et/images/TeleBirr-Logo.svg" alt="pr1" 
                        className="object-contain filter md:grayscale hover:scale-110 hover:filter-none duration-200 w-24 lg:w-40" />
                    </a>

                    <a href="https://ethiotelebirr.com/" target="_blank" rel="noreferrer">
                        <img src="https://cdn.contactcenterworld.com/images/company/ethio-telecom-1200px-logo.png" alt="pr1" 
                        className="object-contain filter md:grayscale hover:scale-110 hover:filter-none duration-200 w-24 lg:w-40" />
                    </a>

                    <a href="https://ethiotelebirr.com/" target="_blank" rel="noreferrer">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png" alt="pr1" 
                        className="object-contain filter md:grayscale hover:scale-110 hover:filter-none duration-200 w-24 lg:w-40" />
                    </a>

                    <a href="https://ethiotelebirr.com/" target="_blank" rel="noreferrer">
                        <img src="https://yt3.googleusercontent.com/ytc/AL5GRJUdBUQwQ9LwWNQAz60ID52b86n-lYTQ1L4vAR4UMw=s900-c-k-c0x00ffffff-no-rj" alt="pr1" 
                        className="object-contain filter md:grayscale hover:scale-110 hover:filter-none duration-200 w-16 lg:w-20" />
                    </a>

                    
                </div>
            </div>
        </div>
    );
}

export default Partners;