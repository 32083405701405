import React, {useEffect, useState, useContext} from 'react';
import { FiUser, FiTag, FiFlag, FiYoutube, FiLoader, FiEdit } from "react-icons/fi";
import { useParams, useLocation, Link } from "react-router-dom";

import { UserContext } from '../../contexts';
import { Constants, Utils } from '../../utils';
import { CampaignService, UserService } from '../../services';

import VideoPlayerPopup from './videoPlayerPopup';

import SharePopup from './share.popup';
import CreatorInfoPopup from './creatorInfoPopup';
import CharityInfo from './charityInfo';
import WithdrawRequest from './withdrawRequest';
import CampaignDonations from './campaignDonations';

import UpdateCampaignDetails from './updateCampaignDetails';

function CampaignDetailScreen() {
    const campaignService = new CampaignService();
    const userService = new UserService();
    const utils = new Utils();

    const [campaign, setCampaign] = useState(null);
    const [topDonations, setTopDonations] = useState([]);
    const [qrCode, setQrCode] = useState('');
    const [loading, setLoading] = useState(false);
    const {id} = useParams();

    const [showVideoPlayer, setShowVideoPlayer] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const [showEditCampaignPopup, setShowEditCampaignPopup] = useState(false);

    const {user} = useContext(UserContext);
    // console.log(user);

    const {pathname} = useLocation();

    const [shareClicked, setShareClicked] = useState(false);

    const [userInfo, setUserInfo] = useState(null);
    const [showCreator, setShowCreator] = useState(false);

    const getUserInfo = (id) => {
        userService.getUserInfo(id)
        .then((res) => {
            setUserInfo(res.data.data);
        })
        .catch((err) => console.log(err));
    }

    useEffect(() => {
        const compare = ( a, b ) => {
            if ( a.amount > b.amount ){
                return -1;
            }
            if ( a.amount < b.amount ){
                return 1;
            }
            return 0;
        }

        if(campaign){
            let donations = [...campaign.donations];
            if(donations.length>0){
                // sort
                donations.sort(compare);

                // console.log(donations);

                // Add the top 3
                if(donations.length>3){
                    setTopDonations([...donations].slice(0,3));
                }else{
                    setTopDonations(donations);
                }
            }
        }
    },[campaign]);

    const fetchCampaignById = () => {
        campaignService.fetchCampaignById(id)
        .then((res) => {
            const camp = res.data.data;
            setCampaign(camp);
            getUserInfo(camp.createdBy);
            setVideoUrl(camp.videoUrl);
            // console.log(camp);
        }).catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }

    useEffect(() => {
        setLoading(true);
        fetchCampaignById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        campaignService.getQrCode(id)
        .then((res) => {
            setQrCode(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkIfMyCampaign = () => {
        if(campaign && user){
            return campaign.createdBy === user.id;
        }else{
            return false;
        }
    }

    return (
        <div className='w-full flex flex-col items-center font-rubik gap-3 pt-20 md:pt-40'>

            {loading && (
                <div>Loading . . .</div>
            )}

            {campaign && (
                <div className='flex flex-col w-full xl:w-3/4 px-5 lg:px-20 xl:px-5 gap-2 md:gap-5 items-start'>

                    <div className="flex flex-row justify-between items-center w-full">
                        <div className='text-2xl md:2xl lg:text-3xl font-medium text-primary-blue text-left hidden md:flex'>{campaign.title}</div>
                        {checkIfMyCampaign() && (
                            <button onClick={() => setShowEditCampaignPopup(true)} className='shrink-0 flex flex-row gap-2 items-center text-primary-blue hover:text-primary-green duration-200'>
                                <FiEdit className='text-base md:text-lg'/>
                                <p>Edit Campaign</p>
                            </button>
                        )}
                    </div>

                    

                    <div className='flex flex-wrap flex-row w-full'>
                        <div className='flex flex-col sm:pr-4 lg:pr-6 rounded-xl gap-4 mb-4 w-full md:mb-10 md:w-2/3'>
                            <div className='relative w-full bg-slate-100 rounded-xl overflow-hidden object-cover md:h-96 h-48'>
                                {campaign.photos.length>0 && (
                                    <img src={`${Constants.baseUrl}/uploaded-file/${campaign.photos[0]}`} alt=''
                                    className='w-full object-cover h-full'/>
                                )}
                                <div className='absolute bottom-2 left-2 flex flex-row gap-2'>
                                    {(campaign.goalEtbAmount ? campaign.goalEtbAmount!==0 : false) && (
                                        <div className='px-2 py-0.5 rounded-full bg-white'>
                                            <p className='text-base font-medium'>ETB</p>
                                        </div>
                                    )}

                                    {(campaign.goalUsdAmount ? campaign.goalUsdAmount!==0 : false) && (
                                        <div className='px-2 py-0.5 rounded-full bg-white'>
                                            <p className='text-base font-medium'>USD</p>
                                        </div>
                                    )}
                                </div>

                            </div>

                            {campaign.createdByCharity && (
                                <div className='flex flex-col w-full'>
                                    <div className="flex flex-row gap-2 items-center mb-2">
                                        <FiLoader className='text-primary-blue text-base'/>
                                        <div className='text-base'>Charity organization:</div>
                                    </div>
                                    <CharityInfo charityId={campaign.charityId}/>
                                </div>
                            )}

                            <div className='text-2xl md:hidden font-medium text-primary-blue'>{campaign.title}</div>

                            {campaign.videoUrl!=='' && (
                                <div className="flex flex-row">
                                    <button 
                                    onClick={() => {
                                        // video
                                        setShowVideoPlayer(true);
                                        
                                    }}
                                    className="flex flex-row gap-3 items-center px-3 py-2 rounded-lg bg-red-400 group">
                                        <div className="text-base text-white group-hover:mr-2 duration-200">Youtube Video</div>
                                        <FiYoutube className='text-white text-xl'/>
                                    </button>
                                </div>
                            )}
                            
                            <div className="flex-col gap-4 hidden md:flex">
                                <div className="flex flex-row gap-3 items-center">
                                    <div className='w-10 h-10 rounded-full bg-slate-100 flex flex-col justify-center items-center'>
                                        <FiUser className='text-primary-blue text-lg'/>
                                    </div>
                                    <div className='flex flex-row gap-2'>
                                        {userInfo==null 
                                        ? (
                                            <div className="h-4 w-60 rounded-xl bg-slate-200"></div>
                                        ) : (
                                            <div className="text-primary-blue text-base font-medium">{userInfo.firstname} {userInfo.lastname}</div>
                                        )}
                                        <div className='text-md font-normal'>
                                            is organizing this fundraiser.
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-row gap-4 items-center">
                                    <div className='text-primary-blue text-md'>
                                        Created <span className='font-medium'>{utils.getTimeDifference(campaign.dateCreated)}</span> ago
                                    </div>
                                    <div className="w-2 h-2 rounded-full bg-slate-900"></div>
                                    <div className='px-6 py-2 rounded-full bg-primary-blue flex flex-row gap-2 items-center text-primary-green'>
                                        <FiTag className='text-lg'/>
                                        {campaign.category}
                                    </div>
                                </div>

                                <div className="flex flex-row mb-0 md:mb-4 w-full">
                                    <div className='text-primary-blue text-md'>
                                        {campaign.description}
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        {/* Card */}
                        <div className='flex flex-col mb-2 duration-200 w-full md:w-1/3'>
                            <div className="flex flex-col gap-2 md:shadow-[0px_0px_12px_4px_rgb(0,0,0,0.06)] bg-white py-1 md:py-3 md:px-3 rounded-xl">
                                {/* <p className='text-left font-medium text-xl text-primary-blue'>Raised so far</p> */}
                                <div className="flex flex-row gap-2 flex-wrap mb-1">
                                    {(campaign.goalEtbAmount ? campaign.goalEtbAmount!==0 : false) && (
                                        <div className='text-primary-blue flex flex-col flex-grow items-center bg-slate-100 rounded-lg pt-2 overflow-hidden min-w-fit'>
                                            <span className='font-bold mr-1.5 text-xl px-2'>{utils.addCommaToNumber(campaign.etbAmount)} <span className='font-normal'>ETB</span></span>
                                            <p className='text-sm px-2'>raised of {utils.addCommaToNumber(campaign.goalEtbAmount??0)} ETB</p>
                                            <div className='h-1.5 w-full rounded-full bg-slate-200 flex flex-row mt-1 md:mt-2 '>
                                                <div className='bg-primary-green rounded-full' style={{width:`${utils.calculatePercentageString(campaign.etbAmount, campaign.goalEtbAmount)}%`}}></div>
                                            </div>
                                        </div>
                                    )}

                                    {(campaign.goalUsdAmount ? campaign.goalUsdAmount!==0 : false) && (
                                        <div className='text-primary-blue flex flex-col flex-grow items-center bg-slate-100 rounded-lg pt-2 overflow-hidden min-w-fit'>
                                            <span className='font-bold mr-1.5 text-xl px-2'>{utils.addCommaToNumber(campaign.usdAmount)} <span className='font-normal'>USD</span></span>
                                            <p className='text-sm px-2'>raised of {utils.addCommaToNumber(campaign.goalUsdAmount??0)} USD</p>
                                            <div className='h-1.5 w-full rounded-full bg-slate-200 flex flex-row mt-1 md:mt-2 '>
                                                <div className='bg-primary-green rounded-full' style={{width:`${utils.calculatePercentageString(campaign.usdAmount, campaign.goalUsdAmount)}%`}}></div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                
                                

                                <button onClick={() => setShareClicked(true)} className='flex flex-row gap-2 px-6 py-2 bg-primary-green rounded-lg items-center duration-200 w-full justify-center'>
                                    <div className='text-primary-blue text-lg font-rubik font-medium'>
                                        Share
                                    </div>
                                </button>

                                <div className='relative'>
                                    {shareClicked && (
                                        <div className='absolute top-0 left-0 w-full'>
                                            <SharePopup shareUrl={`https://lomifunds.org${pathname}`} onCloseClicked={() => setShareClicked(false)}/>
                                        </div>
                                    )}
                                </div>


                                <Link to={`/donation/${id}`} className='flex flex-row gap-2 px-6 py-2 bg-primary-blue rounded-lg items-center duration-200 w-full justify-center mb-2'>
                                    <div className='text-primary-green text-lg font-rubik font-medium'>
                                        Donate now
                                    </div>
                                </Link>

                                {user && (
                                    <Link to={`/report/${id}`} className='flex flex-row gap-2 px-2 py-2 rounded-lg items-center duration-200 w-full mb-1'>
                                        <FiFlag className='text-red-500'/>
                                        <div className='text-red-500 text-md font-rubik'>
                                            Report Campaign
                                        </div>
                                    </Link>
                                )}

                                {topDonations.length>0 && (
                                    <>
                                        <div className='text-primary-blue text-sm mb-1'>Recent Donators</div>
                                        {topDonations.map((donation) => {
                                            // console.log(donation);
                                            return (
                                                <div key={donation.id} className="flex flex-row gap-3 items-center justify-between">
                                                    {/* <div className='w-10 h-10 rounded-full bg-slate-100 flex flex-col justify-center items-center'>
                                                        <FiUser className='text-primary-blue text-lg'/>
                                                    </div> */}
                                                    <div className="flex flex-col flex-grow">
                                                        {donation.annonymous 
                                                        ? (
                                                            <div className='text-primary-blue text-md font-medium'>Anonymous</div>
                                                        ) 
                                                        : (
                                                        <div className='text-primary-blue text-md font-medium'>{`${donation.firstname} ${donation.lastname}`}</div>
                                                        )}
                                                        <div className='text-primary-blue text-sm line-clamp-1'>
                                                            {donation.message}
                                                        </div>
                                                    </div>
                                                    <div className='text-primary-blue text-base font-bold text-right'>
                                                        {utils.addCommaToNumber(donation.depositedAmount)} <span className='ml-1 font-normal text-sm'>{`${donation.currency}`.toUpperCase()}</span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>
                                )}

                                {/* <div className="flex flex-col justify-start mt-2 items-center">
                                    <div className='text-lg font-rubik font-medium text-primary-blue'>
                                        Scan Me!
                                    </div>
                                    <img src={qrCode} 
                                    alt="" className='mb-0 w-full md:w-1/2 border-4 border-primary-green rounded-xl'/>
                                </div> */}

                            </div>
                        </div>
                    </div>


                    <div className="flex flex-col md:flex-row w-full justify-between">
                        <div className="flex flex-col w-full lg:w-3/4 pr-0 md:pr-6">
                            <div className="flex-col gap-4 flex md:hidden mb-4">
                                <div className="flex flex-row gap-2 py-2 items-center">
                                    <div className="w-8 md:w-10 h-8 md:h-10 rounded-full bg-slate-200 flex justify-center items-center shrink-0">
                                        <FiUser size={15} />
                                    </div>
                                    <div className='flex flex-row gap-2 '>
                                        {userInfo==null 
                                        ? (
                                            <div className="h-4 w-60 rounded-xl bg-slate-200"></div>
                                        ) : (
                                            <div className="text-primary-blue text-base font-medium">{userInfo.firstname} {userInfo.lastname} <span className='text-md font-normal'>is organizing this fundraiser.</span></div>
                                        )}
                                        
                                    </div>
                                </div>

                                <div className="flex flex-row gap-4 items-center">
                                    <div className='text-primary-blue text-md'>
                                        Created <span className='font-medium'>{utils.getTimeDifference(campaign.dateCreated)}</span> ago
                                    </div>
                                    <div className="w-2 h-2 rounded-full bg-slate-900"></div>
                                    <div className='px-6 py-2 rounded-full text-primary-green bg-primary-blue flex flex-row gap-2 items-center'>
                                        <FiTag className='text-lg'/>
                                        {campaign.category}
                                    </div>
                                </div>

                                <div className="flex flex-row mb-0 md:mb-4 w-full">
                                    <div className='text-primary-blue text-md'>
                                        {campaign.description}
                                    </div>
                                </div>
                            </div>
                            {checkIfMyCampaign() && (
                                <WithdrawRequest campaign={campaign} user={user} updateCampaign={fetchCampaignById}/>
                            )}
                            {!checkIfMyCampaign() && (
                                <>
                                    <div className='text-primary-blue text-xl font-bold'>
                                        Organizer
                                    </div>
                                    <div className="flex flex-row gap-3 items-center mb-4">
                                        <div className='w-10 h-10 rounded-full bg-slate-100 flex flex-col justify-center items-center'>
                                            <FiUser className='text-primary-blue text-lg'/>
                                        </div>
                                        <div className="flex flex-col flex-grow">
                                            {userInfo==null 
                                            ? (
                                                <div className="flex flex-col gap-2">
                                                    <div className="h-4 w-60 rounded-xl bg-slate-200"></div>
                                                    <div className="h-3 w-32 rounded-xl bg-slate-200"></div>
                                                </div>
                                            ) : (
                                                <div className="flex flex-col">
                                                    <div className="text-primary-blue text-base font-medium">{userInfo.firstname} {userInfo.lastname}</div>
                                                    <div className="text-primary-blue text-sm">{userInfo.email}</div>
                                                </div>
                                            )}
                                        </div>
                                        <button onClick={() => {
                                            if(userInfo!=null){
                                                setShowCreator(true);
                                            }
                                        }} className='flex flex-row gap-2 px-6 py-1 border-primary-blue border-2 rounded-lg items-center duration-200 justify-center mb-4'>
                                            <div className='text-primary-blue text-lg font-rubik'>
                                                Contact
                                            </div>
                                        </button>
                                    </div>
                                </>
                            )}

                            <CampaignDonations campaign={campaign}/>
                        </div>

                        <div className="flex flex-col justify-start mt-2 items-center mb-10 px-2 ">
                            <p className='text-center w-full md:w-1/2 lg:w-2/3 text-sm text-slate-600'>If you wish to open this campaign on your phone scan here.</p>
                            <div className='text-xl font-rubik font-bold text-primary-blue'>
                                Scan Me!
                            </div>
                            <img src={qrCode} 
                            alt="" className='mb-0 w-2/3 border-4 border-primary-green rounded-xl'/>
                        </div>
                    </div>

                </div>
            )}

            {(showCreator && userInfo!=null) && (
                <CreatorInfoPopup onClose={() => setShowCreator(false)} creatorInfo={userInfo}/>
            )}

            {(showVideoPlayer && videoUrl!=='') && (
                <VideoPlayerPopup onClose={() => {
                    setShowVideoPlayer(false);
                }} videoUrl={videoUrl} />
            )}

            {showEditCampaignPopup && (
                <UpdateCampaignDetails onClose={() => setShowEditCampaignPopup(false)} campaignData={campaign} fetchCampaignData={fetchCampaignById}/>
            )}

        </div>
    );
}

export default CampaignDetailScreen;