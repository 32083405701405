import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserService } from '../../services';
// import trashImage from '../../assets/illustrations/trash.svg';

function UserDeleteScreen() {

    const userService = new UserService();

    const navigate = useNavigate();

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleDeleteAccount = () => {
        setLoading(true);
        userService.deleteMyAccount().then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
        }).finally(() => setLoading(true));
    }

    return (
        <div className='w-full flex flex-col items-center font-rubik gap-3 pt-20 md:pt-36 mb-20'>
            <div className='flex flex-col w-full xl:w-4/5 2xl:w-2/3 px-3 md:px-10 xl:px-20 2xl:px-5 gap-3 text-primary-blue'>


                {/* <img src={trashImage} alt="delete account" className='w-56' /> */}
                <p className='text-xl font-medium mb-2'>Are you sure you want to delete your account permanently?</p>

                <div className="flex flex-col bg-red-100 px-4 py-4 rounded-xl gap-2 mb-2">
                    <p className='font-medium text-lg'>Your account, along with all associated data, will permanently be removed.</p>
                    <p>This includes:</p>
                    <div className="flex flex-col font-medium">
                        <li>Created campaign history (live and closed)</li>
                        <li>Donation information</li>
                        <li>Personal details</li>
                    </div>
                    <p>Please note: Once deleted, your account cannot be recovered.</p>
                </div>

                <div className="flex flex-row justify-between">
                    <button onClick={() => navigate(-1)} className='bg-gray-200 text-primary-blue px-4 py-1.5 rounded-lg uppercase hover:bg-opacity-90'>
                        <p>Cancel</p>
                    </button>
                    <button onClick={() => setShowConfirmation(true)} className='bg-red-500 text-white px-4 py-1.5 rounded-lg uppercase hover:bg-opacity-90'>
                        <p>Delete Account</p>
                    </button>
                </div>

            </div>

            {showConfirmation && (
                <div className='inset-0 absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-black bg-opacity-10 backdrop-blur-sm'>
                    <div className='rounded-lg w-[500px] p-4 bg-white flex flex-col gap-2 text-primary-blue'>
                        <p className='text-xl font-medium'>Are you sure?</p>

                        {loading && <p className='text-red-400'>Deleting Account...</p>}
            
                        <div className='flex flex-col mb-2 gap-1'>
                            <p>We're sorry to see you go!</p>
                            <p>We hope you'll consider rejoining <span className='text-primary-green'>Lomi Funds</span> in the future. In the meantime, we wish you the very best.</p>
                        </div>

                        <div className="flex flex-row justify-between gap-2">
                            <button onClick={() => setShowConfirmation(false)} className='bg-gray-200 text-primary-blue px-4 py-1.5 rounded-lg uppercase hover:bg-opacity-90 w-fit flex-grow'>
                                <p>Cancel</p>
                            </button>
                            <button onClick={handleDeleteAccount} className='bg-red-500 text-white px-4 py-1.5 rounded-lg uppercase hover:bg-opacity-90 w-fit flex-grow'>
                                <p>Delete Account</p>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UserDeleteScreen