import axios from "axios";
import { Constants } from "../utils";

class OtpService {

    async requestOtp(formData){
        return axios.post(`${Constants.baseUrl}/otp/request`, formData);
    }

}

export default OtpService;