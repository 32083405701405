import React, { useEffect, useState } from 'react';
import { WithdrawService } from '../../services';

function AccountDetails() {
    const withdrawService = new WithdrawService();

    const [loading, setLoading] = useState(false);

    const [update, setUpdate] = useState(false);

    const [formData, setFormData] = useState({
        accountName: '',
        accountNumber: '',
        bankName: '',
        accountType: '',
        phoneNumber: ''
    });

    const [error, setError] = useState({
        accountName: '',
        accountNumber: '',
        bankName: '',
        accountType: '',
        phoneNumber: ''
    });

    const validateFormData = () => {
        let errorData = {
            accountName: '',
            accountNumber: '',
            bankName: '',
            accountType: '',
            phoneNumber: ''
        }

        if(formData.accountName!=='' && formData.accountName.length<4){
            console.log("heree");
            errorData.accountName = 'Invalid Account Name'
        }

        if(formData.accountNumber!=='' && formData.accountNumber.length<6){
            errorData.accountNumber = 'Invalid Account Number'
        }

        if(formData.bankName!=='' && formData.bankName.length<4){
            errorData.bankName = 'Invalid Bank Name'
        }

        if(formData.phoneNumber!=='' && formData.phoneNumber.length!==9){
            errorData.phoneNumber = 'Invalid Phone Number'
        }

        setError((last_errors) => errorData);
    }

    useEffect(() => {
        validateFormData();
        // eslint-disable-next-line
    }, [formData])

    const handleTextInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleAccountType = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            accountType: value
        }))
    }

    const handleSubmit = () => {
        setLoading(true);
        withdrawService.addMyAccountDetails(formData).then((res) => {
            console.log(res.data);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
            setUpdate(true);
        });
    }

    const handleUpdate = () => {
        setLoading(true);
        withdrawService.updateMyAccountDetails(formData).then((res) => {
            console.log(res.data);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });
    }

    const fetchMyAccountDetails = () => {
        setLoading(true);
        withdrawService.getMyAccountDetails().then((res) => {
            // console.log(res.data);
            if(res.data.data){
                setUpdate(true);
            }

            console.log(res.data);
            setFormData((form) => {
                return {...form, ...res.data.data};
            })
        }).catch((err) => {
            console.log(err);
        }).finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchMyAccountDetails();
        // eslint-disable-next-line
    }, [])

    return (
        <div className='w-full bg-white border-[1px] border-slate-200 rounded-md py-4 px-4 gap-2'>
            <h3>{loading && 'Loading...'}</h3>
            <p className='text-center font-medium text-xl'>Withdraw Account Details</p>
            <p className='text-center text-base mb-4 text-slate-600'>This account will be used to withdraw your funds.</p>


            <div className="flex flex-col md:flex-row w-full gap-4 mb-5">
                <div className="flex flex-col flex-grow md:w-1/2 gap-1">
                    <label htmlFor="accountName">Account Name: {error.accountName!=='' && <span className='text-red-500 text-sm'>{error.accountName}</span>}</label>
                    <input onChange={handleTextInputChange} value={formData.accountName} type="text" name='accountName' id='accountName' placeholder='Account Name' className='px-3 py-2 rounded-md bg-slate-100' />
                </div>
                <div className="flex flex-col flex-grow md:w-1/2 gap-1">
                    <label htmlFor="accountNumber">Account Number: {error.accountNumber!=='' && <span className='text-red-500 text-sm'>{error.accountNumber}</span>}</label>
                    <input onChange={handleTextInputChange} value={formData.accountNumber} type="text" name='accountNumber' id='accountNumber' placeholder='Account Number' className='px-3 py-2 rounded-md bg-slate-100' />
                </div>
            </div>

            <div className="flex flex-col md:flex-row w-full gap-4 mb-5">
                <div className="flex flex-col flex-grow md:w-1/2 gap-1">
                    <label htmlFor="bankName">Bank Name: {error.bankName!=='' && <span className='text-red-500 text-sm'>{error.bankName}</span>}</label>
                    <input onChange={handleTextInputChange} value={formData.bankName} type="text" name='bankName' id='bankName' placeholder='Bank Name' className='px-3 py-2 rounded-md bg-slate-100' />
                </div>
                <div className="flex flex-col flex-grow md:w-1/2 gap-1">
                    <label htmlFor="phoneNumber">Phone Number: {error.phoneNumber!=='' && <span className='text-red-500 text-sm'>{error.phoneNumber}</span>}</label>
                    <div className="flex flex-row w-full bg-slate-100">
                        <div className='flex flex-col justify-center items-center px-2'><p>+251</p></div>
                        <input onChange={handleTextInputChange} value={formData.phoneNumber} type="text" name='phoneNumber' id='phoneNumber' placeholder='0911...' className='px-3 py-2 rounded-md bg-slate-100 flex-grow' />
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row w-full gap-4 mb-3">
                <div className="flex flex-col flex-grow md:w-1/2 gap-1">
                    <p>Account Type:</p>
                    <div className="flex flex-row gap-4">

                        <div className="flex flex-row gap-2 items-center">
                            <input type="radio" id='account-1' value='saving' 
                            checked={formData.accountType==='saving'}
                            onChange={() => handleAccountType('saving')} 
                            className={`appearance-none w-4 h-4 rounded-full ${formData.accountType==='saving' ? 'bg-primary-green' : 'bg-slate-200'}`} />

                            <label htmlFor="account-1">Saving</label>
                        </div>

                        <div className="flex flex-row gap-2 items-center">
                            <input type="radio" id='account-1' value='checking' 
                            checked={formData.accountType==='checking'}
                            onChange={() => handleAccountType('checking')} 
                            className={`appearance-none w-4 h-4 rounded-full ${formData.accountType==='checking' ? 'bg-primary-green' : 'bg-slate-200'}`} />

                            <label htmlFor="account-1">Checking</label>
                        </div>

                    </div>
                </div>

                <div className="flex flex-row flex-grow md:w-1/2">
                    {update ? (
                        <button onClick={handleUpdate} className='px-4 py-1 w-full bg-secondary-blue rounded-md'>
                            <p className='text-primary-green'>Update</p>
                        </button>
                    ) : (
                        <button onClick={handleSubmit} className='px-4 py-1 w-full bg-secondary-blue rounded-md'>
                            <p className='text-primary-green'>Submit</p>
                        </button>
                    )}
                </div>
            </div>

            
            
        </div>
    );
}

export default AccountDetails;