import React from 'react';
import { Constants } from '../../utils';

function SelectCategories({selectedCategory, setSelectedCategory}) {
    return (
        <div className='w-full flex flex-col '>
            <div className="flex flex-col items-start w-full mb-6">
                <div className='text-3xl md:text-3xl font-medium text-primary-blue'>Let's begin your fundraising journey!</div>
                <div className='text-primary-blue text-md md:text-lg line-clamp-2'>We're here to guide you every step of the way.</div>
            </div>

            <div className='text-xl md:text-2xl font-medium text-primary-blue'>
                What best describes why you're fundraising?
            </div>
            {/* <div className='text-primary-blue text-sm md:text-md line-clamp-2 mb-5'>You can choose the right category for your fundraising.</div> */}

            <div className="flex flex-wrap gap-2 md:gap-3 mt-5">
                {Constants.categories.map((itm) => (
                    <div onClick={() => setSelectedCategory(itm.title)} key={itm.id} 
                    className={`px-5 py-2 text-sm md:text-md rounded-full cursor-pointer ${selectedCategory===itm.title ? 'bg-primary-green' : 'bg-slate-100'}`}>
                        {itm.title}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SelectCategories;