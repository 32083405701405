import React from 'react';
// import { FiCheck } from "react-icons/fi";
import { Link } from 'react-router-dom';

import verified_account_image from "../../assets/illustrations/account_verified.png";


function VerifyScreen() {
    return (
        <div className='w-full flex flex-col font-rubik gap-2 pt-20 md:pt-36 items-center'>
            <div className='flex flex-col w-full xl:w-4/5 px-4 md:px-10 xl:px-20 2xl:px-5 gap-4 items-center'>
                <div className='text-primary-blue text-2xl md:text-4xl font-medium mb-2'> Account Verified! </div>

                <div className='text-primary-blue text-base md:text-lg mb-4 text-center px-4'> You can now login to your account using your email and password.</div>

                <img src={verified_account_image} alt="Check Email" className='w-[90%] md:w-[300px] lg:w-[350px] xl:w-[400px] my-4' />

                <div className='text-primary-blue text-base md:text-lg mt-4 text-center w-full lg:w-2/3'>
                    Signin now?
                </div>

                <div className="flex flex-row justify-center">
                    <Link to={'/login'}
                    className='flex flex-row gap-2 px-6 py-2 bg-white border-primary-blue border-2 rounded-md items-center justify-center'>
                        <div className='text-primary-blue text-base font-rubik'>
                            Go to Login
                        </div>
                    </Link>
                </div>

            </div>
        </div>
    )
}

export default VerifyScreen;