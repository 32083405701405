import React from 'react';
import { FiPlus, FiX } from "react-icons/fi";


function CampaignFiles({photo, supporting, setPhoto, setSupporting, removeDoc}) {
    return (
        <div className='w-full flex flex-col gap-3'>
            <div className='text-xl md:text-2xl font-medium text-primary-blue'>
                Photo
            </div>
            <div className='text-primary-blue text-md line-clamp-3 mb-3'>
                Photograph that can describe the campaign.
            </div>

            {!photo && (
                <div className="bg-slate-100 rounded-md px-4 py-3 w-60 h-40 flex justify-center items-center">
                    <FiPlus size={30} className="text-slate-400"/>
                </div>
            )}

            {photo && (
                <img alt="not fount" src={URL.createObjectURL(photo)} className="w-60 h-40 rounded-md object-cover" />
            )}

            <input
                type="file"
                name="myImage"
                onChange={(event) => {
                    setPhoto(event.target.files[0]);
                }}
            />

            <div className='text-xl md:text-2xl font-medium text-primary-blue mt-5'>
                Supporting Documents
            </div>
            <div className='text-primary-blue text-md line-clamp-3 mb-1'>
                Medical Document, or Other explanatory documents.
            </div>

            {supporting.length > 0 && (
                <div className='w-full flex flex-col gap-2'>
                    {supporting.map((doc, idx) => {
                        return (
                            <div key={idx} className="bg-slate-50 rounded-md px-4 py-3 w-full flex flex-row items-center">
                                <div className="text-primary-blue flex-grow line-clamp-1">
                                    {doc.name}
                                </div>
                                <div className="cursor-pointer hover:text-red-500" onClick={() => removeDoc(idx)}>
                                    <FiX size={20} className="text-red-400"/>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            
            <input
                type="file"
                name="myImage"
                onChange={(event) => {
                    setSupporting(event.target.files[0]);
                }}
            />

            {/* <div className='text-primary-blue text-md md:text-md'>
                By continuing, you agree to the Lomi <a href="/" className='text-primary-green underline underline-offset-1'>terms</a> and <a href="/" className='text-primary-green underline underline-offset-1'>privacy</a> notice.
            </div> */}
            
        </div>
    );
}

export default CampaignFiles;