import React, { useEffect, useState } from 'react';

import { DonationService } from '../../services';
import { Utils } from '../../utils';

function CampaignDonations({campaign}) {
    // console.log(campaign);
    const donationService = new DonationService();
    const utils = new Utils();

    const [loading, setLoading] = useState(false);
    const [donations, setDonations] = useState();
    const [donationDetails, setDonationDetails] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;

    const getDonationOfACampaign = () => {
        setLoading(true);
        donationService.fetchCampaignDonations(campaign._id, currentPage, pageSize).then((res) => {
            if(res.data){
                setDonations(res.data.data.donations);
                setDonationDetails({...res.data.data, donations:[]});
            }
        }).catch((err) => {
            console.log(err);
        }).finally(() => setLoading(false));
    }

    useEffect(() => {
        getDonationOfACampaign();
        // eslint-disable-next-line
    }, [currentPage])

    return (
        <div className='flex flex-col gap-1 mb-8'>
            <div className='text-primary-blue text-xl font-bold'>
                Donations ({donationDetails ? donationDetails.total : '-'}) {loading && <span className='text-base font-normal ml-2'>Loading...</span>}
            </div>
            <div className="flex flex-col gap-2 md:flex-row md:flex-wrap justify-between">
                <div className='text-primary-blue text-md'>
                    Please donate to share words of support.
                </div>

                {donationDetails && donationDetails.total>0 && (
                    <div className="flex flex-row gap-2">
                        <button disabled={currentPage===1} onClick={() => {
                            if(currentPage>1){
                                setCurrentPage((current) => current-1);
                            }
                        }}>
                            <p className={`${currentPage===1 ? 'text-gray-400' : 'text-primary-green'}`}>Back</p>
                        </button>
                        <p>{currentPage} or {donationDetails.lastPage}</p>
                        <button disabled={currentPage===donationDetails.lastPage} 
                        onClick={() => {
                            if(currentPage<donationDetails.lastPage){
                                setCurrentPage((current) => current+1);
                            }
                        }}>
                            <p className={`${currentPage===donationDetails.lastPage ? 'text-gray-400' : 'text-primary-green'}`}>Next</p>
                        </button>
                    </div>
                )}
            </div>

            {donations && (
                <div className='flex flex-col gap-2 mt-2 min-h-[200px]'>
                    {donations.map((donation) => {
                        return (
                            <div key={donation.id} className="flex flex-row w-full justify-between items-center border-[1px] bg-slate-50 px-2 rounded-md">
                                <div className="flex flex-col">
                                    {donation.annonymous 
                                    ? (
                                        <div className='text-primary-blue text-md font-medium'>Anonymous</div>
                                    ) 
                                    : (
                                        <div className='text-primary-blue text-md font-medium'>{`${donation.firstname} ${donation.lastname}`}</div>
                                    )}
                                    <div className='text-primary-blue text-sm'>
                                        {donation.message}
                                    </div>
                                </div>
                                <div className='text-primary-blue text-sm'>
                                    <span className='font-bold text-base'>{utils.addCommaToNumber(donation.depositedAmount)}</span> {`${donation.currency}`.toUpperCase()}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default CampaignDonations