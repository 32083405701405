import React, { useEffect, useState } from 'react';
import {FiX} from "react-icons/fi";
import { CategoryService } from '../../services';

function FilteringMenu({onContinue, onCancel}) {
    const categoryService = new CategoryService();
    
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState([]);

    useEffect(() => {
        const fetchCategories = () => {
            setLoading(true);
            categoryService.fetchCategoriesList().then((res) => {
                // console.log(res.data);
                setCategories(res.data);
            }).catch((err) => {
                console.log(err);
            }).finally(() => setLoading(false));
        }

        fetchCategories();
        // eslint-disable-next-line
    }, [])

    const checkIfInSelectedCategories = (category) => {
        const result = selectedCategory.filter((item) => item.id===category.id);
        return result.length>0;
    }
    
    const addToSelectedCategories = (category) => {
        const result = selectedCategory.filter((item) => item.id===category.id);
        if(result.length>0){
            removeFromSelectedCategories(category);
        }else{
            setSelectedCategory((prev) => [...prev, category]);
        }
    }

    const removeFromSelectedCategories = (category) => {
        const result = selectedCategory.filter((item) => item.id!==category.id);
        setSelectedCategory((prev) => result);
    }

    return (
        <div className='absolute top-16 left-0 w-full bg-white border-[1px] border-slate-200 rounded-xl shadow-lg p-3'>
            <p className='font-medium'>Categories:</p>
            {loading && (
                <div className='flex flex-wrap my-2'>
                    {[1,2,3,4].map((item) => {
                        return (
                            <div key={item} className='mr-2 h-7 w-20 rounded-full bg-slate-100'></div>
                        );
                    })}
                </div>
            )}

            {categories.length>0 ? (
                <div className='flex flex-wrap my-2'>
                    {categories.map((category) => {
                        return (
                            <button key={category.id} onClick={() => addToSelectedCategories(category)}
                            className={`flex flex-row gap-1 items-center px-3 py-1 mr-2 mb-2 rounded-full border-[1px] duration-300 hover:border-primary-green ${checkIfInSelectedCategories(category) ? 'bg-primary-green' : 'bg-slate-100'}`}>
                                <p className='text-sm'>{category.title}</p>
                                {checkIfInSelectedCategories(category) && <FiX size={12}/>}
                            </button>
                        );
                    })}
                </div>
            ) : !loading && (
                <div><p className='text-gray-500'>No Categories Available!</p></div>
            )}

            <div className='flex flex-row justify-center gap-4'>
                <button onClick={onCancel} className='px-4 py-1.5 rounded-full bg-primary-blue hover:opacity-90 duration-300'>
                    <p className='uppercase text-sm text-gray-200'>Cancel</p>
                </button>
                {selectedCategory.length>0 && (
                    <button onClick={() => onContinue(selectedCategory)} className='px-4 py-1.5 rounded-full bg-primary-green hover:opacity-90 duration-300'>
                        <p className='uppercase text-sm'>({selectedCategory.length}) Continue</p>
                    </button>
                )}
            </div>
        </div>
    );
}

export default FilteringMenu;