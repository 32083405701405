import React, {useContext} from 'react';
import { MdBookmarkBorder, MdBookmark } from "react-icons/md";
import { Link } from "react-router-dom";

import { UserContext } from '../../contexts';
import { Utils, Constants } from '../../utils';

function CampaignItem({campaign, onSaveOrRemove, saved}) {
    const utils = new Utils();
    const {loggedIn} = useContext(UserContext);

    const hasEtb = campaign.goalEtbAmount ? campaign.goalEtbAmount!==0 : false;
    const hasUsd = campaign.goalUsdAmount ? campaign.goalUsdAmount!==0 : false;

    return (
        <div  key={campaign.id} className='w-1/2 md:w-1/3 xl:w-1/4 p-1 bg-white mb-2'>
            <div className='w-full h-full flex flex-col gap-2 bg-white border-[1px] duration-200 border-slate-200 rounded-xl p-1 pb-3 hover:shadow-lg hover:z-20'>    
                <Link to={`/campaign/${campaign.id}`} className="flex flex-col gap-2">
                    {campaign.photos.length>0 && (
                        <img src={`${Constants.baseUrl}/uploaded-file/${campaign.photos[0]}`} alt=''
                        className='h-32 md:h-40 bg-slate-200 w-full rounded-xl object-cover'/>
                    )}
                    <div className="flex flex-col md:px-2 gap-2">
                        <div className='text-md md:text-lg font-bold text-primary-blue line-clamp-1 md:line-clamp-1'>{campaign.title}</div>
                        <div className='text-primary-blue text-sm line-clamp-2 md:line-clamp-2'>
                            {campaign.description}
                        </div>
                    </div>
                </Link>
                <div className="flex-grow px-2">
                    {/* <div className='w-0.5 h-full bg-slate-200 rounded-full'></div> */}
                </div>
                <div className="flex flex-col md:px-2 gap-1">
                    
                    {/* <div className='h-1.5 w-full rounded-full bg-slate-100 flex flex-row mt-4'>
                        <div className='bg-primary-green rounded-full' style={{width:`${utils.calculatePercentageString(campaign.currentCollected, campaign.totalAmountNeeded)}%`}}></div>
                    </div> */}

                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col items-start gap-2 mt-1">
                            {hasEtb && (
                                <div className='text-primary-blue text-sm md:text-base flex-grow flex flex-row justify-center gap-2 items-center border-[1px] border-primary-green px-1 rounded-full'>
                                    <p className='font-medium pl-1'>{utils.addCommaToNumber(campaign.etbAmount??0, true)}</p>
                                    <div className="bg-slate-200 px-2 rounded-full">
                                        <p className='text-sm'>ETB</p>
                                    </div>
                                </div>
                            )}
                            {hasUsd && (
                                <div className='text-primary-blue text-sm md:text-base flex-grow flex flex-row justify-center gap-2 items-center border-[1px] border-primary-green px-1 rounded-full'>
                                    <p className='font-medium pl-1'>{utils.addCommaToNumber(campaign.usdAmount??0, true)}</p>
                                    <div className="bg-slate-200 px-2 rounded-full">
                                        <p className='text-sm'>USD</p>
                                    </div>
                                </div>
                            )}
                            
                        </div>
                        {loggedIn && (
                            <div onClick={() => {
                                onSaveOrRemove(campaign.id, saved===true);
                            }} className='flex flex-row gap-2 hover:gap-6 px-2 py-2 items-center duration-200 group'>
                                {saved===true 
                                ? (
                                    <MdBookmark className='text-slate-800 text-xl group-hover:text-primary-green duration-200 group-hover:scale-125'/>
                                ) 
                                : (
                                    <MdBookmarkBorder className='text-slate-800 text-xl group-hover:text-primary-green duration-200 group-hover:scale-125'/>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CampaignItem;