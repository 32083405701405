import React from 'react';

function CampaignDetails({title, desc, setTitle, setDesc, videoUrl, setVideoUrl}) {
    return (
        <div className='w-full flex flex-col gap-3'>
            <div className='text-xl md:text-2xl font-medium text-primary-blue'>
                Name of the Campaign?
            </div>
            <div className='text-primary-blue text-md line-clamp-3 mb-3'>
                This will help optimize for search, try to make it as unique as possible.
            </div>

            <input type="text" 
            value={title} 
            onChange={(val) => setTitle(val.currentTarget.value)} 
            placeholder="Title"
            className="bg-slate-100 rounded-md px-4 py-3 flex-grow rounded-tr-none rounded-br-none"/>

            <div className='text-xl md:text-2xl font-medium text-primary-blue mt-3'>
                What is the story?
            </div>
            <div className='text-primary-blue text-md line-clamp-3 mb-3'>
                Try to describe the story behind the campaign here.
            </div>

            <textarea cols="30" rows="5"
            value={desc} 
            onChange={(val) => setDesc(val.currentTarget.value)}  
            placeholder='Campaign Description' 
            className="bg-slate-100 rounded-md px-4 py-3 flex-grow rounded-tr-none rounded-br-none"/>

            <div className='text-xl md:text-2xl font-medium text-primary-blue mt-3'>
                Youtube Video Url
            </div>
            <div className='text-primary-blue text-md line-clamp-3 mb-3'>
                This will help discribe how the donation will be used.
            </div>

            <input type="text" 
            value={videoUrl} 
            onChange={(val) => setVideoUrl(val.currentTarget.value)} 
            placeholder="Youtube video Url"
            className="bg-slate-100 rounded-md px-4 py-3 flex-grow rounded-tr-none rounded-br-none"/>
            
        </div>
    );
}

export default CampaignDetails;