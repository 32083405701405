import axios from "axios";
import Cookies from 'js-cookie';
import { Constants } from "../utils";

class WithdrawService {

    async addMyAccountDetails(formData){
        const token = Cookies.get('user-token');

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'application/json'}
        };

        return axios.post(`${Constants.baseUrl}/withdraw/account/create`, formData, config);
    }

    async getMyAccountDetails(){
        const token = Cookies.get('user-token');

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'application/json'}
        };

        return axios.get(`${Constants.baseUrl}/withdraw/account/get`, config);
    }

    async updateMyAccountDetails(formData){
        const token = Cookies.get('user-token');

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'application/json'}
        };

        return axios.put(`${Constants.baseUrl}/withdraw/account/update`, formData, config);
    }

    async getMyWithdraws(){
        const token = Cookies.get('user-token');

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'application/json'}
        };

        return axios.get(`${Constants.baseUrl}/withdraw/mine`, config);
    }

    async requestWithdraw(formData){
        const token = Cookies.get('user-token');

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'application/json'}
        };

        return axios.post(`${Constants.baseUrl}/withdraw/request`, formData, config);
    }

}

export default WithdrawService;