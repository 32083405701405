import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { LoadingSpinner } from '../shared';
import { Utils } from '../../utils';
import { WithdrawService } from '../../services';

function WithdrawRequest({campaign, user, updateCampaign}) {
    const utils = new Utils();
    const withdrawService = new WithdrawService();

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectedCurrency, setSelectedCurrency] = useState();


    const etbAvailable = (campaign.etbAmount||0) - (campaign.withdrawEtbAmount||0);
    const usdAvailable = (campaign.usdAmount||0) - (campaign.withdrawUsdAmount||0);

    const handleWithdrawRequest = () => {
        setLoading(true);
        // Request withdrawal here
        const data = {
            campaignId: campaign.id??campaign._id,
            currency: selectedCurrency
        }

        if((selectedCurrency==='etb' && etbAvailable===0) || (selectedCurrency==='usd' && usdAvailable===0)){
            toast.error("You don't have balance in the selected currency.");
            setLoading(false);
            return;
        }

        withdrawService.requestWithdraw(data).then(() => {
            updateCampaign();
            setShowConfirmation();
            toast.success("Withdrawal request sent!");
        }).catch((err) => {
            console.log(err);
            // Show toast message
            toast.error("Failed requesting withdrawal, please try again!");
        }).finally(() => setLoading(false));
        
    }

    return (
        <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-2 md:gap-4 mb-2 lg:mb-4">
            <div className="flex flex-col">
                <div className='text-primary-blue text-xl font-bold'>
                    Withdraw & Close Campaign
                </div>
                <div className="text-slate-500 text-sm">By clicking "Withdraw" you can close and request to withdraw the collected amount from Lomi Funds.</div>
            </div>
            <div className="flex flex-col">
                <button onClick={() => setShowConfirmation(true)} 
                className='flex flex-row gap-2 px-6 py-1 border-primary-blue border-2 rounded-lg items-center duration-300 justify-center hover:bg-primary-green'>
                    <div className='text-primary-blue text-lg font-rubik'>
                        Withdraw
                    </div>
                </button>
            </div>

            {showConfirmation && (
                <div className='fixed z-20 inset-0 top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-black bg-opacity-25 px-4'>
                    <div className="flex flex-col bg-white rounded-lg px-4 py-4 w-full md:w-[500px]">
                        <h3 className='font-medium text-2xl mb-1 text-primary-blue'>Request withdrawal</h3>


                        <p className='text-lg font-medium text-secondary-blue mt-2'>Available to withdraw:</p>
                        <p className='text-[15px] text-secondary-blue mb-2'>Please choose the currency you wish to request a withdraw.</p>
                        <div className="flex flex-row flex-wrap gap-2 mb-2">
                            <button onClick={() => setSelectedCurrency('etb')}
                            className={`flex flex-col items-center flex-grow w-fit px-2 py-3 rounded-lg border-[1px] ${selectedCurrency==='etb' ? 'border-primary-green bg-primary-green' : 'border-slate-200 bg-slate-50'}`}>
                                <p className='text-center font-bold text-xl'>{utils.addCommaToNumber(etbAvailable)}</p>
                                <p className='text-sm text-center'>ETB</p>
                            </button>
                            <button onClick={() => setSelectedCurrency('usd')}
                            className={`flex flex-col items-center flex-grow w-fit px-2 py-3 rounded-lg border-[1px] ${selectedCurrency==='usd' ? 'border-primary-green bg-primary-green' : 'border-slate-200 bg-slate-50'}`}>
                                <p className='text-center font-bold text-xl text-primary-blue'>{utils.addCommaToNumber(usdAvailable)}</p>
                                <p className='text-sm text-center text-secondary-blue'>USD</p>
                            </button>
                            
                        </div>

                        <p className='text-[15px] text-slate-500'>Understand that the withdrawal process could take 2-3 working days.</p>
                        <p className='text-[15px] text-secondary-blue mt-2'>Please read Lomi fund's, <Link to={'/legal/termsofservice'} className='text-red-400'>Withdrawal policy</Link>.</p>

                        <div className="flex flex-row justify-between mt-4 gap-3">
                            <button onClick={() => setShowConfirmation(false)} 
                            className='flex flex-row flex-grow gap-2 px-4 py-1.5 rounded-lg items-center duration-300 justify-center bg-red-400 hover:bg-opacity-90'>
                                <div className='text-white text-lg font-rubik'>
                                    Cancel
                                </div>
                            </button>

                            <button disabled={loading || !selectedCurrency} onClick={handleWithdrawRequest} 
                            className={`flex flex-row flex-grow gap-2 px-4 py-1.5 rounded-lg items-center duration-300 justify-center ${(loading || !selectedCurrency) ? 'bg-gray-100 hover:bg-gray-200' : 'bg-primary-blue hover:bg-secondary-blue'}`}>
                                {loading && <LoadingSpinner radius='4'/>}
                                <div className={`text-lg font-rubik ${(loading || !selectedCurrency) ? 'text-primary-blue ' : 'text-white '}`}>
                                    Request Withdraw
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default WithdrawRequest;