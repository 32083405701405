import React, {useState, useEffect} from 'react';
import { FiTag, FiArrowRight } from "react-icons/fi";

import { Constants, Utils } from '../../utils';
import { CampaignService } from '../../services';

function FeaturedCampaign() {
    const campaignService = new CampaignService();
    const utils = new Utils();

    const [loading, setLoading] = useState(false);
    const [featuredCampaigns, setFeaturedCampaigns] = useState([]);

    useEffect(() => {
        setLoading(true);
        campaignService.fetchFeaturedCampaigns()
        .then((res) => {
            // console.log(res.data);
            setFeaturedCampaigns(res.data.data);
        }).catch((err) => {
            console.log(err);
        }).finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='flex flex-col w-full px-4 lg:px-20 py-4 lg:py-8 font-rubik gap-3'>
            <div className='text-primary-blue text-lg'>Where you can help</div>
            <div className="flex flex-row justify-between">
                <div className='text-4xl font-medium text-primary-blue'>Top Raising Campaigns</div>
                <a href="/explore" className='flex flex-row gap-3 items-center'>
                    <div className='text-primary-blue text-lg line-clamp-2'>
                        Browse all
                    </div>
                    <FiArrowRight className='text-md'/>
                </a>
            </div>

            {loading && (
                <div className='flex flex-row flex-wrap mt-3 justify-center items-center'>
                    <div className='text-primary-blue text-md line-clamp-2'>
                        Loading . . .
                    </div>
                </div>
            )}

            {featuredCampaigns.length>0 && (
                <div className='flex flex-row flex-wrap mt-3'>
                    {featuredCampaigns.map((campaign, index) => {
                        const hasEtb = campaign.goalEtbAmount ? campaign.goalEtbAmount!==0 : false;
                        const hasUsd = campaign.goalUsdAmount ? campaign.goalUsdAmount!==0 : false;
                        return (
                            <a href={`/campaign/${campaign.id}`} key={campaign.id} className={`py-1 lg:p-2 w-full lg:w-1/2 group`}>
                                <div className='h-48 lg:h-72 bg-white rounded-2xl overflow-hidden flex flex-row gap-2 group-hover:z-20 group-hover:shadow-xl duration-200 border-slate-50 border-2 group'>
                                    <div className='relative h-full bg-slate-200 w-2/5 group-hover:w-1/2 duration-200'>
                                        {campaign.photos.length>0 && (
                                            <img src={`${Constants.baseUrl}/uploaded-file/${campaign.photos[0]}`} alt=''
                                            className='h-full w-full object-cover'/>
                                        )}
                                        <div className='absolute bottom-2 left-2 flex flex-row gap-2'>
                                            {hasEtb && (
                                                <div className='px-2 py-0.5 rounded-full bg-white'>
                                                    <p className='text-[13px] font-medium'>ETB</p>
                                                </div>
                                            )}

                                            {hasUsd && (
                                                <div className='px-2 py-0.5 rounded-full bg-white'>
                                                    <p className='text-[13px] font-medium'>USD</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-col p-1 lg:p-3 w-3/5 group-hover:w-1/2 duration-200">
                                        <div className='text-lg lg:text-2xl font-bold text-primary-blue line-clamp-1 xl:line-clamp-2'>{campaign.title}</div>
                                        <div className='text-primary-blue text-sm lg:text-md line-clamp-1 lg:line-clamp-2'>
                                            {campaign.description}
                                        </div>
                                        <div className="flex flex-row lg:mb-4 my-1 lg:mt-2">
                                            <div className='text-secondary-blue font-normal text-sm lg:text-base px-2 lg:px-4 py-1 rounded-full bg-primary-green flex flex-row gap-2 items-center'>
                                                <FiTag className='text-sm lg:text-md'/>
                                                {campaign.category}
                                            </div>
                                        </div>
                                        <div className='text-primary-blue text-md lg:text-xl'>
                                            {hasEtb && <p className='font-bold mr-1.5 text-md lg:text-2xl text-primary-blue'>{utils.addCommaToNumber(campaign.etbAmount)} <span className='font-normal'>ETB</span></p>}
                                            {hasUsd && <p className='font-bold mr-1.5 text-md lg:text-2xl text-primary-blue'>{utils.addCommaToNumber(campaign.usdAmount)} <span className='font-normal'>USD</span></p>}
                                            raised
                                        </div>
                                        
                                    </div>
                                </div>
                            </a>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default FeaturedCampaign;