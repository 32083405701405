import React, {useState, useEffect, useContext} from 'react';
import { FiSearch, FiX, FiChevronDown, FiChevronUp } from "react-icons/fi";

import { useSearchParams } from 'react-router-dom';

import { CampaignService, UserService } from '../../services';
import { UserContext } from '../../contexts';
import { CampaignItem } from '../shared';
import FilteringMenu from './filteringMenu';

function CampaignScreen() {
    const campaignService = new CampaignService();
    const userService = new UserService();
    const {user, setUser, loggedIn} = useContext(UserContext);

    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    console.log(searchParams.get('category'));

    const [campaigns, setCampaigns] = useState([]);
    const [campaignsData, setCampaignsData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const [filteredCampaigns, setFilteredCampaigns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    const [filterCategories, setFilterCategories] = useState(searchParams.get('category') ? [{title:searchParams.get('category'), id:0}] : []);

    const [showFilter, setShowFilter] = useState();

    const updateUserData = async () => {
        if(loggedIn) userService.fetchUserData().then((res) => setUser((usr) => res.data.data)).catch((err) => console.log(err));
    }

    const fetchCampaigns = async () => {
        setLoading(true);
        campaignService.fetchCampaigns(currentPage, pageSize, filterCategories.map((item) => item.title))
        .then((res) => {
            // console.log(res.data);
            console.table(res.data);
            setCampaigns((camp) => res.data.campaigns);
            setCampaignsData((camp_data) => {
                return {...res.data, campaigns:null};
            });
            updateUserData();
        }).catch((err) => {
            console.log(err);
        }).finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, filterCategories]);

    useEffect(() => {
        if(searchText===''){
            setFilteredCampaigns(campaigns);
        }else{
            let newList = campaigns.filter((item) => item.title.toLowerCase().startsWith(searchText.toLowerCase()));
            setFilteredCampaigns(newList);
        }
    }, [campaigns, searchText]);

    const onSaveOrRemoveCampaign = async (id, saved) => {
        if(saved===true){
            // Remove Saved Campaign
            campaignService.removeSavedCampaign(id)
            .then((res) => fetchCampaigns())
            .catch((err) => console.log(err));
        }else{
            // Save new Campaign
            campaignService.saveCampaign(id)
            .then((res) => fetchCampaigns())
            .catch((err) => console.log(err));
        }

    }

    return (
        <div className='w-full flex flex-col items-center font-rubik gap-3 pt-20 md:pt-36 mb-20'>
            {/* <div className='text-4xl font-medium text-primary-blue '>Explore</div> */}
            <div className='flex flex-col mb-2 md:mb-8 items-center'>
                <p className='text-primary-blue text-xl font-medium'>Explore Campaigns</p>
                <p className='text-gray-400 text-base'>Search and filter campaigns</p>
            </div>

            <div className='flex flex-col w-full xl:w-4/5 2xl:w-2/3 px-3 md:px-10 xl:px-20 2xl:px-5 gap-2 items-center'>
                <div className='bg-slate-100 rounded-full px-3 py-2 flex flex-row gap-2 md:gap-3 items-center w-full md:w-2/3 relative'>
                    <FiSearch className='text-primary-blue text-md md:ml-2'/>
                    <input type="text" value={searchText} onChange={(val) => setSearchText(val.currentTarget.value)} placeholder="Search"
                    className="bg-slate-100 py-1 focus:outline-0 focus:rounded-xl text-lg flex-grow"/>
                    {searchText && (
                        <button className='px-2'>
                            <FiX className='text-primary-blue text-md'/>
                        </button>
                    )}
                    <button onClick={() => setShowFilter(!showFilter)} 
                    className='flex flex-row gap-2 px-3 md:px-6 py-2 bg-primary-blue rounded-full items-center duration-200 justify-center'>
                        {/* <img src={lomiIcon} alt="" className='w-8'/> */}
                        <div className='text-primary-green text-md font-rubik hidden md:flex'>
                            Category
                        </div>
                        {showFilter ? <FiChevronUp className='text-primary-green text-md'/> : <FiChevronDown className='text-primary-green text-md'/>}
                    </button>
                    {showFilter && <FilteringMenu onCancel={() => setShowFilter(false)} 
                    onContinue={(selectedFilters) => {
                        // OnContinue here
                        setShowFilter(false);
                        setFilterCategories(selectedFilters);
                    }}/>}
                </div>


                {filterCategories.length>0 && (
                    <div className='flex flex-col w-full gap-1'>
                        <button onClick={() => {
                            setFilterCategories((prev) => []);
                        }} className='flex flex-row items-center gap-1 text-red-500 hover:text-red-800 duration-300'>
                            <FiX size={14}/>
                            <p className='text-sm'>Clear Filters</p>
                        </button>
                        <div className='flex flex-wrap gap-2'>
                            {filterCategories.map((category) => {
                                return (
                                    <div key={category.id} className='px-4 py-1 bg-gray-200 rounded-full'>
                                        <p className='text-sm'>{category.title}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                

                <div className="flex flex-row gap-2 w-full justify-between">
                    <div className="flex flex-row items-center gap-2">
                        <div className='text-primary-blue text-md'>Results: </div>
                        <div className='text-primary-green text-md font-bold'>{filteredCampaigns.length}</div>
                    </div>
                    {loading && (
                        <div className='px-4 rounded-xl bg-white w-40 text-primary-blue text-md'>
                            Updating . . .
                        </div>
                    )}

                    <div className='flex-row mt-4'>
                        {campaignsData && (
                            <div className="flex flex-row items-center gap-4">
                                <button onClick={() => {
                                    setCurrentPage((page) => page-1);
                                }} disabled={currentPage===1} 
                                className={`text-md ${currentPage>1 ? 'text-primary-green' : 'text-slate-400'}`}>Prev</button>

                                <div className='text-primary-blue text-md'> {currentPage} / {campaignsData.lastPage} </div>

                                <button onClick={() => {
                                    setCurrentPage((page) => page+1);
                                }} disabled={currentPage===campaignsData.lastPage} 
                                className={`text-md ${currentPage<campaignsData.lastPage ? 'text-primary-green' : 'text-slate-400'}`}>Next</button>
                            </div>
                        )}
                    </div>
                </div>

                

                {filteredCampaigns.length>0 && (
                    <div className='flex flex-wrap flex-row w-full'>
                        {filteredCampaigns.map((campaign) => {
                            if(user != null){
                                const saved = user?.savedCampaigns.includes(campaign.id);
                                return (
                                    <CampaignItem campaign={campaign} onSaveOrRemove={onSaveOrRemoveCampaign} saved={saved} key={campaign.id}/>
                                );
                            }else{
                                return (
                                    <CampaignItem campaign={campaign} onSaveOrRemove={onSaveOrRemoveCampaign} saved={false} key={campaign.id}/>
                                );
                            }
                        })}
                    </div>
                )}

                

            </div>

        </div>
    );
}

export default CampaignScreen;