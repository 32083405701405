import React, {useEffect, useState} from 'react';
import { FiLink2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { CampaignService } from '../../services';
import { Constants, Utils } from '../../utils';

function MyCampaigns() {
    const campaignService = new CampaignService();
    const utils = new Utils();

    const [myCampaigns, setMyCampaigns] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        campaignService.fetchMyCampaigns()
        .then((result) => {
            setMyCampaigns(result.data.data);
        })
        .catch((err) => {
            console.log(err)
        })
        .finally(() => {
            setLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='flex flex-row w-full flex-wrap' id='mycampaign'>
            <div className="flex flex-row justify-between w-full px-2">
                <div className="flex flex-col">
                    <div className="flex flex-row gap-3 items-center">
                        <div className="text-primary-blue text-xl font-medium">
                            My Campaigns
                        </div>
                        <div className="text-primary-blue text-xl font-medium hover:text-primary-green duration-200 hover:cursor-pointer">
                            <FiLink2/>
                        </div>
                    </div>
                    <div className="text-primary-blue text-sm md:text-md">
                        These are the campaigns created by you.
                    </div>
                </div>
                {loading && <div>Loading...</div>}
            </div>
            {myCampaigns.length>0 && (
                <div className='flex flex-wrap flex-row w-full'>
                    {myCampaigns.map((campaign) => {
                        return (
                            <Link key={campaign.id} to={campaign.verified===true ? `/campaign/${campaign.id}` : '#'} className='w-1/2 md:w-1/3 xl:w-1/4 p-1.5 mb-2 '>
                                <div key={campaign.id} 
                                className='w-full h-full p-2 bg-white flex flex-col gap-2 duration-200 hover:z-20 hover:shadow-lg hover:scale-105 rounded-xl group border-[1px] border-slate-100'>
                                    <div className="flex flex-col gap-2">
                                        <div className='h-32 md:h-40 w-full relative'>
                                            {campaign.photos.length>0 && (
                                                <img src={`${Constants.baseUrl}/uploaded-file/${campaign.photos[0]}`} alt=''
                                                className='h-full w-full rounded-xl object-cover'/>
                                            )}
                                            <div className="flex flex-row">
                                            <div className={`absolute bottom-2 left-2 px-4 py-1 rounded-full
                                            ${campaign.verified===true ? 'bg-primary-green' : campaign.verified===null ? 'bg-orange-400' : 'bg-red-500'}`}>
                                                <p className={`text-sm font-normal ${campaign.verified===true ? 'text-primary-blue' : 'text-white'}`}>
                                                    {campaign.verified===true ? "Approved!" : campaign.verified==null ? "Pending approval" : "Denied"}
                                                </p>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="flex flex-col md:px-2 gap-2">
                                            <div className='text-md md:text-lg font-bold text-primary-blue line-clamp-1 md:line-clamp-2'>{campaign.title}</div>
                                            <div className='text-primary-blue text-sm line-clamp-2 md:line-clamp-2'>
                                                {campaign.description}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className='px-4 py-1 rounded-full bg-primary-blue'>
                                            <p className='text-primary-green text-sm'>{campaign.category}</p>
                                        </div>
                                    </div>
                                    <div className='flex-grow'></div>
                                    <div className="flex flex-row flex-wrap gap-2">
                                        <div className="flex flex-col md:px-2 flex-grow w-fit">
                                            <p className='text-primary-blue text-sm md:text-md'>Goal amount.</p>
                                            <p className='text-primary-blue font-bold text-md md:text-lg'>{utils.addCommaToNumber(campaign.goalEtbAmount, true)} ETB</p>
                                            {campaign.verified===true && (
                                                <div className='h-1 w-full rounded-full bg-slate-100 flex flex-row'>
                                                    <div className='bg-primary-green rounded-full' style={{width:`${utils.calculatePercentageString(campaign.etbAmount, campaign.goalEtbAmount)}%`}}></div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex flex-col md:px-2 flex-grow w-fit">
                                            <p className='text-primary-blue text-sm md:text-md'>Goal amount.</p>
                                            <p className='text-primary-blue font-bold text-md md:text-lg'>{utils.addCommaToNumber(campaign.goalUsdAmount, true)} USD</p>
                                            {campaign.verified===true && (
                                                <div className='h-1 w-full rounded-full bg-slate-100 flex flex-row'>
                                                    <div className='bg-primary-green rounded-full' style={{width:`${utils.calculatePercentageString(campaign.usdAmount, campaign.goalUsdAmount)}%`}}></div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            )}
        </div>
    )
}

export default MyCampaigns;