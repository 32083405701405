import React, {useState} from 'react';
import { FiLogIn, FiEyeOff, FiEye } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// import ReCAPTCHA from "react-google-recaptcha";

import { OtpService, UserService } from '../../services';
import { Utils } from '../../utils';

function ForgotPasswordScreen() {
    const navigate = useNavigate();
    const userService = new UserService();
    const otpService = new OtpService();
    const utils = new Utils();

    const [email, setEmail] = useState('');

    const [otpCode, setOtpCode] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [otpSent, setOtpSent] = useState(false);

    // const [captchaVerified, setCaptchaVerified] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [loading, setLoading] = useState(false);


    const canContinue = () => {
        let errorMessage = '';

        if(!utils.validateEmail(email)){
            errorMessage = 'Invalid Email';
        }
         
        // if(!captchaVerified){
        //     errorMessage = 'please verify captcha first';
        // }

        if(errorMessage!==''){
            toast.error(errorMessage);
            return false;
        }else{
            return true;
        }
    }

    // // Recaptcha on change handler
    // function onChange(value) {
    //     if(value){
    //         setCaptchaVerified(true);
    //     }
    //     console.log("Captcha value:", value);
    // }

    const requestOtp = () => {
        const data = {
            email: email
        };

        setLoading(true);
        setErrorMessage('');

        otpService.requestOtp(data)
        .then((result) => {
            toast.success('OTP sent to your email!');
            setSuccessMessage('We have sent an email to ' + email + ' please check your inbox to get the OTP.');
            setOtpSent(true);
        }).catch((err) => {
            const {message} = err.response.data;
            setErrorMessage(message);
            // console.log(err.response.data);
        }).finally(() => {
            // console.log('Hello')
            setLoading(false);
        })
    }

    const changePassword = () => {
        const data = {
            email: email,
            otp: otpCode,
            password: password
        };

        setLoading(true);
        setErrorMessage('');

        userService.resetPassword(data)
        .then((result) => {
            toast.success('Password Changed!');
            navigate('/login');
        }).catch((err) => {
            const {message} = err.response.data;
            setErrorMessage(message);
            // console.log(err.response.data);
        }).finally(() => {
            // console.log('Hello')
            setLoading(false);
        })
    }

    return (
        <div className='min-h-screen w-full flex px-4 flex-col justify-center items-center font-rubik gap-3'>
            <div className='text-4xl font-medium text-primary-blue mb-8'>Forgot Password</div>

            {loading && <div>Loading . . .</div>}

            <div className='flex flex-col w-full lg:w-96 gap-4'>
                {errorMessage && <div className='text-red-500 text-base'>{errorMessage}</div>}
                {successMessage && <div className='text-primary-green text-base'>{successMessage}</div>}

                <input type="text" value={email} onChange={(val) => setEmail(val.currentTarget.value)} placeholder="Email"
                className="bg-slate-100 rounded-md px-4 py-3"/>

                {otpSent && (
                    <div className='flex flex-col gap-4'>
                        <input type="text" value={otpCode} autoComplete='off' onChange={(val) => setOtpCode(val.currentTarget.value)} placeholder="OTP Code"
                        className="bg-slate-100 rounded-md px-4 py-3"/>

                        <div className="flex flex-row bg-slate-100 rounded-md overflow-hidden">
                            <input type={showPassword ? 'text' : 'password'} value={password} onChange={(val) => setPassword(val.currentTarget.value)} placeholder="New Password"
                            className="flex-grow bg-slate-100 px-4 py-3 rounded-md" />
                            <button className='px-4' onClick={() => setShowPassword(!showPassword)}>
                                {showPassword 
                                ? <FiEyeOff className='text-primary-green text-xl'/> 
                                : <FiEye className='text-primary-green text-xl'/>}
                            </button>
                        </div>
                    </div>
                )}

                {/* <ReCAPTCHA
                    sitekey="6Lfvr-MkAAAAAAHDAW9VTxXh5gT2hNgl6rMSfIoz"
                    onChange={onChange}
                /> */}
                
                <div className="flex flex-row justify-end">
                    <button 
                    onClick={() => {
                        if(canContinue()){
                            if(!otpSent){
                                requestOtp();
                            }else{
                                changePassword();
                            }
                        }
                    }}
                    className='flex flex-row gap-2 px-6 py-2 bg-primary-blue rounded-md items-center duration-200 w-full justify-center'>
                        <div className='text-primary-green text-lg font-rubik'>
                            Continue
                        </div>
                        <FiLogIn className='text-primary-green text-xl'/>
                    </button>
                </div>
            </div>

            <div className="flex flex-row">
                {otpSent ? (
                    <button onClick={() => setOtpSent(false)} className='text-primary-green text-lg'>
                        Request New OTP
                    </button>
                ) : (
                    <button onClick={() => setOtpSent(true)} className='text-primary-green text-lg'>
                        Already Have OTP?
                    </button>
                )}
            </div>

            <div className="flex flex-row gap-2">
                <a href='/login' className='text-primary-green text-lg'>Sign In</a>
            </div>


        </div>
    );
}

export default ForgotPasswordScreen;