import React, { useState } from 'react';
import { FiX, FiMail, FiUser} from "react-icons/fi";

function CreatorInfoPopup({onClose, creatorInfo}) {
    const [message, setMessage] = useState('');
    return (
        <div className='fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center'>
            <div className="flex flex-col px-4 py-4 rounded-xl bg-white w-[500px]">
                <div className="flex flex-row justify-between mb-2">
                    <div className="flex flex-row gap-2 items-center">
                        <FiUser size={20}/>
                        <div className='text-xl'>Contact Fundraiser</div>
                    </div>
                    <div onClick={onClose} className='p-2 text-red-400 hover:scale-110 hover:cursor-pointer'>
                        <FiX size={22}/>
                    </div>
                </div>

                <div className="text-base">
                    Mail to:
                </div>
                <div className="text-xl font-medium mb-3">
                    {creatorInfo.firstname} {creatorInfo.lastname}
                </div>
                <div className="text-base">
                    Mail to:
                </div>
                <div className="flex flex-row gap-2 items-center mb-4">
                    <FiMail size={20} className="text-primary-green"/>
                    <div onClick={() => window.location = `mailto:${creatorInfo.email}`} 
                    className="text-lg text-primary-blue hover:text-primary-green hover:cursor-pointer">
                        {creatorInfo.email}
                    </div>
                </div>

                <div className="text-base">
                    Write message:
                </div>
                <textarea cols="30" rows="4"
                value={message} 
                onChange={(val) => setMessage(val.currentTarget.value)}  
                placeholder='Message' 
                className="bg-slate-100 rounded-md px-4 py-3 flex-grow rounded-tr-none rounded-br-none mb-3"/>

                <button 
                onClick={() => {
                }}
                className='flex flex-row px-8 py-2 bg-primary-blue rounded-md items-center duration-200 justify-center gap-2'>
                    {/* {donationLoading && <LoadingSpinner radius='5'/>} */}
                    <div className='text-primary-green text-lg font-rubik'>
                        SEND MESSAGE
                    </div>
                </button>

            </div>
        </div>
    );
}

export default CreatorInfoPopup;