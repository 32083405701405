import React, {useState, useContext} from 'react';
import { FiLogIn, FiEyeOff, FiEye } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";

import { UserContext } from '../../contexts';
import UserService from '../../services/user.service';
import Cookies from 'js-cookie';

function LoginScreen() {
    const navigate = useNavigate();
    const userService = new UserService();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [captchaVerified, setCaptchaVerified] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    const [loading, setLoading] = useState(false);

    const {setUser, setLoggedIn} = useContext(UserContext);

    const canLogin = () => {
        let errorMessage = '';
        if(email===''){
            errorMessage = 'please fill the form';
        }
        
        if (password === '') {
            errorMessage = 'please fill the form';
        }
        
        if(!captchaVerified){
            errorMessage = 'please verify captcha first';
        }

        if(errorMessage!==''){
            toast.error(errorMessage);
            return false;
        }else{
            return true;
        }
    }

    // Recaptcha on change handler
    function onChange(value) {
        if(value){
            setCaptchaVerified(true);
        }
        console.log("Captcha value:", value);
    }
    
    // const logout = () => {
    //     Cookies.remove('user-token');
    //     setUser((us) => null);
    //     setLoggedIn((lg) => false);
    // }

    // useEffect(() => {
    //     logout();
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const login = () => {
        const data = {
            email: email,
            password: password
        };

        setLoading(true);
        setErrorMessage('');

        userService.signIn(data)
        .then((result) => {
            const token = result.data.data.token;
            Cookies.set('user-token', token);
            userService.fetchUserData().then((res) => {
                setUser(res.data.data);
                setLoggedIn(true);
            }).catch((err) => {
                toast.error("Logged In Failed!");                
            }).finally(() => {
                toast.success("Logged In Successfully!");
                navigate("/");
            })
        }).catch((err) => {
            // console.log(err);
            const {message} = err.response.data;
            if(message === 'Account not activated') {
                setErrorMessage('Account not activated, Check your email.');
                toast.error("Account not activated");
            }else{
                setErrorMessage('Invalid Email or Password!');
                toast.error("Logged In Failed!");
            }
            // console.log(err.response.data);
        }).finally(() => {
            // console.log('Hello')
            setLoading(false);
        })
    }

    return (
        <div className='min-h-screen w-full flex px-4 flex-col justify-center items-center font-rubik gap-3'>
            <div className='text-primary-blue text-lg'>Welcome to Lomi!</div>
            <div className='text-4xl font-medium text-primary-blue mb-8'>Sign in</div>

            {loading && <div>Signing In . . .</div>}

            <div className='flex flex-col w-full lg:w-96 gap-4'>
                {errorMessage && <div className='text-red-500 text-base'>{errorMessage}</div>}

                <input type="text" value={email} onChange={(val) => setEmail(val.currentTarget.value)} placeholder="Email"
                className="bg-slate-100 rounded-md px-4 py-3"/>

                <div className="flex flex-row bg-slate-100 rounded-md overflow-hidden">
                    <input type={showPassword ? 'text' : 'password'} value={password} onChange={(val) => setPassword(val.currentTarget.value)} placeholder="Password"
                    className="flex-grow bg-slate-100 px-4 py-3 rounded-md" />
                    <button className='px-4' onClick={() => setShowPassword(!showPassword)}>
                        {showPassword 
                        ? <FiEyeOff className='text-primary-green text-xl'/> 
                        : <FiEye className='text-primary-green text-xl'/>}
                    </button>
                </div>

                <ReCAPTCHA
                    sitekey="6Lfvr-MkAAAAAAHDAW9VTxXh5gT2hNgl6rMSfIoz"
                    onChange={onChange}
                />
                
                <div className="flex flex-row justify-end">
                    <button 
                    onClick={() => {
                        if(canLogin()){
                            login();
                        }
                    }}
                    className='flex flex-row gap-2 px-6 py-2 bg-primary-blue rounded-md items-center duration-200 w-full justify-center'>
                        <div className='text-primary-green text-lg font-rubik'>
                            Sign in
                        </div>
                        <FiLogIn className='text-primary-green text-xl'/>
                    </button>
                </div>
                <div className="flex flex-row gap-2 text-base">
                    <a href='/forgotpassword' className='text-primary-green'>Forgot Password?</a>
                </div>
            </div>


            <div className="flex flex-row gap-2">
                <div className='text-primary-blue text-lg'>Don't have account?</div>
                <a href='/signup' className='text-primary-green text-lg'>Sign up</a>
            </div>


        </div>
    );
}

export default LoginScreen;