import React, {useState, useEffect} from 'react';

import { CampaignService } from '../../services';
import { CampaignItem } from '../shared';

function SavedCampaignsScreen() {
    const campaignService = new CampaignService();

    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchMySavedCampaigns = async () => {
        setLoading(true);
        campaignService.getAllSavedCampaigns()
        .then((res) => {
            // console.log(res.data);
            setCampaigns((camp => res.data.data));
        }).catch((err) => {
            console.log(err);
        }).finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchMySavedCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeSavedCampaign = async (id, saved) => {
        campaignService.removeSavedCampaign(id).then((res) => fetchMySavedCampaigns()).catch((err) => console.log(err));
    }

    return (
        <div className='w-full flex flex-col items-center font-rubik gap-3 pt-20 md:pt-36 mb-20'>
            {/* <div className='text-4xl font-medium text-primary-blue '>Explore</div> */}
            <div className='text-primary-blue text-xl mb-2 md:mb-8 font-medium'>Saved Campaigns</div>

            <div className='flex flex-col w-full xl:w-4/5 2xl:w-2/3 px-3 md:px-10 xl:px-20 2xl:px-5 gap-4 items-center'>
                <div className="flex flex-row gap-2 w-full items-center justify-between">
                    <div className="flex flex-row items-center gap-2">
                        <div className='text-primary-green text-md font-bold'>{campaigns.length}</div>
                        <div className='text-primary-blue text-md'> Campaigns saved. </div>
                    </div>

                    {loading && (
                        <div className='px-4 rounded-xl bg-white w-40 text-primary-blue text-md'>
                            Updating . . .
                        </div>
                    )}
                </div>

                

                {campaigns.length>0 && (
                    <div className='flex flex-wrap flex-row w-full'>
                        {campaigns.map((campaign) => {
                            return (
                                <CampaignItem campaign={campaign} onSaveOrRemove={removeSavedCampaign} saved={true} key={campaign.id}/>
                            );
                        })}
                    </div>
                )}

            </div>

        </div>
    );
}

export default SavedCampaignsScreen;