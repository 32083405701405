import React from 'react';
// import { FiLink, FiLinkedin, FiTwitter } from "react-icons/fi";

// const teams = [
//     {id: 1, name: 'Dr. Sileshi', position: 'CEO / Co-founder', twitter: '', linkedIn: '', other:''},
//     {id: 2, name: 'Dr. Muluken', position: 'CTO / Co-founder', twitter: '', linkedIn: '', other:''},
//     {id: 3, name: 'Surafel Kindu', position: 'Developer / Co-founder', twitter: '', linkedIn: '', other:''},
// ];

function AboutScreen() {
    return (
        <div className='w-full flex flex-col font-rubik gap-2 pt-20 md:pt-36 items-center'>
            <div className='flex flex-col w-full xl:w-4/5 px-4 md:px-10 xl:px-20 2xl:px-5 gap-4 items-center md:items-start'>
                <div className='text-primary-blue text-5xl font-medium mb-2'> We’re here to help people help each other </div>
                <div className='text-primary-blue text-2xl mb-4'> 
                    Lomi is a community-powered fundraising platform. We believe that help is powerful, which is why we’re committed to 
                    making it safe and easy for anyone to give and get help. 
                </div>
                <div className='text-primary-blue text-2xl md:text-3xl font-medium mb-2 underline'> More about us </div>
                <div className='text-primary-blue text-md text-justify'> <span className='font-medium'>Lomi.et</span> is an online platform where people and organizations can campaign and raise 
                funds for projects to benefit social, community or charitable causes and where Supporters can donate money ("Donation") to support those campaigns. 
                Nulla non risus ac diam pretium gravida. Curabitur ipsum augue, mollis vitae tincidunt ac, malesuada vel erat. Nulla erat est, sodales vel 
                rutrum quis, convallis in metus. Phasellus tempor, neque non pulvinar faucibus, lacus erat congue felis, ut hendrerit lectus purus a lacus.</div>

                <div className='text-primary-blue text-md text-justify'> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean rutrum tempor ultrices. 
                Nulla non risus ac diam pretium gravida. Curabitur ipsum augue, mollis vitae tincidunt ac, malesuada vel erat. Nulla erat est, sodales vel 
                rutrum quis, convallis in metus. Phasellus tempor, neque non pulvinar faucibus, lacus erat congue felis, ut hendrerit lectus purus a lacus. 
                Integer convallis pellentesque interdum. Etiam facilisis magna et lorem tincidunt tempor. Sed sed erat lobortis, aliquam purus eu, porttitor 
                risus. Duis venenatis fringilla enim a semper. Cras consequat pulvinar justo, sit amet imperdiet sapien dictum vel. </div>

                <div className='text-primary-blue text-2xl md:text-3xl font-medium mb-2 mt-4 underline'> Want to join us? </div>

                <div className='text-primary-blue text-md text-justify'>
                    We are a growing global team of 10 working to build the most helpful place in the world. Click here to visit our careers page 
                    and learn how you can help us help others.
                </div>


                {/* <div className='text-primary-blue text-2xl md:text-3xl font-medium mb-2 mt-4 underline'> Our Team </div>

                <div className="flex flex-row gap-8">
                    {teams.map((team) => {
                        return (
                            <div key={team.id} className="flex flex-col gap-1 items-center">
                                <img src="https://st4.depositphotos.com/9998432/22670/v/450/depositphotos_226700318-stock-illustration-person-gray-photo-placeholder-man.jpg" 
                                alt="Dr. Sileshi" className='w-20 h-20 rounded-full border-2 border-primary-blue'/>
                                <div className='font-medium text-lg'>
                                    {team.name}
                                </div>
                                <div className='text-sm'>
                                    {team.position}
                                </div>
                                <div className="flex flex-row gap-3 mt-1">
                                    <button><FiLinkedin className='text-primary-blue hover:text-primary-green duration-200 text-xl'/></button>
                                    <button><FiTwitter className='text-primary-blue hover:text-primary-green duration-200 text-xl'/></button>
                                    <button><FiLink className='text-primary-blue hover:text-primary-green duration-200 text-xl'/></button>
                                </div>
                            </div>
                        );
                    })}
                </div> */}

            </div>
        </div>
    );
}

export default AboutScreen;